
import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import '../../assets/Form.css';
export default function FirmaRRHHForm(props) {

    return (

        <Grid container spacing={1} style={{ marginBottom: 2 }}>
            <Grid item xs={6} >
                <Typography className="size">FIRMA RECURSOS HUMANOS: {props.peticion.mail_notificacion}</Typography>
            </Grid>
            <Grid item xs={6} >
                <Typography className="size">ACLARACIÓN: Visto en conformidad {props.peticion.fecha_conformidad_rrhh === null ? new Date().toLocaleString() : props.peticion.fecha_conformidad_rrhh}</Typography>
            </Grid>
        </Grid>
    )
}