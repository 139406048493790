import React, { Component, Fragment } from 'react'
import { Icon, DialogTitle, Tooltip, AccordionSummary, Accordion, AccordionDetails, Divider, Dialog, DialogContent, Card, CardContent, CardHeader, Grid, Typography, Button } from '@material-ui/core';
import { connect } from "react-redux";
import '../../assets/Form.css';
import Number from '../inputs/Number'
import StringNumber from '../inputs/StringNumber'
import String from '../inputs/String'
import TextArea from '../inputs/TextArea'
import DatePicker from '../inputs/DatePicker'
import RadioButton from '../inputs/RadioButtons'
import FirmasFormPlanCarrera from '../partials/FirmasFormPlanCarrera'
//import WarningForm from '../partials/WarningForm'
import { sendPlanCarreraAction } from '../../actions/FormAction'
import { getUserAction } from '../../actions/LoginAction'
import { getPeticionAction } from '../../actions/PeticionAction'
import { Alert, AlertTitle } from '@material-ui/lab';
import InfoIcon from '@material-ui/icons/Info'
import { getSiNo, getCalificacionPerfil } from '../../services/InfoFormService'
import { Element, animateScroll as scroll } from 'react-scroll';
import configStyles from '../../config/style.json';
import Theme from '../../assets/Theme';
import logo from '../../assets/atomo.png';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Redirect } from "react-router-dom";


class PlanCarrera extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openAlert: true,
            warning: false,
            categoria: "",
            sueldoBasico: "",
            adicional: "",
            ingreso: "",
            cumplimientoPerfilTrabajoEquipo: "",
            cumplimientoPerfilActitudEquipo: "",
            superaStandard: '100',
            dentroStandard: '0',
            debajoStandard: '0',
            fechaPrimerPermiso: '',
            otrasCondiciones: '',
            comentarios: '',
            genericas0: 'Supera',
            genericas1: 'Supera',
            nivel0: 'Supera',
            nivel1: 'Supera',
            funcional0: 'Supera',
            funcional1: 'Supera',
            disabledARN: true,
            competencias_funcionales: false,
            competencias_generales: false,
            competencias_de_nivel: false,
            expanded: true,
            errors: {
                puesto: false,
                categoria: false,
                ingreso: false,
                superaStandard: false,
                dentroStandard: false,
                debajoStandard: false,
                fechaPrimerPermiso: false,
                otrasCondiciones: false,
                adicional: false,
                sueldoBasico: false,
                comentarios: false
            }
        }
    }
    collapseAlert = () => {
        this.setState({ openAlert: false })
    }
    componentDidMount() {

        const id = this.props.match.params.id;
        this.props.dispatchGetPeticionAction(id);
    }

    componentDidUpdate(prevProps, prevState) {
        let request = {};
        if (prevProps.peticionData !== this.props.peticionData) {
            request = {
                "mail": this.props.peticionData.destinatario[0].email
            };
            this.props.dispatchGetUsernAction(request);
        }

    }

    send = () => {

        let count = 0;
        let countValid = 0;
        let form = new Object();
        let cumplimiento = new Object();
        let step_actual = new Object();
        let step_avanza = new Object();
        let resultado_standard = new Object();
        let competencia = new Object();
        let genericas = [];
        let funcional = [];
        let nivel = [];
        let errors = [];

        form["hash"] = this.props.match.params.hash;
        form["aprobador"] = this.props.match.params.idAprobador;
        form["status"] = "1";
        form["nombre"] = this.props.peticionData.destinatario[0].name;
        form["apellido"] = this.props.peticionData.destinatario[0].name;

        step_actual["puesto"] = this.props.responseUser[0].title;

        if (this.state.ingreso && this.state.ingreso !== null) {
            count++;
            if (this.state.ingreso.state.selectedDate !== '' && this.state.ingreso.state.selectedDate !== null) {
                countValid++;
                console.log("2")

                let date = this.state.ingreso.state.selectedDate.split("/")
                step_actual["ingreso"] = date.join("-");
            }
        }

        step_actual["nombre"] = this.props.peticionData.carrera.step_actual.nombre;

        form["step_actual"] = step_actual;


        cumplimiento[this.props.peticionData.carrera.competencia.generica[0]] = this.state.genericas0;
        cumplimiento[this.props.peticionData.carrera.competencia.generica[1]] = this.state.genericas1;
        cumplimiento[this.props.peticionData.carrera.competencia.funcional[0]] = this.state.funcional0;
        cumplimiento[this.props.peticionData.carrera.competencia.funcional[1]] = this.state.funcional1;
        cumplimiento[this.props.peticionData.carrera.competencia.de_nivel[0]] = this.state.nivel0;
        cumplimiento[this.props.peticionData.carrera.competencia.de_nivel[1]] = this.state.nivel1;

        form["cumplimiento"] = cumplimiento;


        resultado_standard["supera"] = this.state.superaStandard;
        resultado_standard["dentro"] = this.state.dentroStandard;
        resultado_standard["debajo"] = this.state.debajoStandard;

        form["resultado_standard"] = resultado_standard

        form["fecha_primer_permiso"] = (this.props.peticionData.carrera.fecha_primer_permiso !== null) ? this.props.peticionData.carrera.fecha_primer_permiso : null;

        console.log(countValid)
        if (this.state.confirmacion && this.state.confirmacion !== null) {
            count++;
            if (this.state.confirmacion.state.defaultValue !== '') {
                countValid++;
                console.log("10")
                form[this.state.confirmacion.props.name] = (this.state.confirmacion.state.defaultValue === "Si") ? true : false;
                form["avance"] = this.state.confirmacion.state.defaultValue;
            }
        }



        if (this.state.categoria && this.state.categoria !== null) {
            count++;
            if (this.state.categoria.state.defaultValue !== '') {
                countValid++;
                console.log("12")

                step_avanza["categoria"] = this.state.categoria.state.defaultValue;
            }
        }

        if (this.state.sueldoBasico && this.state.sueldoBasico !== null) {
            count++;
            if (this.state.sueldoBasico.state.defaultValue !== '') {
                countValid++;
                console.log("13")

                step_avanza["sueldo_basico"] = this.state.sueldoBasico.state.defaultValue;
            }
        }

        if (this.state.adicional && this.state.adicional !== null) {
            count++;
            if (this.state.adicional.state.defaultValue !== '') {
                countValid++;
                console.log("14")

                step_avanza["adicional_vol"] = this.state.adicional.state.defaultValue;
            }
        }

        if (this.state.otrasCondiciones && this.state.otrasCondiciones !== null) {
            count++;
            countValid++;
            console.log("15")

            if (this.state.otrasCondiciones.state.defaultValue !== '') {

                step_avanza["otras_condiciones"] = this.state.otrasCondiciones.state.defaultValue;
            } else {
                step_avanza["otras_condiciones"] = ""
            }
        }
        step_avanza["nombre"] = this.props.peticionData.carrera.step_avanza.nombre;

        form["step_avanza"] = step_avanza;

        form["comentarios"] = "";

        genericas.push(this.props.peticionData.carrera.competencia.generica[0]);
        genericas.push(this.props.peticionData.carrera.competencia.generica[1]);
        funcional.push(this.props.peticionData.carrera.competencia.funcional[0]);
        funcional.push(this.props.peticionData.carrera.competencia.funcional[1]);
        nivel.push(this.props.peticionData.carrera.competencia.de_nivel[0]);
        nivel.push(this.props.peticionData.carrera.competencia.de_nivel[1]);
        competencia["generica"] = genericas;
        competencia["de_nivel"] = nivel;
        competencia["funcional"] = funcional;

        form["competencia"] = competencia;
        //errores
        errors = {
            ingreso: (this.state.ingreso && this.state.ingreso.state.selectedDate !== null && this.state.ingreso.state.errorText === '') ? false : true,
            categoria: (this.state.categoria && this.state.categoria.state.defaultValue !== '' && this.state.categoria.state.errorText === '') ? false : true,
            sueldoBasico: (this.state.sueldoBasico && this.state.sueldoBasico.state.defaultValue !== '' && this.state.sueldoBasico.state.errorText === '') ? false : true,
            adicional: (this.state.adicional && this.state.adicional.state.defaultValue !== '' && this.state.adicional.state.errorText === '') ? false : true,
            otrasCondiciones: false
        };

        this.setState({
            errors: errors
        })
        console.log("form", form)
        console.log("count", count)
        console.log("countValid", countValid)
        if (count === countValid) {
            this.props.dispatchSendPlanCarreraAction(form);
        }
    }

    loadingScreen = () => {
        return (
            <Dialog open={true} align="center">
                <DialogContent>
                    <div>  </div>
                    <div> <Typography variant="h5">Procesando datos </Typography>  </div>
                </DialogContent>
            </Dialog>
        )
    }
    callbackCompetencias = (name, value) => {

        let competenciasPorc = [];

        competenciasPorc.push(name === 'genericas0' ? value : this.state.genericas0)
        competenciasPorc.push(name === 'genericas1' ? value : this.state.genericas1)
        competenciasPorc.push(name === 'nivel0' ? value : this.state.nivel0)
        competenciasPorc.push(name === 'nivel1' ? value : this.state.nivel1)
        competenciasPorc.push(name === 'funcional0' ? value : this.state.funcional0)
        competenciasPorc.push(name === 'funcional1' ? value : this.state.funcional1)

        let supera = competenciasPorc.filter(e => e === 'Supera')
        let cumple = competenciasPorc.filter(e => e === 'Cumple')
        let no_avanza = competenciasPorc.filter(e => e === 'No avanza')

        // console.log(competenciasPorc)
        this.setState({ [name]: value, dentroStandard: cumple.length > 0 ? (cumple.length / 6) * 100 : 0, superaStandard: supera.length > 0 ? (supera.length / 6) * 100 : 0, debajoStandard: no_avanza.length > 0 ? (no_avanza.length / 6) * 100 : 0 })
    }
    handleCloseModal = (type) => {
        console.log(type)
        this.setState({ [type]: !this.state[type] });
    }
    handleExpanded = () => {
        console.log("LLEGO ACAAAA")
        this.setState({ expanded: !this.state.expanded });

    }
    render() {

        let today = new Date();
        let date = today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate()
        const ModalCompetencias = (props) => {
            console.log(props)
            let description = []
            description["competencias_generales"] = [
                "• Actitud de servicio: Significa la disposición permanente por ayudar o brindar servicio a los clientes internos y pacientes, de poder comprender y satisfacer sus necesidades, aun aquéllas no expresadas, de generar empatía.",
                "• Trabajo en equipo: Implica la capacidad de colaborar y cooperar con los demás, de formar parte de un grupo y de trabajar juntos en tareas u objetivos compartidos."
            ];
            description["competencias_de_nivel"] = [
                "• Responsabilidad: Asociada al compromiso con que las personas realizan las tareas encomendadas. Cumple con la puntualidad y el presentismo y ante situaciones de demora o ausencia se comunica con anticipación suficiente con su responsable directo permitiéndole una adecuada reorganización de las tareas.",
                "• Conciencia organizacional: Supone facilidad para la relación interpersonal y capacidad para comprender el impacto de las propias acciones en la cadena de valor, entendiendo que el resultado de la propia tarea excede el puesto y repercute en diferentes áreas y posiciones.",
                "• Adaptabilidad: Hace referencia a la capacidad de modificar la conducta personal para alcanzar determinados objetivos cuando surgen cambios o dificultades en los procedimientos habituales y conocidos. Se asocia a la versatilidad del comportamiento ante distintos contextos y personas en forma rápida y adecuada.",
                "• Credibilidad técnica: Es la capacidad necesaria para generar seguridad y confianza en los demás sobre la base de los conocimientos técnicos de su especialidad.",
                "• Conocimiento de los servicios ofrecidos: Es la capacidad de conocer a fondo los servicios y evaluar la factibilidad y viabilidad de su adaptación a los requerimientos, preferencias y necesidades del cliente. Implica también conocer la competencia y las ventajas/desventajas entre los servicios ofrecidos.",
                "• Aprendizaje continuo: Es la habilidad de capitalizar lo aprendido a partir de la educación formal, la experiencia propia y de otros. Implica el interés en mantener su curva de conocimiento en constante desarrollo.",
                "• Involucramiento con normas y procedimientos organizacionales, contables, impositivos y legales: Es el compromiso demostrado por utilizar los procedimientos de la empresa para asegurar eficiencia interna y un constante estándar de servicio al paciente y al cliente interno, velando a su vez por el cumplimiento de las normas provinciales, nacionales o internacionales pertinentes y asegurándose de estar actualizado con los cambios en la legislación de aplicación.",
                "• Liderazgo: Es la habilidad necesaria para orientar la acción de los grupos en una dirección determinada, conduciendo a la acción, fijando objetivos, prioridades y dando seguimiento. Implica dar feedback, integrando la opiniones de los otros. Tener energía y transmitirla al equipo a cargo. Motivar e inspirar confianza. Tener valor para proponer o defender creencias, ideas y asociaciones que aseguren competitividad y efectividad a largo plazo. Gestionar situaciones, personas y tiempos, con una adecuada integración al equipo de trabajo y emprendiendo acciones eficaces para mejorar el talento y las capacidades de los demás.",
                "• Calificación: Implica tener amplios conocimientos de los temas del área que esté bajo su responsabilidad, demostrando compromiso por cumplir los procedimientos de la empresa para asegurar eficiencia interna y un constante estándar de servicio al paciente o al cliente interno. Demostrar capacidad para trabajar con las posiciones de su mismo nivel y de niveles diferentes. Compartir con los demás el conocimiento profesional y expertise. Demostrar constantemente interés en aprender.",
                "• Pensamiento estratégico: Es la habilidad para comprender rápidamente los cambios del entorno, las oportunidades del mercado, las amenazas competitivas y las fortalezas y debilidades de la organización a la hora de identificar la mejor respuesta estratégica. Gestiona el área de expertise a cargo en consonancia con la estrategia de la empresa y de manera asociada a las demás áreas."
            ];
            description["competencias_funcionales"] = [
                "• Involucramiento con normas y procedimientos organizacionales: Es el compromiso demostrado por utilizar los procedimientos de la empresa para asegurar eficiencia interna y un constante estándar de servicio al paciente y al cliente interno.",
                "• Modalidades de contacto: Es la capacidad de demostrar una sólida habilidad de comunicación clara: implica saber escuchar y hacer posible que los demás comprendan la información que se está brindando. Demuestra empatía e interés por los pacientes, atención y sensibilidad frente a sus inquietudes.",
                "• Flexibilidad: Es la capacidad para adaptarse y trabajar en distintas y variadas situaciones y con personas o grupos diversos. Supone entender y valorar posturas distintas o puntos de vista encontrados, adaptando su propio enfoque a medida que la situación cambiante lo requiera y promoviendo los cambios en la propia organización o las responsabilidades a su cargo.",
                "• Habilidad analítica: Tiene que ver con el tipo y alcance de razonamiento. Es la capacidad de identificar los problemas, reconocer la información significativa, buscar y coordinar los datos relevantes. El pensamiento analítico tiene una secuencia a seguir, va de lo general a lo particular y siempre está enfocado a encontrar una respuesta.",
                "• Acuracidad: Implica la precisión y exactitud con que se realizan los reportes y controles y la oportunidad de su presentación.",
                "• Fidelidad: Se trata de la lealtad en el cumplimiento de los compromisos establecidos para la posición, fundamentalmente respetando los intereses corporativos y sin perseguir beneficio propio por los contactos, recursos económicos ó demás activos tangibles e intangibles que la empresa pone a disposición para llevar adelante la tarea asignada.",
                "• Orientación a resultados: Es la tendencia al logro de resultados, fijando metas desafiantes por encima de los estándares, mejorando y manteniendo altos niveles de rendimiento, en el marco de las estrategias de la organización.",
                "• Precisión técnica y cuidado de las herramientas de trabajo: Hace referencia al cumplimiento de los pasos preestablecidos para el mantenimiento y/o reparación que se le encomienden, respetando los tiempos de trabajo y de atención a pacientes para procurar afectar mínimamente el normal desenvolvimiento de las tareas y siendo responsable por el cuidado y la conservación de las herramientas que la empresa pone a disposición para el trabajo que debe cumplir.",
                "• Disponibilidad de respuesta: Es la capacidad para responder ante el requerimiento del cliente interno, siguiendo las prioridades que establezca el responsable del área a quien reporta y trabajando con alta performance en situaciones de exigencia.",
                "• Fidelización del capital humano: Consiste en establecer y mantener relaciones cordiales y recíprocas con el cliente interno, fomentando un clima laboral positivo. Se trata de escuchar activamente y presentar soluciones de valor al cliente interno, bajo el concepto de actuar como funcionario de la empresa representando su imagen y valores e interpretando las necesidades de los colaboradores.",
                "• Confidencialidad y prudencia: Es la actitud de reserva y discreción, tanto en el relacionamiento con los demás colaboradores de la empresa como en el trato con personas ajenas a la misma.",
                "• Orientación al cliente: Demostrar empatía por las necesidades o exigencias del cliente interno, brindando una respuesta clara y oportuna. Tiene que ver con escuchar activamente y diagnosticar correctamente la necesidad para generar en consecuencia soluciones adecuadas, entendiendo las limitaciones técnicas del usuario en el planteamiento del problema y su comunicación.",
                "• Innovación: Es la capacidad para modificar las cosas incluso partiendo de formas o situaciones no pensadas con anterioridad. Implica idear soluciones nuevas y diferentes ante problemas o situaciones requeridos por el propio puesto, la organización y los clientes internos.",
                "• Orientación al paciente/médico/financiador: Demostrar empatía por las necesidades o exigencias que el cliente o paciente pueda requerir, concediendo la más alta calidad de atención y respuesta. Tiene que ver con escuchar activamente y diagnosticar correctamente la necesidad para generar en consecuencia soluciones adecuadas, entendiendo las limitaciones en el planteamiento del problema y su comunicación. Hace referencia a la habilidad de manejar el lenguaje corporal cuando la comunicación es de modo presencial.",
                "• Resolución de conflictos: Es la capacidad de entender las diferencias de intereses y buscar una solución que genere satisfacción al paciente o a quien requiere de nuestro servicio, atendiendo la factibilidad interna y escalando el conflicto cuando la situación lo amerite.",
                "• “Marcar la diferencia”:Es demostrar esmerada actitud y comportamiento al brindar una atención que se destaque por su calidad y calidez, tanto sea a través de la comunicación verbal como no verbal.",
                "• Prudencia:Es la actitud de reserva y discreción, tanto en el relacionamiento con los demás colaboradores de la empresa como en el trato con personas ajenas a la misma.",
                "• Responsabilidad social empresaria: Es la actitud de promover acciones solidarias en las comunidades y en el medio ambiente donde opera la empresa, generando conciencia social en los colaboradores e incentivándolos a la participación en dichas actividades.",
                "• Proactividad:Hace referencia a la actitud permanente de adelantarse a los demás en su accionar. Es la predisposición a actuar de forma anticipada y no sólo de manera reactiva. Implica marcar el rumbo por medio de acciones concretas, no sólo de palabras. Los niveles de actuación van desde concretar decisiones tomadas en el pasado hasta la búsqueda de nuevas oportunidades o soluciones de problemas."
            ];

            return (
                <Fragment>
                    <Tooltip title="mas info">
                        <Button
                            startIcon={<InfoIcon />}
                            onClick={() => this.handleCloseModal(props.type)}
                        >{props.title}
                        </Button>
                    </Tooltip>
                    <Dialog onClose={() => { this.handleCloseModal(props.type) }} data-index={this.props.type} aria-labelledby={this.props.type} open={this.state[props.type]} className={`dialog-${this.props.type}`}>
                        <DialogTitle id={this.props.type} onClose={() => { this.handleCloseModal(props.type) }}>
                            <Grid container>
                                <Grid md={11}>
                                    <Typography variant="h5">{props.title}</Typography></Grid>

                                <Grid md={1}><Icon onClick={() => { this.handleCloseModal(props.type) }} color="primary">close</Icon></Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent dividers className="dialogContent_root">
                            {description[props.type].map(val => { return <Typography>{val}</Typography> })}
                        </DialogContent>
                    </Dialog>
                </Fragment>

            )
        }
        return (
            <Fragment>
                {Object.keys(this.props.responsePlanCarrera).length > 0 ?
                    (<Redirect to={{
                        pathname: `/dashboard/`, state: {
                            responseForm: ' El formulario se envío correctamente',
                            error: false
                        }
                    }} />
                    )
                    :
                    (Object.keys(this.props.peticionData).length > 0) ?
                        (<Theme>
                            <div className={`cardView_container`} styles={configStyles}>
                                <Grid container spacing={1}>
                                    <Grid item md={3} ></Grid>
                                    <Grid item md={6} >
                                        {/*  <HeaderForm rev="Rev. 0" edicion="Edic. 4" fecha="Julio 2017" type="EVALUACION PLAN DE CARRERA" /> */}
                                        <Card >
                                            <CardHeader className="Detail_Action_ViewHeader"
                                                title={<div style={{ 'display': 'inline-block' }}>

                                                    <img src={logo} style={{ margin: '0 auto', float: 'left', 'width': '6%', 'maxWidth': '100%' }}></img>
                                                    <Typography style={{ 'paddingLeft': '8%' }} component="h4" variant="h4"><strong>Sistema de Gestión de la Calidad - Recursos Humanos</strong></Typography>
                                                </div>}
                                                subheader={<Typography style={{ 'paddingLeft': '8%' }} component="h4" variant="h4"><strong>Evaluacion Plan de Carrera </strong></Typography>}
                                                action={<Grid cointaner style={{ 'paddingTop': '10px' }} >
                                                    <Grid item xs={12}>
                                                        <Typography component="h6" variant="h6" style={{ 'textAlign': 'center' }} >Vigencia</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>

                                                        <Typography component="h6" variant="h6" style={{ 'textAlign': 'center' }}>Julio 2017</Typography>
                                                    </Grid>
                                                    <Grid container >
                                                        <Grid item xs={12}>
                                                            <Typography component="h6" variant="h6" >Edic. 4 / Rev. 0</Typography>
                                                        </Grid>

                                                    </Grid></Grid>}
                                            >
                                            </CardHeader>
                                            <CardContent className="Detail_Action_ViewContainer">
                                                <Grid container >

                                                    <Grid item xs={12} >
                                                        <Element name="warning" >
                                                            {this.state.warning &&
                                                                (<Alert severity="error">
                                                                    <AlertTitle>Error</AlertTitle>
                                Debe completar el formulario antes de enviarse.
                                                                </Alert>)}</Element>

                                                        <Grid item md={12} xs={12} >
                                                            <Typography className="size">Nombre y Apellido : {(Object.keys(this.props.peticionData).length > 0) ? this.props.peticionData.destinatario[0].name : ""}</Typography>

                                                        </Grid>

                                                        <Element name="vacaciones" >
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} md={4} sm={12} >
                                                                    <Typography className="size">Puesto</Typography>

                                                                    {(Object.keys(this.props.responseUser).length > 0) &&
                                                                        <String
                                                                            id="puesto"
                                                                            label=""
                                                                            name="puesto"
                                                                            placeholder=""
                                                                            defaultValue={this.props.responseUser[0].title}
                                                                            disabled={true}
                                                                        />
                                                                    }
                                                                </Grid>
                                                                <Grid xs={12} md={4} sm={12}>
                                                                    <Typography className="size">Ingreso</Typography>

                                                                    <DatePicker
                                                                        ref={element => { this.state.ingreso = element }}
                                                                        label=""
                                                                        name="ingreso"
                                                                        id="ingreso"
                                                                        defaultValue={null}
                                                                        placeholder="__/__/____"
                                                                        disabled={false}
                                                                        error={this.state.errors.ingreso}
                                                                        min={[20, null, null]}
                                                                        max={[null, null, null]}
                                                                        date={date}
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} md={4} sm={12}></Grid>

                                                                <Grid xs={12} md={6} sm={12} >
                                                                    <Typography className="size">Step actual del Plan de Carrera</Typography>
                                                                    <String
                                                                        id="stepActual"
                                                                        label=""
                                                                        name="stepActual"
                                                                        placeholder=""
                                                                        defaultValue={(Object.keys(this.props.peticionData).length > 0) ? this.props.peticionData.carrera.step_actual.nombre : ""}
                                                                        disabled={true}
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} md={6} sm={12} >
                                                                    <Typography className="size">Step del Plan de Carrera al cual avanza</Typography>
                                                                    <String
                                                                        id=""
                                                                        label=""
                                                                        name=""
                                                                        placeholder=""
                                                                        defaultValue={(Object.keys(this.props.peticionData).length > 0) ? this.props.peticionData.carrera.step_avanza.nombre : ""}
                                                                        disabled={true}
                                                                    />
                                                                </Grid>



                                                            </Grid>
                                                            {/*   <Grid container spacing={3} className="borderBody inputMargin">
                                            <WarningForm
                                                titulo="Importante: esta evaluación deberá ser remitida a Recursos Humanos antes del 20 de cada mes. "
                                                subitutlo=""
                                            />
                                        </Grid> */}
                                                            <Accordion className="collapsibleCard borderBody" expanded={this.state.expanded} defaultExpanded={true}>
                                                                <AccordionSummary
                                                                    style={{ backgroundColor: "#a31f35" }}
                                                                    expandIcon={<ExpandMoreIcon onClick={this.handleExpanded} />}
                                                                    aria-controls="filter-content"
                                                                    id="filter-header"

                                                                >
                                                                    <Grid container>
                                                                        <Grid item xs={12} md={12}>
                                                                            <Typography style={{ color: 'white', fontSize: '12px' }}>Calificación de cumplimiento con respecto al perfil </Typography>
                                                                        </Grid>
                                                                        <Grid item md={12}>
                                                                            <Typography style={{ fontSize: '9px', color: 'white' }}>(Pasando el mouse o haciendo click en cada competencia a evaluar podrás leer que significa cada una y los comportamientos que hacen a la misma)</Typography>
                                                                        </Grid>
                                                                        <Grid item md={12}>
                                                                            <ModalCompetencias title="Competencias generales" type="competencias_generales"></ModalCompetencias>
                                                                            <ModalCompetencias title="Competencias de nivel" type="competencias_de_nivel"></ModalCompetencias>
                                                                            <ModalCompetencias title="Competencias funcionales" type="competencias_funcionales"></ModalCompetencias>
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionSummary>
                                                                <AccordionDetails>

                                                                    <Grid container spacing={1} >

                                                                        <Grid item md={3} xs={2}>
                                                                            <p>-</p>
                                                                        </Grid>
                                                                        <Grid item md={2} xs={3}>
                                                                            <Typography>Supera las expectativas </Typography>
                                                                        </Grid>

                                                                        <Grid item md={2} xs={3}> <Typography>Cumple las expectativas</Typography></Grid>
                                                                        <Grid item md={2} xs={3}>  <Typography>Por debajo de las expectativas</Typography></Grid>
                                                                        <Grid item md={3} xs={1}>  </Grid>
                                                                        <Grid item md={3} xs={3}>
                                                                            <p>{this.props.peticionData.carrera.competencia.generica[0]}</p>
                                                                        </Grid>

                                                                        <Grid item md={8} xs={8} style={{ paddingLeft: '6%' }}>
                                                                            <RadioButton
                                                                                label={""}
                                                                                defaultValue={this.state.genericas0}
                                                                                defaultChecked={false}
                                                                                disabled={false}
                                                                                showLabel={false}
                                                                                row={true}
                                                                                name='genericas0'
                                                                                optionsSelect={getCalificacionPerfil()}
                                                                                callback={(value) => { this.callbackCompetencias('genericas0', value) }}
                                                                                paddingRight='20%'
                                                                            />
                                                                        </Grid>

                                                                        <Grid item md={1} xs={1}></Grid>
                                                                        <Grid item md={3} xs={3}>
                                                                            <p>{this.props.peticionData.carrera.competencia.generica[1]}</p>
                                                                        </Grid>
                                                                        <Grid item md={8} xs={8} style={{ paddingLeft: '6%' }}>
                                                                            <RadioButton

                                                                                label={""}
                                                                                defaultValue={this.state.genericas1}
                                                                                defaultChecked={false}
                                                                                disabled={false}
                                                                                showLabel={false}
                                                                                row={true}
                                                                                optionsSelect={getCalificacionPerfil()}
                                                                                callback={(value) => { this.callbackCompetencias('genericas1', value) }}
                                                                                paddingRight='20%'

                                                                            />
                                                                        </Grid>

                                                                        <Grid item md={1} xs={1}></Grid>
                                                                        <Grid item md={3} xs={3}>
                                                                            <p>{this.props.peticionData.carrera.competencia.de_nivel[0]}</p>
                                                                        </Grid>
                                                                        <Grid item md={8} xs={8} style={{ paddingLeft: '6%' }}>
                                                                            <RadioButton

                                                                                label={""}
                                                                                defaultValue={this.state.nivel0}
                                                                                defaultChecked={false}
                                                                                disabled={false}
                                                                                showLabel={false}
                                                                                row={true}
                                                                                optionsSelect={getCalificacionPerfil()}
                                                                                callback={(value) => { this.callbackCompetencias('nivel0', value) }}
                                                                                paddingRight='20%'

                                                                            />
                                                                        </Grid>

                                                                        <Grid item md={1} xs={1}></Grid>
                                                                        <Grid item md={3} xs={3}>
                                                                            <p>{this.props.peticionData.carrera.competencia.de_nivel[1]}</p>
                                                                        </Grid>
                                                                        <Grid item md={8} xs={8} style={{ paddingLeft: '6%' }}>
                                                                            <RadioButton

                                                                                label={""}
                                                                                defaultValue={this.state.nivel1}
                                                                                defaultChecked={false}
                                                                                disabled={false}
                                                                                showLabel={false}
                                                                                row={true}
                                                                                optionsSelect={getCalificacionPerfil()}
                                                                                callback={(value) => { this.callbackCompetencias('nivel1', value) }}
                                                                                paddingRight='20%'

                                                                            />
                                                                        </Grid>

                                                                        <Grid item md={1} xs={1}></Grid>
                                                                        <Grid item md={3} xs={3}>
                                                                            <p>{this.props.peticionData.carrera.competencia.funcional[0]}</p>
                                                                        </Grid>
                                                                        <Grid item md={8} xs={8} style={{ paddingLeft: '6%' }}>
                                                                            <RadioButton

                                                                                label={""}
                                                                                defaultValue={this.state.funcional0}
                                                                                defaultChecked={false}
                                                                                disabled={false}
                                                                                showLabel={false}
                                                                                row={true}
                                                                                optionsSelect={getCalificacionPerfil()}
                                                                                callback={(value) => { this.callbackCompetencias('funcional0', value) }}
                                                                                paddingRight='20%'

                                                                            />
                                                                        </Grid>

                                                                        <Grid item md={1} xs={1}></Grid>
                                                                        <Grid item md={3} xs={3}>
                                                                            <p>{this.props.peticionData.carrera.competencia.funcional[1]}</p>
                                                                        </Grid>
                                                                        <Grid item md={8} xs={8} style={{ paddingLeft: '6%' }}>
                                                                            <RadioButton

                                                                                label={""}
                                                                                defaultValue={this.state.funcional1}
                                                                                defaultChecked={false}
                                                                                disabled={false}
                                                                                showLabel={false}
                                                                                row={true}
                                                                                optionsSelect={getCalificacionPerfil()}
                                                                                callback={(value) => { this.callbackCompetencias('funcional1', value) }}
                                                                                paddingRight='20%'

                                                                            />
                                                                        </Grid>

                                                                        <Grid item md={1} xs={1}></Grid>

                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                            <Grid container spacing={2} >
                                                                <Grid item xs={12}>
                                                                    <Typography variant={"h5"} color="primary">Resultado de la solicitud (reflejado en porcentaje)</Typography>
                                                                </Grid>
                                                                <Grid item xs={3} >
                                                                    <Typography className="size">Supera las expectativas</Typography>
                                                                    <StringNumber
                                                                        id={`superaStandard`}
                                                                        label=""
                                                                        name={`superaStandard`}
                                                                        placeholder=""
                                                                        defaultValue={this.state.superaStandard}
                                                                        disabled={true}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography className="size">Cumple las expectativas</Typography>
                                                                    <StringNumber
                                                                        id={`dentroStandard`}
                                                                        label=""
                                                                        name={`dentroStandard`}
                                                                        placeholder=""
                                                                        defaultValue={this.state.dentroStandard}
                                                                        disabled={true}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={5}>
                                                                    <Typography className="size">Por debajo de las expectativas</Typography>
                                                                    <StringNumber
                                                                        id={`debajoStandard`}
                                                                        label=""
                                                                        name={`debajoStandard`}
                                                                        placeholder=""
                                                                        defaultValue={this.state.debajoStandard}
                                                                        disabled={true}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={12} sm={12}>
                                                                    <Typography className="size">Fecha de primer permiso ARN :{this.props.peticionData.carrera.fecha_primer_permiso !== null ? this.props.peticionData.carrera.fecha_primer_permiso : '-'}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Element>
                                                        <Divider style={{ height: 2, backgroundColor: "#a31f35" }} />
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} md={4} sm={12} >
                                                                <Typography className="size">Categoría</Typography>

                                                                <String
                                                                    ref={element => { this.state.categoria = element }}
                                                                    id="categoria"
                                                                    label=""
                                                                    name="categoria"
                                                                    placeholder=""
                                                                    defaultValue=""
                                                                    disabled={false}
                                                                    error={this.state.errors.categoria}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={4} sm={12}>
                                                                <Typography className="size">Sueldo básico</Typography>

                                                                <Number
                                                                    ref={element => { this.state.sueldoBasico = element }}
                                                                    id="sueldoBasico"
                                                                    label=""
                                                                    name="sueldoBasico"
                                                                    placeholder=""
                                                                    defaultValue=""
                                                                    minLength={1}
                                                                    maxLength={null}
                                                                    disabled={false}
                                                                    error={this.state.errors.sueldoBasico}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={4} sm={12} >
                                                                <Typography className="size">Adicional Vol.</Typography>

                                                                <String
                                                                    ref={element => { this.state.adicional = element }}
                                                                    id="adicional"
                                                                    label=""
                                                                    name="adicional"
                                                                    placeholder=""
                                                                    defaultValue=""
                                                                    disabled={false}
                                                                    error={this.state.errors.adicional}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} md={4} >
                                                                <Typography className="size">Otras condiciones</Typography>

                                                            </Grid>
                                                            <Grid item xs={12} md={4} >
                                                                <TextArea
                                                                    ref={element => { this.state.otrasCondiciones = element }}
                                                                    id="otrasCondiciones"
                                                                    name="otrasCondiciones"
                                                                    label=""
                                                                    error={this.state.errors.otrasCondiciones}
                                                                    defaultValue=""
                                                                    disabled={false}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={4} ></Grid>
                                                        </Grid>
                                                        {/*  <Grid container spacing={3}>
                                                            <Grid item xs={12} md={6}>
                                                                <Typography variant={"h5"} color="primary">Comentarios de la reunión de feedback</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} md={6} >
                                                                <TextArea
                                                                    ref={element => { this.state.comentarios = element }}
                                                                    id="comentarios"
                                                                    name="comentarios"
                                                                    label=""
                                                                    error={false}
                                                                    defaultValue=""
                                                                    disabled={false}
                                                                    handleError={false}
                                                                />
                                                            </Grid>
                                                        </Grid> */}
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography variant={"h5"} color="primary">Competencias para la solicitud</Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography className="size" color="primary">Genericas</Typography>

                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography className="size" color="primary">De nivel</Typography>

                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography className="size" color="primary">Funcionales</Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography className="size">(1){this.props.peticionData.carrera.competencia.generica[0]}</Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography className="size">(1){this.props.peticionData.carrera.competencia.de_nivel[0]}</Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography className="size">(1){this.props.peticionData.carrera.competencia.funcional[0]}</Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography className="size">(2){this.props.peticionData.carrera.competencia.generica[1]}</Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography className="size">(2){this.props.peticionData.carrera.competencia.de_nivel[1]}</Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography className="size">(2){this.props.peticionData.carrera.competencia.funcional[1]}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Divider style={{ height: 2, backgroundColor: "#a31f35" }} />
                                                        <Grid xs={12} md={12} sm={12}>

                                                            <Typography variant={"h5"} color="primary">Consideración final</Typography>

                                                            <RadioButton
                                                                ref={element => { this.state.confirmacion = element }}
                                                                name="cumplimientoPerfilActitudEquipo"
                                                                label={"¿Confirma avance en plan de carrera?"}
                                                                defaultValue={"Si"}
                                                                defaultChecked={true}
                                                                row={true}
                                                                disabled={false}
                                                                optionsSelect={getSiNo()}
                                                                showLabel={true}
                                                                paddingRight='0%'

                                                            />
                                                        </Grid>
                                                        <FirmasFormPlanCarrera
                                                            aprobadorFirm={(Object.keys(this.props.peticionData).length > 0) ? this.props.peticionData.aprobadores[0].name : ''}
                                                            aprobadorAclaracion={""}
                                                        />
                                                        <Button onClick={() => this.send()} variant="contained" className="enviar" /* disabled={loading} fullWidth  color="secondary" className="buttonSearch" */>
                                                            Finalizar Solicitud
                            </Button>

                                                    </Grid>

                                                </Grid>
                                            </CardContent>
                                        </Card>
                                        {/* <FooterForm /> */}
                                    </Grid>
                                    <Grid item md={3} ></Grid>
                                </Grid>
                            </div>
                        </Theme>
                        )
                        :
                        (this.loadingScreen())

                }

            </Fragment>
        )
    }
}
const mapStateToProps = reducers => {
    return reducers.listReducer;
};
const mapDispatchToProps = dispatch => {
    return {
        dispatchSendPlanCarreraAction: (form) =>
            dispatch(
                sendPlanCarreraAction(form),
            ),
        dispatchGetPeticionAction: (id) =>
            dispatch(
                getPeticionAction(id),
            ),
        dispatchGetUsernAction: (request) =>
            dispatch(
                getUserAction(request),
            ),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PlanCarrera);


