import React from "react";
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';

require('typeface-arimo');

const Theme = ({children}) => {

const styles = children.props.styles

const theme = createMuiTheme({
    typography: {
        fontFamily: styles.fonts,
        fontSize: styles.body.fontSize,
        backgroundColor: styles.body.background
    },
    palette: {
        primary: { main: styles.colors.primary},
        secondary: { main: styles.colors.secondary},
        white: { main: '#FFFFFF'},
        textColor: { main: styles.textColor},
        text: { primary: styles.textColor},
    },
    props: {
        MuiTable: {
            component:'div'
        },
        MuiTableRow: {
            component:'div'
        },
        MuiTableHead: {
            component:'div'
        },
        MuiTableBody: {
            component:'div'
        },
        MuiTableCell: {
            component:'div'
        },
        MuiFormControl: {
            variant: 'outlined',
            fullWidth: true,
            size: 'small',
            align: 'left',
            margin:'none'
        },
        MuiTextField: {
            margin: 'normal',
            size: 'small',
            variant: 'outlined',
            fullWidth: true
        },
        MuiFilledInput: {
            //margin: 'normal',
            size: 'small',
            variant: 'outlined',
            fullWidth: true,
        },
        MuiButton: {
            disableRipple: true,
            color: 'secondary',
            variant: 'contained'
        },
        MuiGrid: {
            alignContent: 'center',
        }
    },
    overrides: {
        MuiButton: {
            root: {textTransform: 'none'},
            contained: {color: styles.colors.primary, backgroundColor: '#f7f7f7'},
            containedPrimary: {
                backgroundColor: styles.colors.accentColor,
                "&:hover": { backgroundColor: styles.colors.accentColorHover }
            },
            outlined: {borderColor: styles.colors.primary}
        },
        MuiAvatar: {
            colorDefault: {backgroundColor: styles.colors.primary}
        },
        MuiTypography: {
            h1: { color: styles.colors.primary, fontFamily: styles.fontsHeadings, fontSize: 48, letterSpacing: "-1px" },
            h2: { color: styles.colors.primary, fontFamily: styles.fontsHeadings, fontSize: 40, letterSpacing: "-1px" },
            h3: { color: styles.colors.primary, fontFamily: styles.fontsHeadings, fontSize: 32, letterSpacing: "-1px" },
            h4: { color: styles.colors.primary, fontFamily: styles.fontsHeadings, fontSize: 12, letterSpacing: "-1px"},
            h5: { color: styles.colors.primary, fontFamily: styles.fontsHeadings, fontSize: 12, letterSpacing: "-0.5px", fontWeight: 500},
            h6: { color: styles.colors.primary, fontFamily: styles.fontsHeadings, fontSize: 12, letterSpacing: "-0.5px" },
            h7: {fontFamily: styles.fontsHeadings, fontSize: 12 },
        },
        MuiPaper: {rounded: {borderRadius: '12px'}},
        MuiTableCell: {
            root: {padding: '10px'},
            head: {fontWeight: 600, }
        },
        MuiListItemIcon:{
            root:{minWidth: 40}
        }
    }
});

const drawerWidth = 265;

const GlobalCSS = withStyles({
"@global": {
    "body": { margin: 0, padding: 0,  backgroundColor: styles.body.background, color: styles.body.color },
    ".Select-SimpleSelect":{
        "& .MuiPaper-root": {
            maxHeight: '340px',
        }
    },
    ".container_chips": {
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap',
        '& > *': {
        margin: theme.spacing(0.5),
        }
    },
    ".chips_roles": {
        "& .MuiChip-label": {
            fontSize: 11
        }
    },
    ".Select-MultipleSelect":{
        "& .MuiInputLabel-outlined":{
            transform: 'translate(14px, 42px) scale(1);'
        },
        "&.SelectSmall":{
            "& .MuiInputLabel-outlined":{
                transform: 'translate(14px, 34px) scale(1);'
            },
        },
        "&.MuiInput-underline:before":{
            border: 'none'
        },
        "&.MuiInput-underline:hover:not(.Mui-disabled):before":{
            border: 'none'
        },
        "& .MuiPaper-root": {
            maxHeight: '340px',
        },
        "& .MuiSelect-selectMenu": {
            borderColor: 'rgba(0, 0, 0, 0.23)',
            border: '1px solid',
            paddingLeft: '14px',
            paddingTop: '18.5px',
            paddingBottom: '18.5px'
        },
        "& .MuiInputLabel-shrink":{
            transform: 'none'
        },
        "& .MuiInput-formControl":{
            marginTop: 0
        }
    },
    ".SelectSmall":{
        "& .MuiSelect-selectMenu": {
            paddingTop: 10.5,
            paddingBottom: 10.5
        },
    },
    ".wrapper": {display: 'flex'},
    ".appBar": {
		zIndex: theme.zIndex.drawer + 1,/*
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}), */
	},
	".appBarShift": {
		/* marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}), */
    },
    ".drawerPaper": {
		position: 'relative',
		whiteSpace: 'nowrap',
        width: drawerWidth,
        minHeight: '100vh',

        border: 'none',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
        }),
        overflow: 'hidden'
	},
	".drawerPaperClose": {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: 56,
		},
    },
    ".menuButton": {
		marginRight: 36,
	},
	".menuButtonHidden": {
		display: 'none',
    },
    ".menuIsologo": {
        marginRight: 22,
        marginLeft: 8
	},
	".menuIsologoHidden": {
		display: 'none',
    },
    '.LayoutContainer': {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
    },
    ".paperDrawer":{
        padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
    },
    ".app_bar_spacer": theme.mixins.toolbar,
    ".MainLayout_wrapper":{
        display: 'flex',
        "& .toolbar_Appbar":{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        "& .toolbarDrawer": {
            //backgroundColor: 'red',
            //display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            //padding: '0 8px',
            //maxHeight: '64px',
            ...theme.mixins.toolbar,
        },
        "& .toolbarDrawerSidebar": {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        "& .toolbarDrawerButton":{
            color: 'white',
            position: 'absolute',
            top: 72,
            right: -25,
            backgroundColor: styles.body.background,
            padding: 10,
            "& .MuiIconButton-label":{
                position: "relative", left: "-6px"
            }

        },
        "& .toolbarLogo":{
            maxHeight: '35px',
            marginLeft: 10,
            marginRight: 'auto',
            display: 'none',
        },
        "& .toolbarLogoShow": {
            display: 'block',
        },
        "& .main_admin": {
            flexGrow: 1,
            //height: '100vh',
            overflow: 'auto',
        },
        "& .title_Dashboard": {
            flexGrow: 1,
            marginLeft: 16
        }
    },
    ".main":{ flexGrow: 1, height: '100vh', overflow: 'auto'},
    ".login":{
        backgroundColor: 'white',
        textAlign: 'left',

        /* "& .form":{
            width: '80%'
        }, */
        /* "& .submit":{
            margin: theme.spacing(3, 0, 2),
        }, */
        "& .title": {
            //marginBottom: theme.spacing(1),
            marginBottom: 0,
        },
        /* "& .subtitle": {
            marginBottom: theme.spacing(4),
        }, */
        "& .form-header":{
            marginBottom: theme.spacing(4),
        }
    },
    ".login_container":{
        padding: theme.spacing(4)
    },
    ".headerLogoText": {
        textAlign: "left"
    },
    ".main.login":{
        "& .MuiGrid-align-content-xs-center": {
            alignContent: "stretch",
        }
    },
    ".bg-login":{
        backgroundColor: styles.colors.primary,
        backgroundPosition: 'center',
        backgroundSize: "cover",
        "& .logo":{display: "block", position: "relative", top: "80px", maxWidth: "250px"}
    },
   /*  ".paper": {
        display: 'flex',
        flexDirection: 'column',

    }, */
    ".paper": {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    ".container":{
        paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
    },
    ".mainLayout_container":{
        paddingTop: theme.spacing(2.5)
    },
   
    ".content_section":{
        backgroundColor: 'rgba(255,255,255,.5)'
    },
    ".filters": {
        "& .MuiFormControl-root": {
            marginRight: 8
        }
    },
    ".filters_section_container":{
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
        padding: 0
    },
    ".filters_section_left": {flexGrow: 1,},
    ".filters_section_actions":{
        marginRight: 0
    },
    ".filter_tablecell":{
        "& .MuiFormControl-root":{
            paddingTop: 0,
            marginBottom: 0
        },
        "& .MuiFormLabel-root": {
            display: 'none'
        }
    },
    ".header_section_container": {
        paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(0),
    },
    ".header_detail_dashboard": {
        display: 'flex',
        alignItems: 'start',
        marginBottom: theme.spacing(3),
        padding: 0,
    },
    ".header_detail_dashboard_avatar_title":{
        flexGrow: 1,
    },
    ".header_detail_dashboard_actions": {

    },
    ".header_detail_dashboard_avatar" : {
        flex: "0 0 auto", marginRight: 16
    },
    ".header_detail_dashboard_title" : {
        flex: "1 1 auto",
        "& .section" :{
            textTransform: 'uppercase',
            color: styles.textColor,
            display: 'block',
            fontSize: 14,
            fontWeight: 600
        },
        "& .title" :{
            fontSize: 22,
            fontWeight: 500
        }
    },

    ".content_detail_dashboard": {
        //marginBottom: theme.spacing(4)
    },
    ".content-center": {
        display: 'flex',
        flexDirection: 'column',
    },
    ".MuiFormControl-root":{
        textAlign: 'left',
        paddingTop: 5,
        marginBottom: 5,
        paddingBottom: 5,
        //sin overflow por los mensajes de validación
        [theme.breakpoints.down('sm')]: { marginBottom: 12 },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            color: styles.inputsLabelColor,
            transform: "none",
            whiteSpace: "nowrap",
            fontSize: styles.inputsLabelFontSize,
            fontWeight: styles.inputsLabelFontWeight,
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "100%",
        },
        "& fieldset": {
            top: 0,
            "& legend": { display: "none" },
        }
    },
    ".card_button":{ marginRight: 8 },
    ".button_white_outlined.MuiButton-outlined":{borderColor: 'white', color: 'white'},
    ".detail_dashboard": {

    },
    ".BreadcrumbsDashboard": {
        display: 'flex',
        width: '100%',
        marginBottom: 0,
        //fontSize: 14,
        //"& .text":{ fontSize: 14 }
        "& a ":{color: styles.textColor},
        "& a:hover ":{color: styles.linkColorHover}
    },
    ".tabTitle": {
        "& .MuiTab-wrapper": {
            flexDirection: 'row',
            "& span": {display: 'inline-block'},
            "& .MuiIcon-root": { marginRight: 10 }
        }

    },
    ".toolbarTab":{
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        display: 'flex',

        "& .toolbarTab_buttons":{
            marginRight: 0
        }
    },
    ".toolbar_tab_title":{
        flexGrow: 1,
        textTransform: 'uppercase'
    },
    ".tableList":{
        width: '100%',
        marginBottom: theme.spacing(2),
        "& .tableList_table":{
            minWidth: 750,
            "& .MuiTableRow-root":{
                "& .MuiTableCell-root:firts-child":{
                    width: '40%'
                },

            }
        },
    },
    ".visuallyHidden":{
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    ".container_chips": {
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap',
        '& > *': {
        margin: theme.spacing(0.5),
        }
    },
    ".chips_roles": {
        "& .MuiChip-label": {
            fontSize: 11
        }
    },
    ".buttons_actions": {
        '& > *': {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        }
    },
    ".MenuHeader_Avatar":{
        color: '#FFFFFF',
        backgroundColor: 'rgba(255,255,255,.2)',
        overflow: 'visible'
    },
    ".MenuHeader_Badge":{
        "& .MuiBadge-badge":{backgroundColor: 'orange'}

    },
    ".button_lowecase":{
        "& .MuiTypography-root":{textTransform: 'none'}
    },
    ".MenuLogin":{
        display: 'flex'
    },
    ".Detail_Action_ViewHeader": {
        backgroundColor: '#f5f5f5',
    },
    ".Detail_Action_ViewContainer":{
        padding: theme.spacing(2),
    },
    ".DashboardCardContracted":{
        "& .MuiCardHeader-action":{margin: 0},
        "& .icon":{
            padding: 8,
            display: 'block',
            width: 40,
            height: 40,
            borderRadius: '50%'
        },
        "&:hover":{
            "& .icon": {
                backgroundColor: '#f0f0f0',
            }
        }
    },
    ".chipLastLogin": {
        float: 'right'
    },
    ".dialogTitle_root": { margin: 0, padding: theme.spacing(2) },
    ".dialogTitle_closeButton": { position: 'absolute', right: theme.spacing(1), top: theme.spacing(1), color: theme.palette.grey[500] },
	".dialogActions_root": { margin: 0, padding: theme.spacing(1) },
    ".dialogContent_root": { padding: theme.spacing(2) },
    ".buttonDelete":{
        backgroundColor: '#ec4139',
		color: '#fff',
		margin: '8px',
		"&:hover": {
			backgroundColor: '#ff3b31'
		}
    },
    ".dashboardCardTabs-root":{
        flexGrow: 1, width: '100%'
    },
    ".sidebarnav-list":{
        width: '100%',
        maxWidth: 380,
        backgroundColor: theme.palette.background.paper,
    },
    ".nested":{
        "& .MuiListItem-root":{
            paddingLeft: theme.spacing(3.5),
        },
        "& .nested":{
            "& .MuiListItem-root":{
                paddingLeft: theme.spacing(4.5),
            }
        }
    },
    ".tabPanel_content":{
        padding: theme.spacing(2),
        minHeight:400
    },
    ".cardView_container": {
        
        width:'100%'
    },
    ".large": {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    ".MuiCardHeader-title":{
        fontSize: 16
    },
    ".toolbarTab_title ":{
        fontSize: 16
    },
    ".avatar_square":{

    },
    ".cardView_Tours":{
        //marginTop: '-30px!important',

        "& .icon_added":{
            position: 'absolute',
            backgroundColor: 'white',
            //border: '2px solid white',
            top: 0,
            left: 0,
            height: 24,
            width: 24,
            borderRadius: '50%',
            boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
            textAlign: 'center',
            color: 'green',
            zIndex: 10

        },
        "& .tour":{
            position: 'relative',
            overflow: 'visible',
            borderBottom: '1px solid #d2d2d2',
            marginBottom: theme.spacing(1.5),
            paddingBottom: theme.spacing(1),
            borderRadius: 0
        },
        "& .title_card_tour":{
            paddingRight: 100,
            position: 'relative',
            lineHeight: 1.2,
            fontSize: 18,
            "& .MuiChip-root": {
                marginLeft: theme.spacing(1.5),
                position: 'absolute',
                right:0,
                top: 0
            },
        },
        "& .col_title":{
            //marginBottom: theme.spacing(1)
        },
        "& .collapse_content_container":{
           paddingTop: theme.spacing(1.5),
           "& .title": {
                color: styles.headingsColor,
                fontWeight: 600
           }
        },
        "& .MuiListItem-root":{
            paddingTop: 0,
            paddingBottom: 0,
            alignItems: "start",
            marginBottom: 8
        },
        "& .MuiListItemText-root":{
            marginTop: 0,
            marginBottom: 0
        },
        "& .MuiListItemSecondaryAction-root":{
            top: 26,
            right: 8
        },
        "& .listItemTour":{
            "& .MuiAvatar-root":{
                width: 45,
                height: 45,
            },
           "& .MuiListItemText-primary": {
                color: styles.headingsColor,
                fontWeight: 600,
                fontSize: 13,
                letterSpacing: 'normal',
                lineHeight: '1.2em',
                marginBottom: theme.spacing(0.5)
           },
           "& .MuiListItemText-secondary":{
                fontSize: 12,
                letterSpacing: 'normal',
                lineHeight: 1.2
           },
           "& .MuiListItemText-root ":{
               borderBottom: '1px solid #d2d2d2',
               paddingBottom: theme.spacing(1)
           }

        },
        "& .tabPanel_content":{
            padding: 0
        },

        "& .col_left":{
            "& .MuiAvatar-root":{
                width: 70,
                height: 70
            },
            [theme.breakpoints.up('md')]:{
                maxWidth: 80,
                flexBasis: 80,
            }
        },
        "& .col_center":{
            [theme.breakpoints.up('md')]:{
                maxWidth: 'calc(80% - 80px)',
                flexBasis: 'calc(80% - 80px)',
            }
        },
        "& .col_right":{
            textAlign: 'right',
            [theme.breakpoints.up('md')]:{
                maxWidth: '20%',
                flexBasis: '20%',
            }
        },
        "& .MuiCardContent-root":{
            padding: theme.spacing(1.5)
        },

        "& .MuiCardContent-root:last-child":{
            padding: theme.spacing(1.5)
        },
    },
    ".collapse_link":{
        padding: 0,
        textDecoration: 'none',
        cursor: 'pointer',
        "&:hover":{
            textDecoration: 'none',
        }
    },
    ".collapse":{
        "& .expand": {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
          },
          "& .expandOpen": {
            transform: 'rotate(180deg)',
          },
    },
    ".MuiCardContent-root":{
        paddingBottom: theme.spacing(1.5)
    },
    ".MuiCardContent-root:last-child":{
        paddingBottom: theme.spacing(1.5)
    },
    ".MuiList-root": {
        "& .MuiListItem-container:last-child": {
            "& .MuiListItem-root":{
                marginBottom: 0
            }
        }
    },
    ".accordion_paper":{
        padding: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5)
    },
    ".custom_accordion":{
        margin: 0,
        backgroundColor: 'transparent',
        padding: 0,
        boxShadow: 'none',
        "& .MuiAccordionSummary-root":{
            paddingLeft: 0,
        },
        "& .MuiAccordionSummary-content":{
            margin: 0
        },
        "& .MuiAccordionDetails-root":{
            padding: 0,
            display: 'block'
        },
        "& .MuiAccordionSummary-root.Mui-expanded":{
            minHeight: 'inherit'
        }
    },
    ".filter_tours_chips":{
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'nowrap',
        listStyle: 'none',
        margin: 0,
        borderRadius: theme.spacing(0.5),
       
        borderColor: styles.colors.lightGrey,
        "& .chip":{
            margin: theme.spacing(0.5),
        },
        "& .title_filters":{
            padding: theme.spacing(1),
            textTransform: 'uppercase',
            fontSize: 14,
            lineHeight: '26px'
        },
        "& .chips":{
            "&.filled":{
                flexGrow: 1
            },
        },
        "& .autocomplete_container":{
            flexGrow: 1,
            minWidth: 400,
            display: 'flex',
        },
        "& .button_filter":{
            padding: 4,
        },
        "& .MuiFormControl-root":{
            marginBottom: 0,
            paddingTop: 0
        },
        "& .MuiFormLabel-root":{
            display: 'none'
        },
        "& .MuiInputBase-root":{
            padding: theme.spacing(0.5)
        }
    },
    ".MuiCardHeader-root": {
        backgroundColor: "#f7f7f7",
        padding:'9px'
    },
    ".tableFilter": {
        backgroundColor: styles.colors.utralightGrey,
        border: "2px solid",
        borderColor: styles.colors.primary,
        "& .MuiButtonBase-root": {
            margin: 5
        },
        "& .tableFilterCelda": {
            padding: "2px 10px"
        },
        "& .filterButtonLabel": {
            fontSize: 15,
            color: styles.colors.primary,
            paddingLeft: 5
        },
    },
    ".main_admin": {
        "& .MuiGrid-root":  {
        },
        "& .MuiFormControl-root":{
            margin: 0
        },
        "& .alertTopCardContent":{
            marginBottom: 15,
        },
        "& .inlineModules":{
            display: 'flex',
            justifyContent: 'start',
            flexWrap: 'wrap',
            "& .bloqAutocomplete":{
                flexGrow: 1,
            },
            "& .bloqButton":{
                paddingTop: 23,
                paddingLeft: 5,
            },
            "& .MuiFormControl-root":{
            },
        },

        "& .bloqTabs": {
            "& .MuiTabs-flexContainer": {
                backgroundColor: styles.colors.utralightGrey
            },
            "& .MuiTab-root": {
                backgroundColor: styles.colors.primary,

            },
            "& .MuiAppBar-root": {
                boxShadow: "none",
                borderBottom: "1px solid #1751a1"
            },
            "& .borderBox": {
                border: "1px solid",
                borderColor: styles.colors.primary
            }
        },
        "& .bloqProductImage": {
            border: "1px solid #e4e4e4",
            borderRadius: 8,
            "& .imageProduct": {
                maxWidth: 150,
                margin: "0 auto",
                display: "flex",
                padding: 15,
            }
        },
        "& .bloqProductUpFile": {
            display: "flex",
            "& input": {
                display: "none"
            },
            "& .MuiButton-root":{
                marginTop: 22
            },
            "& .divButton":{
                flexGrow: 1,
            },
            "& .divMessage":{
                flexGrow: 4,
            },

        },
        "& .bloqCheckboxList": {
            width: "32%",
            border: "2px solid #dedede",
            padding: 0,
            display: "inline-block",
            verticalAlign: "top",
            margin: 5,
            "& .MuiListSubheader-root": {
                backgroundColor: styles.colors.lightGrey,
                fontWeight: 600,
                paddingLeft: 10,
                borderBottom: "2px solid #1751a1",
            },
            "& .MuiListItem-gutters": {
                padding: "0px 10px 0px 25px",
                borderBottom: styles.colors.lightGrey
            },
            "& .MuiListItem-gutters:first-child": {
                backgroundColor: "#dedede"
            },
            "& .MuiListItemIcon-root": {
                minWidth: "auto"
            },
        },
        "& .datePickerFromTo": {
            "& .bloqTitle": {
                display: "block",
            },
            "& .bloqDatePicker": {
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "flex-end",
                "& .dateFrom": {
                    display: "flex",
                    flexWrap: "nowrap",
                    alignItems: "flex-end",
                    "& .label": {
                        padding: 5
                    },
                    "& .datepicker": {
                        padding: 5,
                    },
                },
                "& .dateTo": {
                    display: "flex",
                    flexWrap: "nowrap",
                    alignItems: "flex-end",
                    "& .label": {
                        padding: 5,
                    },
                    "& .datepicker": {
                        padding: 5,
                    },
                },
                "& .MuiFormControl-root": {
                    padding: 0,
                    margin: 0
                }
            }
        },
        "& .inputMaxMin": {
            "& .bloqTitle": {
                display: "block",
            },
            "& .bloqDatePicker": {
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "flex-end",
                "& .dateFrom": {
                    display: "flex",
                    flexWrap: "nowrap",
                    alignItems: "flex-end",
                    "& .label": {
                        padding: 5,
                    },
                    "& .datepicker": {
                        padding: 5,
                    },
                },
                "& .dateTo": {
                    display: "flex",
                    flexWrap: "nowrap",
                    alignItems: "flex-end",
                    "& .label": {
                        padding: 5,
                    },
                    "& .datepicker": {
                        padding: 5,
                    },

                },
                "& .MuiFormControl-root": {
                    padding: 0,
                    margin: 0
                },
            }
        },
        "& .MuiFormControl-root .MuiInputLabel-outlined.MuiInputLabel-shrink, & .formLabel": {
            fontSize: 12,
            // color: styles.colors.primary,
            fontWeight: 500,
            fontFamily: styles.fonts
        },
        "& .formGridItem": {
            marginBottom: 20
        },
        "& .cardTableContainer": {
            "& .tableMaterial": {
                "& .MuiTableHead-root": {
                    "& .MuiTableCell-head": {
                        color: styles.colors.primary,

                    },
                },
                "& ..MuiTableRow-root": {
                    paddingTop: 0,
                },
                "& .MuiTableCell-root": {
                    padding: "8px 10px",
                }
            }
        },
        "& .tableListActionsButtons": {
            "& .iconButton": {
                margin: 3,
                backgroundColor: styles.colors.utralightGrey,
                "&:hover": {
                    backgroundColor: styles.colors.lightGrey
                }
            }
        },
        "& .tableListModule": {
            "& .tableMaterial": {
                "& .tableModuleOffices": {
                    "& .titleAndSubtitle": {
                        "& .cellTitle": {
                            color: styles.colors.primary,
                            fontWeight: "600",
                            fontSize: 14,
                        },
                        "& .cellSubtitle": {
                            fontSize: 10,
                            textTransform: "uppercase"
                        },
                    },
                }
            }
        },
        "& .repeatInputsModule": {
            border: "1px solid grey",
            borderColor: styles.colors.lightGrey,
            padding: "10px 15px",
            "& .titulo": {
                backgroundColor: styles.colors.utralightGrey,
            },
            "& .iconButton": {
                margin: 3,
                backgroundColor: styles.colors.utralightGrey,
                "&:hover": {
                    backgroundColor: styles.colors.lightGrey
                }
            }
        },
        "& .subGrupoModulo": {
            margin: 3,
            "& .grupoTitulo": {
                backgroundColor: styles.colors.primary,
                color: "white",
                padding: "5px 15px",
                "& .titulo": {
                    color: "white",
                },
            },
            "& .grupoCuerpo": {
                border: "1px solid",
                borderColor: styles.colors.lightGrey,
                padding: 10,
            },
        },
        "& .contentNotFound": {
            textAlign: "center",
            "& .image": {
                width: 50,
                height: 50,
            },
            "& .bigText": {
                fontSize: 18,
            },
            "& .smallText": {
                fontSize: 14,
            },
            "& .icon": {
                fontSize: 18,
            },
        },
        "& .tableDragDrop": {
            width: "100%",
            padding: 7,
            "& .alignCenter": {
                textAlign: "center"
            },
            "& .alignLeft": {
                fontSize: 14,
                paddingLeft: 15
            },
            "& thead": {
                backgroundColor: styles.colors.primary,
                color: "white",
                "& th": {
                    fontWeight: 500,
                    textAlign: "left",
                    padding: 5
                },
            },
            "& td": {
                padding: 5
            },
            "& tr:nth-child(even)": {
                backgroundColor: styles.colors.utralightGrey
            },
            "& .MuiFormControl-root": {
                paddingTop: 0,
            }
        },
        "& .MuiButton-root.optionSelected": {
            backgroundColor: "green"
        },
        "& .MuiButton-root.optionSelected": {
            backgroundColor: "green"
        }
    },
    // Nivel Superior Generico de Anidamiento
    ".bloqWhiteTabs": {
        "& .MuiTabs-flexContainer": {
            borderBottom: "2px solid #1751a1"
        },
        "& .MuiTab-root": {
            border: "1px solid",
            borderColor: styles.colors.primary,
            opacity: 1,
            borderBottom: "none",
        },
        "& .MuiButtonBase-root": {
            padding: "5px 10px",
            minHeight: 35
        },
        "& .MuiTabs-indicator": {
            display: "none"
        },
        "& .MuiTab-root.Mui-selected": {
            backgroundColor: styles.colors.primary,
            "& .MuiTypography-root": {
                color: "white"
            }
        },
        "& .MuiAppBar-root": {
            boxShadow: "none",
            borderBottom: "1px solid #1751a1"
        },
        "& .borderBox": {
            border: "1px solid",
            borderColor: styles.colors.primary
        }
    },
    ".tableOptions": {
        width: "100%",
        borderColor: "styles.colors.lightGrey",
        marginTop: 15,
        "& thead": {
            backgroundColor: styles.colors.lightGrey,
            // color: "white"
        },
        "& td": {
            padding: 5,
        },
        "& tr:nth-child(even)": {
            backgroundColor: styles.colors.utralightGrey
        },
        "& .MuiFormControl-root": {
            paddingTop: 0,
        }
    },
    ".chipsContainer": {
        "& .MuiChip-root": {
            marginRight: 10,
            padding: 5,
        }
    },
}})(() => null);

return <ThemeProvider theme={theme}><GlobalCSS/>{children}</ThemeProvider>
};

export default Theme;

