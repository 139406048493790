import React, { Component, Fragment } from 'react'
import { DialogContent, DialogTitle, Dialog, Tooltip, Divider, Select, CardContent, Card, CardHeader, Grid, Icon, Typography, TextField, InputAdornment, Button } from '@material-ui/core';
import { connect } from "react-redux";
import '../../assets/Form.css';
import DateValid from '../inputs/DateValid';
import StringNumber from '../inputs/StringNumber';
import config from '../../config/config.json';
import Chips from '../inputs/Chips';
import { sendPeticionAction, getRRHHAction, getUsuariosAction, getAprobadoresByDepartmentAction } from '../../actions/PeticionAction'
//import Navbar from '../Navbar';
import { Element, scroller, animateScroll as scroll } from 'react-scroll';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Redirect } from "react-router-dom";
import logo from '../../assets/atomo.png';
import { getSiNo, getCompetenciasDeNivel, getCompetenciasFuncionales, getCompetenciasGenerales } from '../../services/InfoFormService';
import RadioButton from '../inputs/RadioButtons';
import configStyles from '../../config/style.json';
import Theme from '../../assets/Theme';

class PeticionPlanCarrera extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        date.setDate(date.getDate() + config.fecha_expiracion["plan_carrera"]);
        this.state = {
            responsable: '',
            fechaExpiracion: '',
            aprobadores: [],
            destinatario: '',
            date: date,
            aprobadoresChip: [],
            fechaPrimerPermiso: '',
            peticionDate: null,
            disabledARN: true,
            stepActual: "",
            stepAvanza: "",
            competenciasFuncionales: [],
            competenciasDenivel: [],
            competenciasGenerales: [],
            competencias_funcionales: false,
            competencias_generales: false,
            competencias_de_nivel: false,
            errors: {
                responsable: false,
                destinatario: false,
                fechaExpiracion: false,
                fechaPrimerPermiso: false,
                aprobadores: false,
                stepActual: false,
                stepAvanza: false,
                competenciasFuncionales: false,
                competenciasDenivel: false,
                competenciasGenerales: false,
            },
            create: false,
            valueAutocomplete: ''

        }
        this.aprobadoresId = []

    }

    componentDidMount() {

        this.props.dispatchGetRRHHAction();
        this.props.dispatchGetUsuariosAction();
    }

    componentDidUpdate(prevProps, prevState) {

        if (Object.keys(prevProps.responseRRHH).length > 0 && this.state.responsable === '') {

            let responsable = prevProps.responseRRHH.filter(e => e.name === 'Berenice Bisignano Burgos')
            this.setState({ responsable: responsable[0] })
        }
        if (Object.keys(this.props.responseAprobadoresByDepartment).length > 0 && this.state.aprobadoresChip.length === 0) {
            let apro = this.props.responseAprobadoresByDepartment[0];
            apro["status"] = "0";
            this.aprobadoresId.push(apro)
            let chip = [];
            chip.push({ 'id': apro.email, "label": apro.name, 'selected': false })

            this.setState({ aprobadoresChip: chip })
        }
    }

    send = () => {
        let countValid = 0;
        let count = 0;
        let form = new Object();
        let formPeticion = new Object();
        let formPlanCarrera = new Object();
        let step_actual = new Object();
        let step_avanza = new Object();
        let errors = [];
        let nivel = [];
        let funcional = [];
        let genericas = [];
        let competencia = new Object();
        console.log("ESTADOS", this.state)

        count++;
        if (this.state.competenciasDenivel.length > 0) {
            countValid++;
            nivel.push(this.state.competenciasDenivel[0])
            nivel.push(this.state.competenciasDenivel[1])
        }
        count++;
        if (this.state.competenciasFuncionales.length > 0) {
            countValid++;
            funcional.push(this.state.competenciasFuncionales[0])
            funcional.push(this.state.competenciasFuncionales[1])
        }
        count++;
        if (this.state.competenciasGenerales.length > 0) {
            countValid++;
            genericas.push(this.state.competenciasGenerales[0])
            genericas.push(this.state.competenciasGenerales[1])
        }

        competencia["generica"] = genericas;
        competencia["de_nivel"] = nivel;
        competencia["funcional"] = funcional;


        formPlanCarrera["competencia"] = competencia;

        // Plan de carrera
        if (this.state.disabledARN) {
            console.log("1 sin dato")

            formPlanCarrera["fecha_primer_permiso"] = null;

        } else {
            if (this.state.fechaPrimerPermiso && this.state.fechaPrimerPermiso !== null) {
                count++;
                if (this.state.fechaPrimerPermiso.state.selectedDate !== '' && this.state.fechaPrimerPermiso.state.selectedDate !== null) {
                    countValid++;
                    console.log("1 con dato")
                    let date = this.state.fechaPrimerPermiso.state.selectedDate.split("/")
                    formPlanCarrera["fecha_primer_permiso"] = date.join("-");
                }
            }

        }

        if (this.state.stepActual && this.state.stepActual !== null) {
            count++;
            if (this.state.stepActual.state.defaultValue !== '') {
                countValid++;
                console.log("2")

                step_actual["nombre"] = this.state.stepActual.state.defaultValue;
            }
        }

        formPlanCarrera["step_actual"] = step_actual;
        formPlanCarrera["cumplimiento"] = null;
        formPlanCarrera["resultado_standard"] = null;
        formPlanCarrera["avance"] = '';
        formPlanCarrera["comentarios"] = '';
        formPlanCarrera["director"] = '';
        formPlanCarrera["status"] = "0";
        formPlanCarrera["aprobador"] = null;


        if (this.state.stepAvanza && this.state.stepAvanza !== null) {
            count++;
            if (this.state.stepAvanza.state.defaultValue !== '') {
                countValid++;
                console.log("3")

                step_avanza["nombre"] = this.state.stepAvanza.state.defaultValue;
            }
        }
        formPlanCarrera["step_avanza"] = step_avanza;
        count++;
        if (this.state.responsable !== '') {
            countValid++;
            console.log("4")

            formPeticion["mail_notificacion"] = this.state.responsable.email;
        }

        count++;
        if (this.state.destinatario !== '') {

            countValid++;
            console.log("5")

            let destinatario = this.state.destinatario;
            destinatario["status"] = "0";
            formPeticion["destinatario"] = [destinatario];
            formPlanCarrera["nombre"] = this.state.destinatario.name
            formPlanCarrera["apellido"] = this.state.destinatario.name
        }

        formPeticion["tipo_peticion"] = "2";
        count++;
        if (Object.keys(this.aprobadoresId).length > 0) {
            countValid++;
            console.log("6")

            formPeticion["aprobadores"] = this.aprobadoresId;
        }

        if (this.state.fechaExpiracion && this.state.fechaExpiracion !== null) {
            count++;
            if (this.state.fechaExpiracion.state.selectedDate !== '' && this.state.fechaExpiracion.state.selectedDate !== null) {
                countValid++;
                console.log("7")

                let date = this.state.fechaExpiracion.state.selectedDate.split("/")
                formPeticion[this.state.fechaExpiracion.props.name] = date.join("-");
            }
        }

        errors = {
            stepActual: (this.state.stepActual && this.state.stepActual.state.defaultValue !== '' && this.state.stepActual.state.errorText === '') ? false : true,
            stepAvanza: (this.state.stepAvanza && this.state.stepAvanza.state.defaultValue !== '' && this.state.stepAvanza.state.errorText === '') ? false : true,
            fechaPrimerPermiso: (this.state.disabledARN ? false : this.state.fechaPrimerPermiso && this.state.fechaPrimerPermiso.state.selectedDate !== null && this.state.fechaPrimerPermiso.state.errorText === '') ? false : true,
            responsable: (this.state.tipoPeticionValue === 1) ? false : (this.state.responsable !== '') ? false : true,
            destinatario: (this.state.destinatario !== '') ? false : true,
            aprobadores: (Object.keys(this.aprobadoresId).length > 0) ? false : true,
            competenciasDenivel: this.state.competenciasDenivel.length > 0 ? false : true,
            competenciasFuncionales: this.state.competenciasFuncionales.length > 0 ? false : true,
            competenciasGenerales: this.state.competenciasGenerales.length > 0 ? false : true
        };

        this.setState({
            errors: errors
        })
        console.log(count)
        console.log(countValid)
        if (count === countValid) {
            scroller.scrollTo('message')
            this.setState({ create: true })
            form["planCarrera"] = formPlanCarrera;
            form["peticion"] = formPeticion;
            console.log("form", form)
            this.props.dispatchSendPeticionAction(form);
        }
    }

    callbackARN = (boolean) => {
        this.setState({ disabledARN: boolean === 'Si' ? false : true })
    }

    getValueRRHH = (object, value, reason) => {
        this.setState({ responsable: value })
    }

    getValueDestinatario = (object, value, reason) => {
        this.setState({ destinatario: value, aprobadoresChip: [], valueAutocomplete: '' })
        this.aprobadoresId = []
        let department = {
            "department": value.department
        }
        this.props.dispatchGetAprobadoresByDepartmentAction(department);
    }
    callbackChips = (aprobadores) => {

        this.aprobadoresId = []
        let chip = []
        aprobadores.map(aprobador => {

            let include = this.props.responseAprobadoresByDepartment.filter(e => e.email === aprobador.id);
            let apro = include[0];
            apro["status"] = "0";

            this.aprobadoresId.push(apro)

            chip.push(aprobador)
        })
        let horas = chip.length * 48;
        let dias = horas / 24;
        var date = new Date();
        date.setDate(date.getDate() + dias);
        this.setState({ aprobadoresChip: chip, date: date })

    }
    handleChangeMultipleFuncionales = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        this.setState({ competenciasFuncionales: value });
    };

    handleChangeMultipleDeNivel = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        this.setState({ competenciasDenivel: value });
    };
    handleChangeMultipleGenerales = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        this.setState({ competenciasGenerales: value });
    };
    handleCloseModal = (type) => {
        console.log(type)
        this.setState({ [type]: !this.state[type] });
    }
    render() {
        console.log(this.state.competencias_funcionales)
        const ModalCompetencias = (props) => {
            console.log(props)
            let description = []
            description["competencias_generales"] = [
                "• Actitud de servicio: Significa la disposición permanente por ayudar o brindar servicio a los clientes internos y pacientes, de poder comprender y satisfacer sus necesidades, aun aquéllas no expresadas, de generar empatía.",
                "• Trabajo en equipo: Implica la capacidad de colaborar y cooperar con los demás, de formar parte de un grupo y de trabajar juntos en tareas u objetivos compartidos."
            ];
            description["competencias_de_nivel"] = [
                "• Responsabilidad: Asociada al compromiso con que las personas realizan las tareas encomendadas. Cumple con la puntualidad y el presentismo y ante situaciones de demora o ausencia se comunica con anticipación suficiente con su responsable directo permitiéndole una adecuada reorganización de las tareas.",
                "• Conciencia organizacional: Supone facilidad para la relación interpersonal y capacidad para comprender el impacto de las propias acciones en la cadena de valor, entendiendo que el resultado de la propia tarea excede el puesto y repercute en diferentes áreas y posiciones.",
                "• Adaptabilidad: Hace referencia a la capacidad de modificar la conducta personal para alcanzar determinados objetivos cuando surgen cambios o dificultades en los procedimientos habituales y conocidos. Se asocia a la versatilidad del comportamiento ante distintos contextos y personas en forma rápida y adecuada.",
                "• Credibilidad técnica: Es la capacidad necesaria para generar seguridad y confianza en los demás sobre la base de los conocimientos técnicos de su especialidad.",
                "• Conocimiento de los servicios ofrecidos: Es la capacidad de conocer a fondo los servicios y evaluar la factibilidad y viabilidad de su adaptación a los requerimientos, preferencias y necesidades del cliente. Implica también conocer la competencia y las ventajas/desventajas entre los servicios ofrecidos.",
                "• Aprendizaje continuo: Es la habilidad de capitalizar lo aprendido a partir de la educación formal, la experiencia propia y de otros. Implica el interés en mantener su curva de conocimiento en constante desarrollo.",
                "• Involucramiento con normas y procedimientos organizacionales, contables, impositivos y legales: Es el compromiso demostrado por utilizar los procedimientos de la empresa para asegurar eficiencia interna y un constante estándar de servicio al paciente y al cliente interno, velando a su vez por el cumplimiento de las normas provinciales, nacionales o internacionales pertinentes y asegurándose de estar actualizado con los cambios en la legislación de aplicación.",
                "• Liderazgo: Es la habilidad necesaria para orientar la acción de los grupos en una dirección determinada, conduciendo a la acción, fijando objetivos, prioridades y dando seguimiento. Implica dar feedback, integrando la opiniones de los otros. Tener energía y transmitirla al equipo a cargo. Motivar e inspirar confianza. Tener valor para proponer o defender creencias, ideas y asociaciones que aseguren competitividad y efectividad a largo plazo. Gestionar situaciones, personas y tiempos, con una adecuada integración al equipo de trabajo y emprendiendo acciones eficaces para mejorar el talento y las capacidades de los demás.",
                "• Calificación: Implica tener amplios conocimientos de los temas del área que esté bajo su responsabilidad, demostrando compromiso por cumplir los procedimientos de la empresa para asegurar eficiencia interna y un constante estándar de servicio al paciente o al cliente interno. Demostrar capacidad para trabajar con las posiciones de su mismo nivel y de niveles diferentes. Compartir con los demás el conocimiento profesional y expertise. Demostrar constantemente interés en aprender.",
                "• Pensamiento estratégico: Es la habilidad para comprender rápidamente los cambios del entorno, las oportunidades del mercado, las amenazas competitivas y las fortalezas y debilidades de la organización a la hora de identificar la mejor respuesta estratégica. Gestiona el área de expertise a cargo en consonancia con la estrategia de la empresa y de manera asociada a las demás áreas."
            ];
            description["competencias_funcionales"] = [
                "• Involucramiento con normas y procedimientos organizacionales: Es el compromiso demostrado por utilizar los procedimientos de la empresa para asegurar eficiencia interna y un constante estándar de servicio al paciente y al cliente interno.",
                "• Modalidades de contacto: Es la capacidad de demostrar una sólida habilidad de comunicación clara: implica saber escuchar y hacer posible que los demás comprendan la información que se está brindando. Demuestra empatía e interés por los pacientes, atención y sensibilidad frente a sus inquietudes.",
                "• Flexibilidad: Es la capacidad para adaptarse y trabajar en distintas y variadas situaciones y con personas o grupos diversos. Supone entender y valorar posturas distintas o puntos de vista encontrados, adaptando su propio enfoque a medida que la situación cambiante lo requiera y promoviendo los cambios en la propia organización o las responsabilidades a su cargo.",
                "• Habilidad analítica: Tiene que ver con el tipo y alcance de razonamiento. Es la capacidad de identificar los problemas, reconocer la información significativa, buscar y coordinar los datos relevantes. El pensamiento analítico tiene una secuencia a seguir, va de lo general a lo particular y siempre está enfocado a encontrar una respuesta.",
                "• Acuracidad: Implica la precisión y exactitud con que se realizan los reportes y controles y la oportunidad de su presentación.",
                "• Fidelidad: Se trata de la lealtad en el cumplimiento de los compromisos establecidos para la posición, fundamentalmente respetando los intereses corporativos y sin perseguir beneficio propio por los contactos, recursos económicos ó demás activos tangibles e intangibles que la empresa pone a disposición para llevar adelante la tarea asignada.",
                "• Orientación a resultados: Es la tendencia al logro de resultados, fijando metas desafiantes por encima de los estándares, mejorando y manteniendo altos niveles de rendimiento, en el marco de las estrategias de la organización.",
                "• Precisión técnica y cuidado de las herramientas de trabajo: Hace referencia al cumplimiento de los pasos preestablecidos para el mantenimiento y/o reparación que se le encomienden, respetando los tiempos de trabajo y de atención a pacientes para procurar afectar mínimamente el normal desenvolvimiento de las tareas y siendo responsable por el cuidado y la conservación de las herramientas que la empresa pone a disposición para el trabajo que debe cumplir.",
                "• Disponibilidad de respuesta: Es la capacidad para responder ante el requerimiento del cliente interno, siguiendo las prioridades que establezca el responsable del área a quien reporta y trabajando con alta performance en situaciones de exigencia.",
                "• Fidelización del capital humano: Consiste en establecer y mantener relaciones cordiales y recíprocas con el cliente interno, fomentando un clima laboral positivo. Se trata de escuchar activamente y presentar soluciones de valor al cliente interno, bajo el concepto de actuar como funcionario de la empresa representando su imagen y valores e interpretando las necesidades de los colaboradores.",
                "• Confidencialidad y prudencia: Es la actitud de reserva y discreción, tanto en el relacionamiento con los demás colaboradores de la empresa como en el trato con personas ajenas a la misma.",
                "• Orientación al cliente: Demostrar empatía por las necesidades o exigencias del cliente interno, brindando una respuesta clara y oportuna. Tiene que ver con escuchar activamente y diagnosticar correctamente la necesidad para generar en consecuencia soluciones adecuadas, entendiendo las limitaciones técnicas del usuario en el planteamiento del problema y su comunicación.",
                "• Innovación: Es la capacidad para modificar las cosas incluso partiendo de formas o situaciones no pensadas con anterioridad. Implica idear soluciones nuevas y diferentes ante problemas o situaciones requeridos por el propio puesto, la organización y los clientes internos.",
                "• Orientación al paciente/médico/financiador: Demostrar empatía por las necesidades o exigencias que el cliente o paciente pueda requerir, concediendo la más alta calidad de atención y respuesta. Tiene que ver con escuchar activamente y diagnosticar correctamente la necesidad para generar en consecuencia soluciones adecuadas, entendiendo las limitaciones en el planteamiento del problema y su comunicación. Hace referencia a la habilidad de manejar el lenguaje corporal cuando la comunicación es de modo presencial.",
                "• Resolución de conflictos: Es la capacidad de entender las diferencias de intereses y buscar una solución que genere satisfacción al paciente o a quien requiere de nuestro servicio, atendiendo la factibilidad interna y escalando el conflicto cuando la situación lo amerite.",
                "• “Marcar la diferencia”:Es demostrar esmerada actitud y comportamiento al brindar una atención que se destaque por su calidad y calidez, tanto sea a través de la comunicación verbal como no verbal.",
                "• Prudencia:Es la actitud de reserva y discreción, tanto en el relacionamiento con los demás colaboradores de la empresa como en el trato con personas ajenas a la misma.",
                "• Responsabilidad social empresaria: Es la actitud de promover acciones solidarias en las comunidades y en el medio ambiente donde opera la empresa, generando conciencia social en los colaboradores e incentivándolos a la participación en dichas actividades.",
                "• Proactividad:Hace referencia a la actitud permanente de adelantarse a los demás en su accionar. Es la predisposición a actuar de forma anticipada y no sólo de manera reactiva. Implica marcar el rumbo por medio de acciones concretas, no sólo de palabras. Los niveles de actuación van desde concretar decisiones tomadas en el pasado hasta la búsqueda de nuevas oportunidades o soluciones de problemas."
            ];

            return (
                <Fragment>
                    <Tooltip title="mas info">
                        <Button

                            onClick={() => this.handleCloseModal(props.type)}
                        >{props.title}</Button>
                    </Tooltip>
                    <Dialog  onClose={() => {this.handleCloseModal(props.type)}} data-index={this.props.type} aria-labelledby={this.props.type} open={this.state[props.type]} className={`dialog-${this.props.type}`}>
                        <DialogTitle id={this.props.type} onClose={() => {this.handleCloseModal(props.type)}}>
                            <Grid container>
                                <Grid md={11}>
                                    <Typography variant="h5">{props.title}</Typography></Grid>
                                
                                <Grid md={1}><Icon onClick={() => {this.handleCloseModal(props.type)}} color="primary">close</Icon></Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent dividers className="dialogContent_root">
                            {description[props.type].map(val => { return <Typography>{val}</Typography> })}
                        </DialogContent>
                    </Dialog>
                </Fragment>

            )
        }


        return (
            (<Theme>
                <div className={`cardView_container`} styles={configStyles}>
                    {(this.state.create && this.props.responsePeticion.id !== undefined) &&
                        <Redirect to={{
                            pathname: `/dashboard/peticiones`, state: {
                                responseCreate: 'La solicitud se guardó exitosamente',
                                error:false
                            }
                        }} />
                    }
                    <Element name="message" >
                        {/*  <Navbar /> */}
                    </Element>

                    <Card >
                        <CardHeader className="Detail_Action_ViewHeader"
                            title={<div style={{ 'display': 'inline-block' }}>
                                <img src={logo} style={{ margin: '0 auto', float: 'left', 'width': '6%', 'maxWidth': '100%' }}></img>
                                <Typography style={{ 'paddingLeft': '8%' }} component="h4" variant="h4"><strong>Solicitud para envio de formulario</strong></Typography>
                            </div>}>

                        </CardHeader>
                        <CardContent className="Detail_Action_ViewContainer">
                            <Grid container spacing={1}>

                                <Grid item xs={12} sm={6} md={4}>
                                    {Object.keys(this.props.responseRRHH).length > 0 &&
                                        <Autocomplete
                                            onChange={this.getValueRRHH}
                                            value={this.state.responsable}
                                            fullWidth
                                            freeSolo
                                            disableClearable
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            id="autocomplete"
                                            options={this.props.responseRRHH}
                                            getOptionLabel={(option) => { return option.name }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps, type: 'search', startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Icon>search</Icon>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    label={"Solicitud Iniciada por:"}
                                                    placeholder={""}
                                                    fullWidth
                                                    error={this.state.errors.responsable}
                                                />
                                            )}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    {Object.keys(this.props.responseUsuarios).length > 0 &&
                                        <Autocomplete
                                            onChange={this.getValueDestinatario}
                                            fullWidth
                                            freeSolo
                                            disableClearable
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            id="autocomplete"
                                            options={this.props.responseUsuarios}
                                            getOptionLabel={(option) => { return option.name }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps, type: 'search', startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Icon>search</Icon>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    label={"Persona Evaluada"}
                                                    placeholder={""}
                                                    fullWidth
                                                    error={this.state.errors.destinatario}
                                                />
                                            )}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}></Grid>

                                <Grid item xs={12} md={4} sm={12}>
                                    <RadioButton
                                        defaultValue={"No"}
                                        label={"¿Tiene permiso ARN?"}
                                        defaultChecked={true}
                                        callback={this.callbackARN}
                                        optionsSelect={getSiNo()}
                                        row={true}
                                        showLabel={true}
                                        paddingRight="0%"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={12}>
                                    <Typography className="main_admin formLabel">Fecha de primer permiso ARN:</Typography>
                                    <DateValid
                                        ref={element => { this.state.fechaPrimerPermiso = element }}
                                        name="fecha_primer_permiso"
                                        id="fechaPrimerPermiso"
                                        defaultValue={null}
                                        placeholder="__/__/____"
                                        error={this.state.errors.fechaPrimerPermiso}
                                        disabled={this.state.disabledARN}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={12}></Grid>
                                <Grid item xs={12} md={4} sm={12}>

                                    <StringNumber
                                        ref={element => { this.state.stepActual = element }}
                                        id="stepActual"
                                        label="Step actual del Plan de Carrera"
                                        name="stepActual"
                                        placeholder=""
                                        defaultValue=""
                                        disabled={false}
                                        error={this.state.errors.stepActual}
                                    />

                                </Grid>
                                <Grid item xs={12} md={4} sm={12} >

                                    <StringNumber
                                        ref={element => { this.state.stepAvanza = element }}
                                        id="stepAvanza"
                                        label="Step del Plan de Carrera al cual avanza"
                                        name="stepAvanza"
                                        placeholder=""
                                        defaultValue=""
                                        disabled={false}
                                        error={this.state.errors.stepAvanza}
                                    />

                                </Grid>
                                <Grid item xs={12} md={4} sm={12}></Grid>
                                
                                <Grid item xs={12} md={4} sm={12}>
                                    <ModalCompetencias title="Competencias generales (seleccionar dos)" type="competencias_generales"></ModalCompetencias>
                                    {this.state.errors.competenciasGenerales && <Typography className="size" style={{ color: 'red' }} >Debe seleccionar 2</Typography>}

                                    <Select
                                        multiple
                                        native
                                        value={this.state.competenciasGenerales}
                                        onChange={this.handleChangeMultipleGenerales}
                                        inputProps={{
                                            id: 'select-multiple-native',
                                        }}
                                    >
                                        {getCompetenciasGenerales().map((name) => (
                                            <option key={name} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                    </Select>

                                </Grid>
                                <Grid item xs={12} md={4} sm={12} >
                                    <ModalCompetencias title="Competencias de nivel (seleccionar dos)" type="competencias_de_nivel"></ModalCompetencias>

                                    {this.state.errors.competenciasDenivel && <Typography className="size" style={{ color: 'red' }} >Debe seleccionar 2</Typography>}

                                    <Select
                                        multiple
                                        native
                                        value={this.state.competenciasDenivel}
                                        onChange={this.handleChangeMultipleDeNivel}
                                        inputProps={{
                                            id: 'select-multiple-native',
                                        }}
                                    >
                                        {getCompetenciasDeNivel().map((name) => (
                                            <option key={name} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                    </Select>

                                </Grid>
                                <Grid item xs={12} md={4} sm={12} >
                                    <ModalCompetencias title="Competencias funcionales (seleccionar dos)" type="competencias_funcionales"></ModalCompetencias>
                                    {this.state.errors.competenciasFuncionales && <Typography className="size" style={{ color: 'red' }} >Debe seleccionar 2</Typography>}

                                    <Select
                                        multiple
                                        native
                                        value={this.state.competenciasFuncionales}
                                        onChange={this.handleChangeMultipleFuncionales}
                                        inputProps={{
                                            id: 'select-multiple-native',
                                        }}
                                    >
                                        {getCompetenciasFuncionales().map((name) => (
                                            <option key={name} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                    </Select>
                                </Grid>

                                <Divider style={{ height: 2, backgroundColor: "#a31f35" }} />

                                <Grid item xs={12} md={12} sm={12} >
                                    <Typography>Aprobadores (En el orden que se agregan, es el orden en que se envían los mails)</Typography>
                                    <Chips
                                        disabled={Object.keys(this.props.responseAprobadoresByDepartment).length > 0 ? false : true}
                                        size="small"
                                        view={'edit'}
                                        placeholder=""
                                        label="Aprobadores"
                                        {...this.props}
                                        chips={this.state.aprobadoresChip}
                                        options={this.props.responseAprobadoresByDepartment}
                                        callbackAdd={this.callbackChips}
                                        title=""
                                        optionLabel={"name"}
                                        valueAutocomplete={this.state.valueAutocomplete}
                                    />

                                </Grid>
                                {/* } */}
                                <Grid item xs={12} md={4} sm={12}>
                                    <Typography>Plazo Límite para completar la Solicitud:</Typography>
                                    
                                    <DateValid
                                        ref={element => { this.state.fechaExpiracion = element }}
                                        label=""
                                        name="fecha_expiracion"
                                        id="fechaExpiracion"
                                        defaultValue={this.state.date}
                                        placeholder="__/__/____"
                                        error={this.state.errors.fechaExpiracion}
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={12}></Grid>
                                <Grid item xs={12} md={4} sm={12}></Grid>
                            </Grid>
                            <br/>
                            <Button
                                onClick={() => this.send()}
                                variant="contained"
                                className="enviar"
                            >
                                Enviar
                                </Button>

                        </CardContent>
                    </Card>
                </div>

            </Theme>)
        )
    }
}
const mapStateToProps = reducers => {
    return reducers.listReducer;
};
const mapDispatchToProps = dispatch => {
    return {
        dispatchSendPeticionAction: (request) =>
            dispatch(
                sendPeticionAction(request),
            ),
        dispatchGetRRHHAction: () =>
            dispatch(
                getRRHHAction(),
            ),
        dispatchGetUsuariosAction: () =>
            dispatch(
                getUsuariosAction(),
            ),
        dispatchGetAprobadoresByDepartmentAction: (request) =>
            dispatch(
                getAprobadoresByDepartmentAction(request),
            ),


    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PeticionPlanCarrera);


