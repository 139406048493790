import React, { Component, Fragment } from 'react'
import { sendAprobadorAction } from '../actions/FormAction'
import { getPeticionAction } from '../actions/PeticionAction'
import { connect } from "react-redux";
import { Dialog, DialogContent, Divider, Grid, Card, CardHeader, CardContent, Typography, Button } from '@material-ui/core';
import FirmaDestinatarioForm from './partials/FirmaDestinatarioForm';
import FirmasAprobadoresForm from './partials/FirmasAprobadoresForm';
import TextArea from './inputs/TextArea';
import Theme from '../assets/Theme';
import configStyles from '../config/style.json';
import '../assets/Form.css';
import logo from '../assets/atomo.png';
import { Redirect } from "react-router-dom";
class Aprobador extends Component {

    constructor(props) {
        super(props);
        this.state = {
            motivo: '',
            openAlert: true,
            errors: {
                motivo: false
            }
        }
    }
    collapseAlert = () => {
        this.setState({ openAlert: false })
    }
    UNSAFE_componentWillMount = () => {
        const id = this.props.match.params.id;

        this.props.dispatchGetPeticionAction(id);

    }

    aprueba = () => {
        const hash = this.props.match.params.hash;
        const idAprobador = this.props.match.params.idAprobador;
        let request = {
            "aprobador": idAprobador,
            "hash": hash,
            "status": 1
        }
        this.props.dispatchSendAprobadorAction(request);
    }

    desaprueba = () => {
        const hash = this.props.match.params.hash;
        const idAprobador = this.props.match.params.idAprobador;
        let request = {
            "aprobador": idAprobador,
            "hash": hash,
            "status": 0
        }
        if (this.state.motivo.state.defaultValue !== "") {
            request["motivo_rechazo"] = this.state.motivo.state.defaultValue
        }

        this.setState({
            errors: {
                motivo: (this.state.motivo && this.state.motivo.state.defaultValue !== '' && this.state.motivo.state.errorText === '') ? false : true,
            }
        })

        console.log(request)
        if (this.state.motivo.state.defaultValue !== "") {
            this.props.dispatchSendAprobadorAction(request);
        }

    }
    loadingScreen = () => {
        return (
            <Dialog open={true} align="center">
                <DialogContent>
                    <div>  </div>
                    <div> <Typography variant="h5">Procesando datos </Typography>  </div>
                </DialogContent>
            </Dialog>
        )
    }
    render() {

        let aprobador = ""
        const idApro = this.props.match.params.idAprobador;

        if (Object.keys(this.props.peticionData).length > 0) {
            console.log(idApro)
            let datosAprobador = this.props.peticionData.aprobadores.filter(e => e.id == idApro)
            console.log(datosAprobador)
            aprobador = datosAprobador[0].name
        }
        let today = new Date().toLocaleString();

        today = today.split(",");
        today = today[0].split("/");

        console.log(today)
        let date = today[2] + '-' + today[0] + '-' + today[1];

        return (

            Object.keys(this.props.responseAprobador).length > 0 ?
                (<Redirect to={{
                    pathname: `/dashboard/`, state: {
                        responseForm: this.props.responseAprobador.info,
                        error: this.props.responseAprobador.error
                    }
                }} />
                )
                :
                (Object.keys(this.props.peticionData).length > 0 ?
                    (<Theme>
                        <div className={`cardView_container`} styles={configStyles}>

                            <Grid container spacing={1}>

                                <Grid item sm={3}></Grid>
                                <Grid item sm={6}>
                                    <Card>
                                    <CardHeader className="Detail_Action_ViewHeader"
                                         title={<div style={{ 'display': 'inline-block' }}>

                                         <img src={logo} style={{ margin: '0 auto', float: 'left', 'width': '6%', 'maxWidth': '100%' }}></img>
                                         <Typography style={{ 'paddingLeft': '8%' }} component="h4" variant="h4">Solicitud de Licencia del empleado/a:  {this.props.peticionData.destinatario[0].name}</Typography>

                                     </div>}
                                          
                                            subheader={<Typography style={{ 'paddingLeft': '8%' }} component="h4" variant="h4">{(this.props.peticionData.licencia.fecha_inicio !== "") ? 'Licencia por Vacaciones' : 'Licencias Especiales'}</Typography>}
                                            
                                            >
                                        </CardHeader>
                                        <CardHeader className="Detail_Action_ViewHeader"

                                            title={<Typography component="h4" variant="h4">Solicitud de Licencia del empleado/a:  {this.props.peticionData.destinatario[0].name}</Typography>}>

                                        </CardHeader>
                                        <CardContent className="Detail_Action_ViewContainer">
                                        <Grid container spacing={1}>
                                                <Grid item sm={3}>
                                                    <Typography className="size"><strong>Fecha de inicio:</strong> </Typography>
                                                </Grid>
                                                <Grid item sm={3} >
                                                    <Typography className="size">{this.props.peticionData.licencia.fecha_inicio}</Typography>
                                                </Grid>
                                                <Grid item sm={3}>
                                                    <Typography className="size"><strong>Fecha de reincorporación:</strong></Typography>

                                                </Grid>
                                                <Grid item sm={3} >
                                                    <Typography className="size">{this.props.peticionData.licencia.fecha_reincorporacion}</Typography>
                                                </Grid>
                                            </Grid>
                                            {this.props.peticionData.licencia.fecha_inicio !== "" &&
                                                (<Fragment>
                                                    <Grid container spacing={1}>
                                                        <Grid item sm={3}>
                                                            <Typography className="size"><strong>Días que se toma: </strong></Typography>
                                                        </Grid>
                                                        <Grid item sm={3} >
                                                            <Typography className="size">Completa RRHH</Typography>
                                                        </Grid>
                                                        <Grid item sm={3}>
                                                            <Typography className="size"><strong>Días pendientes: </strong></Typography>
                                                        </Grid>
                                                        <Grid item sm={3} >
                                                            <Typography className="size">Completa RRHH</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Fragment>)

                                            }
                                           
                                            {this.props.peticionData.licencia.motivo_licencia !== "" &&
                                                (
                                                    <Grid container spacing={1}>
                                                    <Grid item sm={3} >
                                                    <Typography className="size"><strong>Motivo: </strong></Typography>
                                                    </Grid>
                                                    <Grid item sm={3} >
                                                    <Typography className="size"> {this.props.peticionData.licencia.motivo_licencia}</Typography>
                                                    </Grid>
                                                    <Grid item sm={3}>
                                                    <Typography className="size"><strong>Descripción:</strong> </Typography>
    
                                                    </Grid>
                                                    <Grid item sm={3} >
                                                    <Typography className="size"> {(this.props.peticionData.licencia.descripcion_lic_especiales === "") ? 'No aplica' : this.props.peticionData.licencia.descripcion_lic_especiales}</Typography>
                                                    </Grid>
                                                </Grid>
                                                )
                                            }
                                             <Grid container spacing={1}>
                                                    <Grid item sm={3} >
                                                    <Typography className="size"><strong>Compensación de horas: </strong></Typography>
                                                    </Grid>
                                                    <Grid item sm={3} >
                                                    <Typography className="size">{this.props.peticionData.licencia.compensa_horas ? 'Si' : 'No'}</Typography>
                                                    </Grid>
                                                    <Grid item sm={3}>
                                                    <Typography className="size"><strong>Días y horarios de compensación:</strong> </Typography>
    
                                                    </Grid>
                                                    <Grid item sm={3} >
                                                    <Typography className="size"> {(this.props.peticionData.licencia.descripcion_lic_especiales === "") ? 'No aplica' : this.props.peticionData.licencia.descripcion_lic_especiales}</Typography>
                                                    </Grid>
                                                </Grid>
                                           

                                            
                                    <Divider style={{ height: 2, backgroundColor: "#a31f35" }} />
                                    <Grid container >
                                                <Grid item xs={3} >
                                                    <Typography className="size"><strong>Especifique el motivo en el caso de rechazar la solicitud </strong></Typography>
                                                 </Grid>
                                                 <Grid item xs={9} >
                                                 <TextArea
                                                        ref={element => { this.state.motivo = element }}
                                                        id="motivo"
                                                        name="motivo"
                                                        label=""
                                                        error={this.state.errors.motivo}
                                                        defaultValue=""
                                                    />
                                                </Grid>
                                                
                                            </Grid>
                                           
                                            <Divider style={{ height: 2, backgroundColor: "#a31f35" }} />
                                            <br />
                                            {(Object.keys(this.props.peticionData).length > 0) &&
                                                <Fragment>
                                                    <FirmaDestinatarioForm peticion={this.props.peticionData} />
                                                    <FirmasAprobadoresForm peticion={this.props.peticionData} />
                                                </Fragment>
                                            }

                                            <Button style={{ margin: '16px' }} onClick={this.aprueba} variant="contained" className="enviar" >
                                                Aprobar
                                            </Button>

                                            <Button onClick={this.desaprueba} variant="contained" className="enviar" >
                                                Rechazar
                                            </Button>
                                        </CardContent>

                                    </Card>
                                </Grid>
                                <Grid item sm={3}></Grid>

                            </Grid>
                        </div>
                    </Theme>)
                    : (this.loadingScreen())
                )


        )
    }
}

const mapStateToProps = reducers => {
    return reducers.listReducer;
};
const mapDispatchToProps = dispatch => {
    return {
        dispatchSendAprobadorAction: (form) =>
            dispatch(
                sendAprobadorAction(form),
            ),
        dispatchGetPeticionAction: (id) =>
            dispatch(
                getPeticionAction(id),
            ),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Aprobador);


