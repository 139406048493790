
/*
 * Verifica si un valor se encuentra dentro de un array de permisos.
 * Se recorre en esta funcion ya que el permissionItems no es un json clave:valor, 
 * si no un array de objetos que dentro tienen las clave:valor que buscamos.
 *  @param   {array}   permissionsItems      Array de objetos con los items que tiene habilitados ese usuario.
 *  @param   {array}   value                 Valor a buscar en el array
 *  @return  {boolean}   
 */
export function isPermissionEnabled(value,permissionsItems ) {               // Ejemplo:  
   
    if (value.indexOf('/') !== -1) {                                       // value = user/view
        let permissionStr = value.split('/');
        let permissionFather = permissionStr[0];                        // user
        let permissionAction = permissionStr[1];                        // view
        //verifico que tenga el permisoPadre                            
        var result = permissionsItems.filter(function (permission) {
            return Object.keys(permission)[0] == permissionFather;
        });
        if (result.length > 0) { //Si tiene el permiso padre 
            if (result[0][permissionFather].indexOf(permissionAction) !== -1) {  // verifico que la accion (add, view, etc.) esté dentro del permiso padre.
                return true
            } else {
                return false
            }
        } else { // Si NO tiene el permiso padre, tampoco tendrá la accion.
            return false
        }
    } else {
        var result = permissionsItems.filter(function (permission) {
            return permission === value;

        });
    
        if (result.length > 0) {

            return true
        } else {
            return false
        }
    }
}

/*
* Recorre config.json recursivamente y devuelve los items que puede ver el usuario segun sus permisos.
* Los items del config.json que no tengan el atributo permissionName ni submenu, iran a la interfaz directamente
* ya que no dependen de ningun permiso para realizar su funcionalidad. Ejemplo: item Dashboard.
*  @param   {array}   configItems         array de objetos con todos los items y submenus posibles.
*  @param   {array}   permissionsItems    array de objetos con los items que tiene habilitado ese usuario.
*  @return  {array}   
*/
export function enabledPermissions(configItems, permissionsItems) {
    let enabledItems = []; 
    configItems.forEach( item => {
        if (!item.permissionName){ // Si NO tiene permissionName
            if (!item.submenu) { // Si NO tiene submenu
                enabledItems.push(item);                    
            } else{ // Si tiene submenu
                let submenu = enabledPermissions(item.submenu, permissionsItems)
                if (submenu.length>0){ //Lo agrego SOLO si algun item del submenu tiene los permisos.
                  enabledItems.push(item);
                  enabledItems[enabledItems.length - 1].submenu = submenu
                }
          }
        }else{ // si tiene permissionName 
            if (isPermissionEnabled(permissionsItems, item.permissionName)) { // verifico si el usuairo posee ese permiso
                enabledItems.push(item);                                      
                if (item.submenu) { // si tiene submenu
                    enabledItems[enabledItems.length - 1].submenu = enabledPermissions(item.submenu, permissionsItems) 
                }
            }
        }
    })
    return enabledItems
}

