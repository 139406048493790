import axios from "axios";
import config from "../config/config.json"

export const getListPeticion = async (query) => {
    //const url = config.credentials.url+'peticion/';
    let userData = JSON.parse(window.localStorage.getItem('userData'));
    let query1 = ''
    console.log(query !== null && userData.department !== 'Recursos Humanos')
    if(query !== null && userData.department !== 'Recursos Humanos'){
         query1= query.split("&");
        
         query1[3] = 'tipo_peticion=1';
         query1[6] = 'destinatario='+userData.email
        
         console.log(query1)
         query = query1.join("&");
    }
    const url = userData.department === 'Recursos Humanos' ? (query === null ? config.credentials.url+'peticion/' : config.credentials.url+'peticion/'+query) : (query === null ? config.credentials.url+'peticion/'+'?destinatario='+userData.email+'&tipo_peticion=1'  : config.credentials.url+'peticion/'+query);
    
    const response = await axios.get(url)
    
    .then(res => {
        console.log(res);
        return res.data;
    })
    .catch((err) => {
        //console.log(err);
        return false;
    }); 

    return response;
}

export const getExport = async (query) => {
    //const url = config.credentials.url+'peticion/';
    let userData = JSON.parse(window.localStorage.getItem('userData'));
    let query1 = ''
    console.log(query !== null && userData.department !== 'Recursos Humanos')
    if(query !== null && userData.department !== 'Recursos Humanos'){
         query1= query.split("&");
        
         query1[3] = 'tipo_peticion=1';
         query1[6] = 'destinatario='+userData.email
        
         console.log(query1)
         query = query1.join("&");
    }
    const url = userData.department === 'Recursos Humanos' ? (query === null ? config.credentials.url+'export/' : config.credentials.url+'export/'+query) : (query === null ? config.credentials.url+'export/'+'?destinatario='+userData.email+'&tipo_peticion=1'  : config.credentials.url+'export/'+query);
    
    const response = await axios.get(url)
    
    .then(res => {
        console.log(res);
        return res.data;
    })
    .catch((err) => {
        //console.log(err);
        return false;
    }); 

    return response;
}

export const getTipoPeticion = async () => {
   
    const url = config.credentials.url+'tipopeticion/';
    const response = await axios.get(url)
   
    .then(res => {
        console.log(res);
        return res.data;
    })
    .catch((err) => {
        //console.log(err);
        return false;
    }); 

    return response;
}

export const getStatus = async () => {
   
    const url = config.credentials.url+'estado/';
    const response = await axios.get(url)
   
    .then(res => {
        console.log(res);
        return res.data;
    })
    .catch((err) => {
        //console.log(err);
        return false;
    }); 

    return response;
}

export const sendPeticion = async (request) => {
    const url = config.credentials.url+'peticion/';
   
    const headers = {
        'Content-Type': "application/json"
      };
    const response = await axios.post(url,JSON.stringify(request),{headers:headers})
   
    .then(res => {
        console.log(res);
        return res.data;
    })
    .catch((err) => {
        //console.log(err);
        return false;
    }); 

    return response;
}

export const getPeticion = async (id) => {
   
    const url = config.credentials.url+'peticion/'+id+"/";
    const response = await axios.get(url)
   
    .then(res => {
        console.log(res);
        return res.data;
    })
    .catch((err) => {
        //console.log(err);
        return false;
    }); 

    return response;
}

export const getRRHH = async () => {
   
    const url = config.credentials.url+'getManagersRRHH/';
    console.log(config.credentials.url+'getManagersRRHH/')
    const response = await axios.get(url)
    
    .then(res => {
        console.log("RESPUESTA RRHH",res);
        return res.data;
    })
    .catch((err) => {
        //console.log(err);
        return false;
    }); 

    return response;
}

export const getUsuarios = async () => {
   
    const url = config.credentials.url+'getUsers/';
    const response = await axios.get(url)
    
    .then(res => {
        console.log(res);
        return res.data;
    })
    .catch((err) => {
        //console.log(err);
        return false;
    }); 

    return response;
}

export const getAprobadores = async (request) => {
   
    const url = config.credentials.url+'getManagersByUser/';
    const response = await axios.post(url, request)
    
    .then(res => {
        console.log(res);
        return res.data;
    })
    .catch((err) => {
        //console.log(err);
        return false;
    }); 

    return response;
}

export const getAprobadoresByDepartment = async (request) => {
   
    const url = config.credentials.url+'getUsersByDepartment/';
    const response = await axios.post(url, request)
    
    .then(res => {
        console.log(res);
        return res.data;
    })
    .catch((err) => {
        //console.log(err);
        return false;
    }); 

    return response;
}

