import React, { Component, Fragment } from 'react';
import { Grid, Paper, Chip, TextField, InputAdornment, Typography, Button, Icon } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";

export default class Chips extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chipData: props.chips,
            id: '',
            autocompleteActive: false,
            autoCompleteValue: '',
            autoCompleteId: '',
            autocompleteObject: {},
            valueAutocomplete: this.props.valueAutocomplete
        }
    }

    handleAdd = (chipToAdd, i) => () => {
        let res = [];
        this.state.chipData.map(val => {
            if (val.key === chipToAdd.key) {
                val.selected = true
            }
            res.push(val)
        })

        this.setState({ chipData: res })
    };

    handleDelete = (chipId) => () => {
        console.log("handleDelete", chipId)
        let res = [];
        console.log(this.state.chipData)
       
        this.state.chipData.map(val => {
            if (val.id !== chipId)
                res.push(val)
        })
        console.log(res)
        this.setState({ chipData: res })
        this.props.callbackAdd(res)

    }

    applyAutocomplete = () => {

        let include = this.state.chipData.filter(e => e.label === this.state.autoCompleteValue);

        if (Object.keys(include).length === 0 && this.state.autoCompleteValue !== "") {
            let res = [];
            this.state.chipData.map(val => {
                res.push(val)
            });

            res.push({ 'id': this.state.autoCompleteId, "label": this.state.autoCompleteValue, 'selected': false })
            this.props.callbackAdd(res)
        }
    }
    UNSAFE_componentWillReceiveProps(next_props) {

        this.setState({ chipData: next_props.chips, valueAutocomplete: next_props.valueAutocomplete })
    }

    getValue = (object, value, reason) => {

        this.setState({
            autoCompleteValue: value[this.props.optionLabel],
            autoCompleteId: value.email
        })
    }
    render() {

        const { title, view, options, label, placeholder, optionLabel } = this.props

        return (

            <div className="filter_tours_chips" >
                <Grid container >
                    <Grid item xs={8} sm={8} md={8} >

                        <Autocomplete
                            inputValue={this.state.valueAutocomplete}
                            disabled={this.props.disabled}
                            onInputChange={(event, newInputValue) => {
                                this.setState({ valueAutocomplete: newInputValue });
                            }}
                            onChange={this.getValue}
                            fullWidth
                            freeSolo
                            disableClearable
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="autocomplete"
                            options={options}
                            getOptionLabel={(option) => { return option[optionLabel] }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps, type: 'search', startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>search</Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                    label={label}
                                    placeholder={placeholder}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} style={{'paddingTop': '17px'}}>
                        <Button
                            disabled={this.props.disabled}

                            onClick={this.applyAutocomplete}
                            variant="contained"
                            color="primary"
                            className="ml-05">Aplicar</Button>
                    </Grid>



                    <Grid item xs={12} sm={12} md={12} >
                        {this.state.chipData.map((data, i) => {
                            return (
                                <Fragment>

                                    <Typography style={{'fontSize':'12px','display':'inline-block'}}>Aprobador {i + 1}:</Typography><Chip key={data.id}
                                        size="small"
                                        disabled={view === 'show' ? true : false}
                                        label={data.label}
                                        className={`chip ${data.selected ? 'selected' : ''}`}
                                        onDelete={this.handleDelete(data.id)}
                                    /><br />
                                </Fragment>
                            );
                        })}
                    </Grid>
                </Grid>

            </div>
        );
    }
}