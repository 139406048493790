import { getExport, getStatus, getListPeticion, getTipoPeticion, sendPeticion, getPeticion, getRRHH, getUsuarios, getAprobadores, getAprobadoresByDepartment } from "../services/PeticionService";
import { sendPlanCarrera } from "../services/ListService";


export const getListPeticionAction = async (query) => {

    let response = await getListPeticion(query);
    console.log(response);
    return {
        type: "GET_LIST",
        payload: response
    }
}

export const getTipoPeticionAction = async () => {

    let response = await getTipoPeticion();
    console.log(response);
    return {
        type: "GET_TIPO_PETICION",
        payload: response
    }
}

export const getStatusAction = async () => {

    let response = await getStatus();
    console.log(response);
    return {
        type: "GET_STATUS",
        payload: response
    }
}

export const exportAction = async (query) => {

    let response = await getExport(query);
    console.log(response);
    return {
        type: "GET_EXPORT",
        payload: response
    }
}

export const sendPeticionAction = async (request) => {

    let responsePeticion = await sendPeticion(request["peticion"]);
    console.log(responsePeticion);
 
    if (responsePeticion.hash !== undefined) {
        let requestPlanCarrea = request["planCarrera"];
        requestPlanCarrea["hash"] = responsePeticion.hash;
        console.log("requestPlanCarrea", requestPlanCarrea)
        let response = await sendPlanCarrera(requestPlanCarrea);
        console.log("response planCarrera", response);
    }
    return {
        type: "SEND_PETICION",
        payload: responsePeticion
    }
}

export const getPeticionAction = async (id) => {

    let response = await getPeticion(id);
    console.log(response);
    return {
        type: "GET_PETICION",
        payload: response
    }
}

export const getRRHHAction = async () => {

    let response = await getRRHH();

    return {
        type: "GET_RRHH",
        payload: response
    }
}

export const getUsuariosAction = async () => {
    let response = await getUsuarios();
    console.log(response);
    return {
        type: "GET_USUARIOS",
        payload: response
    }
}

export const getAprobadoresAction = async (request) => {
    let response = await getAprobadores(request);
    console.log(response);
    return {
        type: "GET_APROBADORES",
        payload: response
    }
}

export const getAprobadoresByDepartmentAction = async (request) => {
    let response = await getAprobadoresByDepartment(request);
    console.log(response);
    return {
        type: "GET_APROBADORES_DEPARTMENT",
        payload: response
    }
}


