import React, { Component, Fragment } from 'react'
import { sendDirectorPlanCarreraAction } from '../actions/FormAction'
import { getPeticionAction } from '../actions/PeticionAction'
import { connect } from "react-redux";
import { Divider, Dialog, DialogContent,  Grid, Card, CardHeader, CardContent, Typography, Button } from '@material-ui/core';
import FirmaRRHHForm from './partials/FirmaRRHHForm';
import FirmasAprobadoresForm from './partials/FirmasAprobadoresForm';
import Theme from '../assets/Theme';
import configStyles from '../config/style.json';
import { Redirect } from "react-router-dom";
class AprobadorDirectorPlanCarrera extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openAlert: true
        }
    }
    collapseAlert = () => {
        this.setState({ openAlert: false })
    }
    UNSAFE_componentWillMount = () => {
        const id = this.props.match.params.id;
        this.props.dispatchGetPeticionAction(id);
    }

    aprueba = () => {
        const hash = this.props.match.params.hash;
        const idAprobador = this.props.match.params.idAprobador;
        let request = {
            "aprobador": idAprobador,
            "hash": hash,
            "status": 1
        }
        this.props.dispatchSendDirectorPlanCarreraAction(request);
    }

    desaprueba = () => {
        const hash = this.props.match.params.hash;
        const idAprobador = this.props.match.params.idAprobador;
        let request = {
            "aprobador": idAprobador,
            "hash": hash,
            "status": 0
        }

        this.props.dispatchSendDirectorPlanCarreraAction(request);
    }
    loadingScreen = () => {
        return (
            <Dialog open={true} align="center">
                <DialogContent>
                    <div>  </div>
                    <div> <Typography variant="h5">Procesando datos </Typography>  </div>
                </DialogContent>
            </Dialog>
        )
    }
    render() {


        return (

            Object.keys(this.props.responseDirectorPlanCarrera).length > 0 ?
                (<Redirect to={{
                    pathname: `/dashboard/`, state: {
                        responseForm: this.props.responseDirectorPlanCarrera.info,
                        error: this.props.responseDirectorPlanCarrera.error
                    }
                }} />
                )
                :
                (Object.keys(this.props.peticionData).length > 0 ?
                    (<Theme>
                        <div className={`cardView_container`} styles={configStyles}>
                            <Grid container spacing={1}>

                                <Grid item sm={3}></Grid>
                                <Grid item sm={6}>
                                    <Card>
                                        <CardHeader className="Detail_Action_ViewHeader"
                                            title={
                                                <Fragment>
                                                    <Typography component="h4" variant="h4">Solicitud de Plan de carrera del empleado/a:  {this.props.peticionData.destinatario[0].name} {this.props.peticionData.destinatario[0].surname}</Typography>
                                                </Fragment>
                                            } />

                                        <CardContent className="Detail_Action_ViewContainer">

                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>Avance en plan de carrera: </strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.avance}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Typography component="h5" variant="h5">Step actual </Typography>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>Puesto:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography> {this.props.peticionData.carrera.step_actual.puesto}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>Ingreso: </strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.step_actual.ingreso}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>Nombre:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.step_actual.nombre}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>

                                            <Divider style={{ height: 2, backgroundColor: "#a31f35" }} />

                                            <Typography component="h5" variant="h5">Calificación de cumplimiento con respecto al perfil </Typography>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>{Object.keys(this.props.peticionData.carrera.cumplimiento)[0]}:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.cumplimiento[Object.keys(this.props.peticionData.carrera.cumplimiento)[0]]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}></Grid>
                                                <Grid item sm={5}>
                                                    <Typography ><strong>{Object.keys(this.props.peticionData.carrera.cumplimiento)[1]}:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.cumplimiento[Object.keys(this.props.peticionData.carrera.cumplimiento)[1]]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}></Grid>
                                                <Grid item sm={5}>
                                                    <Typography ><strong>{Object.keys(this.props.peticionData.carrera.cumplimiento)[2]}:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.cumplimiento[Object.keys(this.props.peticionData.carrera.cumplimiento)[2]]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}></Grid>
                                                <Grid item sm={5}>
                                                    <Typography ><strong>{Object.keys(this.props.peticionData.carrera.cumplimiento)[3]}:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.cumplimiento[Object.keys(this.props.peticionData.carrera.cumplimiento)[3]]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}></Grid>
                                                <Grid item sm={5}>
                                                    <Typography ><strong>{Object.keys(this.props.peticionData.carrera.cumplimiento)[4]}:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.cumplimiento[Object.keys(this.props.peticionData.carrera.cumplimiento)[4]]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}></Grid>
                                                <Grid item sm={5}>
                                                    <Typography ><strong>{Object.keys(this.props.peticionData.carrera.cumplimiento)[5]}:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.cumplimiento[Object.keys(this.props.peticionData.carrera.cumplimiento)[5]]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}></Grid>
                                            </Grid>
                                            <Divider style={{ height: 2, backgroundColor: "#a31f35" }} />
                                            <Typography component="h5" variant="h5">Resultado de la solicitud </Typography>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>Supera el standard:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.resultado_standard.supera} %</Typography>
                                                </Grid>
                                                <Grid item sm={2}></Grid>
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item sm={5}>
                                                    <Typography ><strong>Dentro del standard:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.resultado_standard.dentro} %</Typography>
                                                </Grid>
                                                <Grid item sm={2}></Grid>
                                            </Grid>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>Debajo del standard: </strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.resultado_standard.debajo} %</Typography>
                                                </Grid>
                                                <Grid item sm={2}></Grid>
                                            </Grid>
                                            <Divider style={{ height: 2, backgroundColor: "#a31f35" }} />
                                            <Grid container spacing={1}>
                                                <Grid item sm={5}>
                                                    <Typography ><strong>Fecha de primer permiso ARN: </strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.fecha_primer_permiso === null ? 'No aplica' : this.props.peticionData.carrera.fecha_primer_permiso}</Typography>
                                                </Grid>
                                                <Grid item sm={2}></Grid>
                                            </Grid>
                                            <Divider style={{ height: 2, backgroundColor: "#a31f35" }} />
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography component="h5" variant="h5" >Step del Plan de Carrera al cual avanzaStep del Plan de Carrera al cual avanza:</Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.step_avanza.nombre}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>Categoría: </strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.step_avanza.categoria}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>Sueldo básico: </strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.step_avanza.sueldo_basico}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>Adicional Vol.: </strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.step_avanza.adicional_vol}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>Otras condiciones: </strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.step_avanza.otras_condiciones}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            {/*  <Divider style={{ height: 2, backgroundColor: "#a31f35" }} />

                                            <Typography component="h5" variant="h5">Definición de Competencias para la evaluación </Typography>
                                            <Typography component="h5" variant="h5">.Genéricas </Typography>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>1)</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.competencia.generica[0]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>2)</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.competencia.generica[1]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>

                                            <Typography component="h5" variant="h5">.De nivel </Typography>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>1)</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.competencia.de_nivel[0]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>2)</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.competencia.de_nivel[1]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Typography component="h5" variant="h5">.Funcionales </Typography>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>1)</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.competencia.funcional[0]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>2)</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.competencia.funcional[1]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>

                                            <br />
                                            
                                            <br /> */}
                                            <Divider style={{ height: 2, backgroundColor: "#a31f35" }} />
                                            {(Object.keys(this.props.peticionData).length > 0) &&
                                                <Fragment>
                                                   
                                                   <FirmasAprobadoresForm peticion={this.props.peticionData} />
                                                    <FirmaRRHHForm peticion={this.props.peticionData} />
                                                </Fragment>
                                            }

                                            <Button onClick={this.aprueba} variant="contained" className="enviar" >
                                                Aprobar
                            </Button>
                                            <Button onClick={this.desaprueba} variant="contained" className="enviar" >
                                                Rechazar
                            </Button>
                                        </CardContent>
                                    </Card>
                                    </Grid>
                            <Grid item sm={3}></Grid>
                            </Grid>
                        </div>
                    </Theme>)
                    :
                    (this.loadingScreen())
                )
        )
    }
}

const mapStateToProps = reducers => {
    return reducers.listReducer;
};
const mapDispatchToProps = dispatch => {
    return {
        dispatchSendDirectorPlanCarreraAction: (form) =>
            dispatch(
                sendDirectorPlanCarreraAction(form),
            ),
        dispatchGetPeticionAction: (id) =>
            dispatch(
                getPeticionAction(id),
            ),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AprobadorDirectorPlanCarrera);


