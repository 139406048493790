
import React, { Fragment } from 'react'
import { Grid, Typography } from '@material-ui/core';
import '../../assets/Form.css';

export default function FirmaDestinatarioForm(props) {

    return (
        <Fragment>
        <Grid container spacing={2}>
            <Grid item xs={6} >
                <Typography className="size">FIRMA DEL EMPLEADO: {props.peticion.destinatario[0].name}</Typography>
            </Grid>
            <Grid item xs={6} > <Typography className="size">ACLARACIÓN:{props.peticion.destinatario[0].fecha_conformidad !== null ? props.peticion.destinatario[0].fecha_conformidad : new Date().toLocaleString()}</Typography></Grid>
        </Grid>
       
        </Fragment>
    )
}