import React, { Component, Suspense, Fragment } from 'react';
import config from '../../../config/config.json';
import ContentLoader from "react-content-loader";
import { IconButton, Collapse, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

class DashboardCardExpanded extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: true,
			card: []
		}
	}

	UNSAFE_componentWillMount = async () => {
		//Come from routing dinamic
		let card = this.props.match.params.card;
		console.log(card)
		let cardFilter = config.cards.filter(e => e.routing === card);
		this.setState({ card: cardFilter[0] });
	}

	dashboardCardType = () => {
	
		let Component = React.lazy(() => import(`./sections/DashboardCard${this.state.card.config.type}`));
		return <Suspense fallback={<div className="preload"><div className="responsive">
			<ContentLoader speed={2} backgroundColor="#e8e8e8" foregroundColor="#f0f0f0" width={385} height={200} viewBox="0 0 385 200"><rect x="0" y="0" rx="10" ry="10" width="385" height="200" /></ContentLoader>
		</div></div>}>
			<Component data={this.state.card} className={this.state.card.config.type} />
		</Suspense>
	}

	collapseAlert = () => {
		this.setState({ open: !this.state.open })
	}

	render() {

		return (
			<Fragment>

				<div className={`detail_dashboard dashboard_card_expanded`}>
					<div className="content_detail_dashboard" >
						{this.props.location.state !== undefined && this.props.location.state.responseDelete !== undefined && (
							<Collapse in={this.state.open}>
								{/* <Container maxWidth='xl' className="_container pb-0" > */}
									<Grid container spacing={1}>
										<Grid item sm={12}>
											<Alert severity="warning"
												action={
													<IconButton
														aria-label="close"
														color="inherit"
														size="small"
														onClick={this.collapseAlert}
													>
														<CloseIcon fontSize="inherit" />
													</IconButton>
												}
											>
												{this.props.location.state.responseDelete}
											</Alert>
										<br/>
										</Grid>
									</Grid>
								{/* </Container> */}
							</Collapse>
						)}
						{this.props.location.state !== undefined && this.props.location.state.responseCreate !== undefined && (
							<Collapse in={this.state.open}>
								{/* <Container maxWidth='xl' className="container pb-0"> */}
									<Grid container spacing={1}>
										<Grid item sm={12}>
											<Alert severity={`${this.props.location.state.responseCreate.detail !== undefined ? 'error' : 'success'}`}
												action={
													<IconButton
														aria-label="close"
														color="inherit"
														size="small"
														onClick={this.collapseAlert}
													>
														<CloseIcon fontSize="inherit" />
													</IconButton>
												}
											>
												{(this.props.location.state.responseCreate.detail !== undefined) ? 'Se produjo un error. Vuelva a intentarlo' : 'Se creó correctamente.' }
											</Alert>
										<br/>
										</Grid>
									</Grid>
								{/* </Container> */}
							</Collapse>
						)}

						{this.props.location.state !== undefined && this.props.location.state.responseEdit !== undefined && (
							<Collapse in={this.state.open}>
								{/* <Container maxWidth='xl' className="container pb-0"> */}
									<Grid container spacing={1}>
										<Grid item sm={12}>
											<Alert severity={`${this.props.location.state.responseEdit.detail !== undefined ? 'error' : 'success'}`}
												action={
													<IconButton
														aria-label="close"
														color="inherit"
														size="small"
														onClick={this.collapseAlert}
													>
														<CloseIcon fontSize="inherit" />
													</IconButton>
												}
											>
											{(this.props.location.state.responseEdit.detail !== undefined) ? 'Se produjo un error. Vuelva a intentarlo' : 'Se editó correctamente.' }
											</Alert>
										<br/>
										</Grid>
									</Grid>
								{/* </Container> */}
							</Collapse>
						)}
						{this.dashboardCardType()}
					</div>
				</div>
			</Fragment>
		)
	}
}

export default DashboardCardExpanded
