import {destinatarioPlanCarrera,actualizaPlanCarrera, sendDirectorPlanCarrera,sendLicencia, sendAprobador,sendAprobadorRRHH,sendPlanCarrera,sendAprobadorPlanCarrera,getDirectores,sendRRHHPlanCarrera} from "../services/ListService";
import {sendPeticion} from "../services/PeticionService";

export const sendLicenciaAction = async (request) => {
    let responsePeticion = await sendPeticion(request["peticion"]);
    console.log("RESPONSE PEITICON",responsePeticion);
    if(responsePeticion.id !== undefined){
        let requestLicencia = request["licencia"];
        requestLicencia["id"] = responsePeticion.id;
        requestLicencia["peticion_hash"] = responsePeticion.hash;
        console.log("requestLicencia",requestLicencia)
        let response = await sendLicencia(requestLicencia);
        console.log("respobnse liciencia",response);
        return {
            type: "SEND_LICENCIA",
            payload: response
        }
    }
   
}

export const sendAprobadorAction = async (request) => {
    
    let response = await sendAprobador(request);
    console.log(response);
    return {
        type: "SEND_APROBADOR",
        payload: response
    }
}

export const sendAprobadorRRHHAction = async (request) => {
    
    let response = await sendAprobadorRRHH(request);
    console.log(response);
    return {
        type: "SEND_APROBADOR_RRHH",
        payload: response
    }
}

export const sendPlanCarreraAction = async (request) => {
    
    let response = await sendPlanCarrera(request);
    console.log(response);
    return {
        type: "SEND_PLAN_CARRERA",
        payload: response
    }
}

export const sendAprobadorPlanCarreraAction = async (request) => {
    
    let response = await sendAprobadorPlanCarrera(request);
    console.log(response);
    return {
        type: "SEND_APROBADOR_PLAN_CARRERA",
        payload: response
    }
}

export const getDirectorAction = async (request) => {
    
    let response = await getDirectores(request);
    console.log(response);
    return {
        type: "GET_DIRECTORES",
        payload: response
    }
}

export const sendRRHHPlanCarreraAction = async (request) => {
    
    let response = await sendRRHHPlanCarrera(request);
    console.log(response);
    return {
        type: "SEND_RRHH_PLAN_CARRERA",
        payload: response
    }
}

export const sendDirectorPlanCarreraAction = async (request) => {
    
    let response = await sendDirectorPlanCarrera(request);
    console.log(response);
    return {
        type: "SEND_DIRECTOR_PLAN_CARRERA",
        payload: response
    }
}

export const sendActualizaPlanCarreraAction = async (request) => {
    
    let response = await actualizaPlanCarrera(request);
    console.log(response);
    return {
        type: "ACTUALIZAR_PLAN_CARRERA",
        payload: response
    }
}

export const sendDestinatarioPlanCarreraAction = async (request) => {
    
    let response = await destinatarioPlanCarrera(request);
    console.log(response);
    return {
        type: "DESTINATARIO_PLAN_CARRERA",
        payload: response
    }
}