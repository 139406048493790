import React, { Fragment, Component } from 'react';
import {Radio,RadioGroup,FormControlLabel,FormControl,InputLabel} from '@material-ui/core';

export default class RadioButtons extends Component {

	constructor(props){
		super(props);
		this.state={
			defaultValue:props.defaultValue
		}
	}
 
  	handleChange = event => {
		this.setState({defaultValue:event.target.value});
		console.log(event.target.value)
		if(this.props.callback !== undefined){
			this.props.callback(event.target.value);
		} 
		

	};
  	render(){
		const { id, name, label, defaultValue, optionsSelect, disabled} = this.props;
		return (
			<Fragment>
				<InputLabel shrink htmlFor="age-native-label-placeholder">
				{label}
					</InputLabel>
				<FormControl component="fieldset" variant="outlined"  className="radio">
					<RadioGroup 
						aria-label={label} 
						name={name}
						id={id} 
						onChange={this.handleChange} 
						defaultValue={defaultValue}
						value={this.state.defaultValue}
						row={this.props.row}
						className={this.props.classNameCustom}
					>
					{optionsSelect.map((option, i) => (
						<FormControlLabel  style={{paddingRight:this.props.paddingRight}}  key={i} value={option} disabled={disabled} control={<Radio inputRef={element => { this.radioInput = element }} />} label={this.props.showLabel ? option : ''} />
					))}
					</RadioGroup>
				</FormControl>
			</Fragment>
		);
	}
}
