import React , { Component, Fragment } from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default class Email extends Component{
    constructor(props){
        super(props);
        console.log("constructor", props)
        this.state={
            errorText: '',
            error:props.error,
            defaultValue:props.defaultValue,
            focus: false
        }
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }
    UNSAFE_componentWillReceiveProps(next_props) {
        console.log("UNSAFE_componentWillReceiveProps",next_props)
        this.setState({ error: next_props.error });
    }
    
    handleChange = (event) =>{

        this.setState({defaultValue:event.target.value});
       
        if(event.target.value === ''){
          
            this.setState({errorText: 'Ingrese un email',error:true});
        }else{
            if (event.target.value.match(/^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/)) {
                this.setState({errorText: '',error:false});
            } else {
                this.setState({errorText: 'Formato inválido',error:true})
            }  
        }
    }
    
    onBlur() {setTimeout(() => {if (this.state.focus){this.setState({focus: false})}}, 0)}
    onFocus() {if (!this.state.focus) {this.setState({focus: true})}}

    render() {
        const OnFocusHideFixedElements = withStyles({"@global": {
    "body .headroom":{'@media (max-width: 960px)': {transform: "translate3d(0px, -100%, 0px)!important"}}
}})(() => null);  
        return (
            <Fragment>
                {this.state.focus && <OnFocusHideFixedElements/>}
                <TextField 
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    variant="outlined" 
                    error={this.state.error}
                    id={this.props.id} 
                    label={this.props.label} 
                    placeholder={this.props.placeholder}     
                    helperText={this.state.errorText}
                    onChange={this.handleChange}
                    value={this.state.defaultValue}
                    name={this.props.name}
                    disabled={this.props.disabled}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    margin="none"
                />
            </Fragment>
        )
    };
};
