import React, { Component } from 'react'
import { DialogContent, Dialog, Grid, Typography, Button} from '@material-ui/core';
import { getUser } from '../../../services/CardsService';
import { Alert, AlertTitle } from '@material-ui/lab';
import Email from '../../inputs/Email';

export default class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: '',
            email: '',
            initial:false,
            open:true,
            responseLogin: '',
            errors: {
                user:false
            }
        }
    }
    applyLogin = async () => {
        console.log("this.user", this.state.user)

        let countValid = 0;
        let errors = [];
        let request = {};
        if (this.state.user && this.state.user !== null) {
            if (this.state.user.state.defaultValue !== '' && !this.state.user.state.error) {
                countValid++;
                request = {
                    "mail": this.state.user.state.defaultValue
                };
            }
        }
        errors = {
            user: (this.state.user && this.state.user.state.defaultValue !== '' && this.state.user.state.errorText === '') ? false : true,
        }

        if (1 === countValid) {

            let response = await getUser(request)
                .then(res => {
                    console.log("them", res)
                    if(res){
                        window.localStorage.setItem('userData',JSON.stringify(res[0]));
                        if(this.props.callback !== undefined)
                        this.props.callback(true,JSON.stringify(res[0]))
                        
                        this.setState({open:false });
                    }else{
                        this.setState({ responseLogin: false, initial: true,open:true });
                        if(this.props.callback !== undefined)
                        this.props.callback(false,null)
                    }
                    
                })
                .catch((err) => {
                    console.log("catch", err);
                    this.setState({ responseLogin: false, initial: true,open:true });
                    if(this.props.callback !== undefined)
                        this.props.callback(false,null)

                });
        } else {
            this.setState({ initial: true });
        }
    }
    render() {
        return (
            <Dialog open={this.state.open} align="center">
                <DialogContent>

                    <Grid container >
                        {this.state.initial && !this.state.responseLogin &&
                            <Grid item md={12}>
                                <Alert severity="error">
                                    <AlertTitle>Error:  Email no encontrado</AlertTitle>
                                </Alert>
                            </Grid>}
                        <Grid item md={12}>
                            <Typography variant="h5">Ingrese su email </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <Email
                                ref={element => { this.state.user = element }}
                                label=""
                                placeholder={""}
                                disabled={false}
                                defaultValue=""
                                error={this.state.errors.user}
                            />
                        </Grid>
                        <br />
                        <Grid item md={3}></Grid>
                        <Grid item md={3}></Grid>
                        <Grid item md={3}></Grid>
                        <Grid item md={3}>
                            <Button
                                style={{ color: 'white' }}
                                disabled={false}
                                className="enviar"
                                onClick={this.applyLogin}
                                variant="contained">Aceptar</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }
}
