export const getMotivo = () =>{
    const response = [
        "PROFILACTICAS",
        "Examen",
        "Matrimonio",
        "Por concurrencia a juzgado ó autoridad administrativa con citación previa",
        "OTRO MOTIVO"
    ];
         
    return response;
}

export const getSiNo = () =>{
    const response = [
        "No",
        "Si"
    ];
         
    return response;
}
export const getMonths = () =>{
    const response = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"     
    ];
         
    return response;
}

export const getCalificacionPerfil = () =>{
    const response = [
        "Supera",
        "Cumple",
        "No avanza" 
    ];
         
    return response;
}

export const getCompetenciasFuncionales = () =>{
    const response = [
        "Involucramiento con normas y procedimientos organizacionales",
        "Modalidades de contacto",
        "Flexibilidad",
        "Habilidad analítica",
        "Acuracidad",
        "Fidelidad",
        "Orientación a resultados",
        "Precisión técnica y cuidado de las herramientas de trabajo",
        "Disponibilidad de respuesta",
        "Fidelización del capital humano",
        "Confidencialidad y prudencia",
        "Orientación al cliente",
        "Innovación",
        "Orientación al paciente/médico/financiador",
        "Resolución de conflictos",
        "Marcar la diferencia",
        "Prudencia",
        "Responsabilidad social empresaria",
        "Proactividad"
    ];
         
    return response;
}

export const getCompetenciasGenerales = () =>{
    const response = [
        "Actitud de servicio",
        "Trabajo en equipo"
    ];
         
    return response;
}

export const getCompetenciasDeNivel = () =>{
    const response = [
        "Responsabilidad",
        "Conciencia organizacional",
        "Adaptabilidad",
        "Credibilidad técnica",
        "Conocimiento de los servicios ofrecidos",
        "Aprendizaje continuo",
        "Involucramiento con normas y procedimientos organizacionales, contables, impositivos y legales",
        "Liderazgo",
        "Calificación",
        "Pensamiento estratégico"
    ];
         
    return response;
}




