import React, { Component, Fragment } from 'react';
import config from './../../../config/config.json';
import DashboardCard from './DashboardCard';
import { IconButton, Collapse, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: true,
			cards: config.cards,
			openAlert: true
		}
	}

	componentWillMount = async () => {

		this.setState({ cards: config.cards });
	}

	collapseAlert = () => {
		this.setState({ openAlert: false });
	}

	render() {
		return (
			<Fragment>
				<Grid container spacing={3}>
					{this.props.location.state !== undefined && this.props.location.state.responseForm !== undefined && (
						<Collapse in={this.state.openAlert}>
							
								<Grid item md={12}>
									<Alert severity={`${this.props.location.state.error ? 'error' : 'success'}`}
										action={
											<IconButton
												aria-label="close"
												color="inherit"
												size="small"
												onClick={this.collapseAlert}
											>
												<CloseIcon fontSize="inherit" />
											</IconButton>
										}
									>
										{(this.props.location.state.error) ? 'Se produjo un error. Vuelva a intentarlo' : this.props.location.state.responseForm}
									</Alert>
								
							</Grid>
						
						</Collapse>
					)}
					<Grid item sm={12}>
						{this.state.cards.map((card, i) => {
							return (<DashboardCard
								key={i}
								indice={i}
								card={card}
							/>)
						})}
					</Grid>
				</Grid>
			</Fragment>
		)
	}
}

export default Dashboard
