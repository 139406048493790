
import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import '../../assets/Form.css';

export default function FirmasAprobadoresForm(props) {

    return (

        <Grid container spacing={1} style={{ marginBottom: 2 }}>
            
            <Grid item xs={6} >
                <Typography className="size">FIRMA(S) JEFE(S) DIRECTO:  {props.peticion.aprobadores.map((val,i) => (
                   <Typography key={i} className="size">{val.name}</Typography> 
                ))}
                </Typography>
            </Grid>
            <Grid item xs={6} >    <Typography className="size">ACLARACIÓN: {props.peticion.aprobadores.map((val,i) => (
                  <Typography key={i} className="size">{val.fecha_conformidad === null ? `Visto en conformidad ${new Date().toLocaleString()}`  : `Visto en conformidad ${val.fecha_conformidad}` }</Typography>
                  
                ))}</Typography></Grid>
        </Grid>
    )
}