import React, { Component, Fragment } from "react";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
// pick a date util library
import enLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
export default class DateOfBirthDatePickerInline extends Component {
	constructor(props) {
		super(props);

		let today = new Date();
		let minDate  =new Date(today.getFullYear() - 99,today.getMonth(),today.getDate(),0,0,0);
		let maxDate  = new Date(today.getFullYear() + 99,today.getMonth(),today.getDate(),0,0,0);

		this.state = {
			locale: "es",
			selectedDate: (props.defaultValue !== null) ? this.formatDate(props.defaultValue) : null,
			minDate: minDate,
			maxDate: maxDate,
			localeMap: {
				es: enLocale
			},
			errorText: '',
			error: props.error
		}
	}


	
	componentDidUpdate(prevProps, prevState) {
        if (prevProps.error !== this.props.error) {
            this.setState({
				error: this.props.error
            })
		}
		if (prevProps.defaultValue !== this.props.defaultValue) {
            this.setState({
				selectedDate: this.formatDate(this.props.defaultValue)
            })
        }
    }

	formatDate = (date) => {
		let d = new Date(date);

		let month = '' + (d.getMonth() + 1);
		let day = '' + d.getDate();
		let year = d.getFullYear();

		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;

		if ([year, month, day].join('/') === 'NaN/NaN/NaN')
			return false;
		else
			return [year, month, day].join('/');
	}

	handleChange = date => {

		let response = this.formatDate(date);

		this.setState({ selectedDate: response })
		if (!response || date === null) {

			this.setState({ selectedDate: null, errorText: 'Ingrese una fecha con formato DD/MM/AAAA', error: true });
		} else {
			date = new Date(date.setHours(0, 0, 0, 0));

			if (date > this.state.maxDate || date < this.state.minDate) this.setState({ error: true, errorText: "Fecha fuera de rango" });
			else
				this.setState({ errorText: '', error: false });

		}
	};
	resetDate = () => {
		this.setState({selectedDate: null})
	   }

	render() {
		const theme = createMuiTheme({
		
			palette: {
				primary: {
					main: '#a51e36',
					light:  '#a51e36',
					dark: '#a51e36'
				},
				secondary: {
					main: '#a51e36',
				},
			},
			
		});
		return (
			<Fragment>
				<ThemeProvider theme={theme}>
					<MuiPickersUtilsProvider utils={DateFnsUtils} locale={this.state.localeMap[this.state.locale]}>
				
						<KeyboardDatePicker
							autoOk
							size='small'
							margin="none"
							variant="inline"
							inputVariant="outlined"
							error={this.state.error}
							label={this.props.label}
							name={this.props.name}
							helperText={this.state.errorText}
							format="dd/MM/yyyy"
							invalidDateMessage={this.state.errorText}
							value={this.state.selectedDate}
							InputAdornmentProps={{ position: "start" }}
							onChange={(date) => { this.handleChange(date) }}
							maxDateMessage="La fecha no debe ser posterior a la fecha máxima"
							minDateMessage="La fecha no debe ser anterior a la fecha mínima"
							minDate={this.state.minDate}
							maxDate={this.state.maxDate}
							placeholder={this.props.placeholder}
							id={this.props.id}
							autoComplete="off"
							disabled={this.props.disabled}
							fullWidth
						/>

					</MuiPickersUtilsProvider>
				</ThemeProvider>
			</Fragment>
		);
	}

}


