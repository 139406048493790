import React, { Component } from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

//Dashboard
import Dashboard from "./components/views/admin/Dashboard";
import PeticionPlanCarrera from './components/forms/PeticionPlanCarrera';
import Licencia from './components/forms/Licencia';
import PdfLicencia from './components/pdf/PdfLicencia';
import PdfPlanCarrera from './components/pdf/PdfPlanCarrera';
import PlanCarrera from './components/forms/PlanCarrera';
import DashboardCardExpanded from "./components/views/admin/DashboardCardExpanded";

//import List from './components/List'
import Aprobador from './components/Aprobador'
import AprobadorRrhh from './components/AprobadorRrhh'
import AprobadorRrhhPlanCarrera from './components/AprobadorRrhhPlanCarrera'
import AprobadorDirectorPlanCarrera from './components/AprobadorDirectorPlanCarrera'
//Layout principales
import Main from './components/layouts/Main';
import MainAdmin from './components/layouts/MainAdmin';
import AprobadorPlanCarrera from "./components/AprobadorPlanCarrera";
import ActualizaPlanCarrera from "./components/ActualizaPlanCarrera";
import DestinatarioPlanCarrera from "./components/DestinatarioPlanCarrera";

class Routing extends Component {
    render() {
        return (
            <Router>
            <Switch>
                <Route path='/' component={Main} exact />
                <Route path='/dashboard' >
                    <MainAdmin>
                        <Switch>
                            <Route path='/dashboard' exact component={Dashboard} />
                            <Route path="/dashboard/peticiones/crear" component={PeticionPlanCarrera} exact />
                            <Route path='/dashboard/:card' exact component={DashboardCardExpanded} />
                        </Switch>
                    </MainAdmin>
                </Route>
					<Route path="/formulario_licencia/" component={Licencia} exact />
					<Route path="/formulario_plan_carrera/:id/:idAprobador/:hash" component={PlanCarrera} exact />
					<Route path="/aprobador/:id/:idAprobador/:hash" component={Aprobador} exact />
			     	<Route path="/aprobador_plan_carrera/:id/:idAprobador/:hash" component={AprobadorPlanCarrera} exact /> 
                     <Route path="/aprueba_plan_carrera_RRHH/:id/:hash" component={AprobadorRrhhPlanCarrera} exact />
                     <Route path="/aprueba_plan_carrera_director/:id/:hash" component={AprobadorDirectorPlanCarrera} exact />
                     <Route path="/destinatario_plan_carrera/:id/:hash" component={DestinatarioPlanCarrera} exact />
                     <Route path="/actualiza_plan_carrera/:id/:hash" component={ActualizaPlanCarrera} exact />
                    <Route path="/apruebaRRHH/:id/:hash" component={AprobadorRrhh} exact />
                    <Route path="/formulario/licencia/show/:id/" component={PdfLicencia} exact />
                     <Route path="/formulario/plan_carrera/show/:id/" component={PdfPlanCarrera} exact /> 	
            </Switch>
        </Router>
        );
    }
}

export default Routing;