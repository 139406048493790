import React, { Fragment, Component } from 'react';
import {Checkbox,FormControl,FormControlLabel } from '@material-ui/core';

export default class CheckboxLicencia extends Component {

	constructor(props) {
		super(props);
		this.state = {
			checked: this.props.defaultChecked,
		}
	}

	handleChange = event => {

		this.setState({ defaultValue: event.target.value, checked: event.target.checked });
		this.props.callback(event.target.name, event.target.checked)
	};
	render() {
		const { id, name, label, defaultValue } = this.props;

		return (
			<Fragment>
				<FormControl component="fieldset" variant="outlined" fullWidth className="checkbox">
					<FormControlLabel
						control={
							<Checkbox
								checked={this.props.checked}
								onChange={this.handleChange}
								name={name}
								color="primary"
								aria-expanded={true}
								id={id}
								value={defaultValue}
								disabled={this.props.disabled}
							/>
						}
						label={label}
					/>
				</FormControl>
			</Fragment>
		);
	}
}
