
import React, { Fragment } from 'react'
import { Grid,Typography} from '@material-ui/core';

export default function FirmasFormPlanCarrera(props){

    return(
        <Fragment>
        <Grid container spacing={2}>
            <Grid item xs={6} >
                <Typography style={{ 'fontSize': '12px' }}>Validado por:</Typography>
            </Grid>
            <Grid item xs={6} > <Typography style={{ 'fontSize': '12px' }}>ACLARACIÓN:</Typography></Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 2 }}>
            <Grid item xs={6} >
                <Typography style={{ 'fontSize': '12px' }}>{props.aprobadorFirm}</Typography>
                <Typography>_______________________________</Typography>
            </Grid>
            <Grid item xs={6} >

                <Typography style={{ 'fontSize': '12px' }}>Visto en conformidad {new Date().toLocaleString()}</Typography>
                <Typography>___________________________________</Typography>
            </Grid>
        </Grid>
        </Fragment>

    )
}