import React , { Component, Fragment } from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default class Number extends Component{
    constructor(props){
        super(props);
        this.state={
            errorText: '',
            error:props.error,
            initial:true,
            defaultValue:this.props.defaultValue,
            focus: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);   
    }
    UNSAFE_componentWillReceiveProps(next_props) {
        this.setState({ error: next_props.error });
    }
    resetValue =  () => {
        this.setState({
            defaultValue:'',
            errorText: '',
            error:false
        })
    }

    clearNumber = (value = '') =>{
        return value.replace(/\D+/g, '');
    }
  
    handleChange = (event) =>{

        let value = event.target.value;
        if(this.props.maxLength !== null){
            const clearValue = this.clearNumber(value);
            value = clearValue.slice(0, this.props.maxLength);
           
        }
       
        this.setState({defaultValue:value});

        if(value === ''){
            this.setState({errorText: this.props.placeholder,error:true});
        }else{
            if (value.match('^[0-9]*$')) {
            
                if(this.props.minLength !== null && value.length < this.props.minLength){
                    this.setState({errorText: 'Mínimo '+this.props.minLength+' números',error:true})
                
                }else{
                    if(this.props.maxLength !== null && value.length !== this.props.maxLength){
                     
                        
                        this.setState({errorText: 'Máximo '+this.props.maxLength+' números',error:true}) 
                    }else{
                        this.setState({errorText: '',error:false})
                    }
                }
            } else {
                this.setState({defaultValue:'',errorText: 'Sólo números',error:true})   
            }
        }
    }

    onBlur() {setTimeout(() => {if (this.state.focus){this.setState({focus: false})}}, 0)}
    onFocus() {if (!this.state.focus) {this.setState({focus: true})}}

  render() {
    const OnFocusHideFixedElements = withStyles({"@global": {
    "body .headroom":{'@media (max-width: 960px)': {transform: "translate3d(0px, -100%, 0px)!important"}}
}})(() => null);  
    return (
        <Fragment>
            {this.state.focus && <OnFocusHideFixedElements/>}
            <TextField 
                fullWidth
                InputLabelProps={{shrink: true}}
                className={this.props.classNameCustom}
                variant="outlined" 
                error={this.state.error}
                id={this.props.id} 
                label={this.props.label} 
                placeholder={this.props.placeholder}
                helperText={this.state.errorText}
                onChange={this.handleChange}
                value={this.state.defaultValue}
                name={this.props.name}
                disabled={this.props.disabled}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                margin="none"
            />
        </Fragment>
    );
  };
};
