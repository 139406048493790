import axios from "axios";
import config from "../config/config.json"

export const sendLicencia = async (request) => {
   
    const url =  config.credentials.url+'licencia/';
    const headers = {
        'Content-Type': "application/json"
      };
    const response = await axios.post(url,JSON.stringify(request),{headers:headers})
   
    .then(res => {
        console.log(res);
        return res.data;
    })
    .catch((err) => {
        //console.log(err);
        return false;
    }); 

    return response;
}

export const sendAprobador = async (request) =>{
   
    const headers = {
        'Content-Type': "application/json"
      };
   
    const url = config.credentials.url+'aprueba/';

    const response = await axios.post(url,JSON.stringify(request),{headers:headers})
    .then(res => {
        console.log(res);
        let data={
            "info": "Se actualizó correctamente la petición",
            "error": false
        }
        return data;
    })
    .catch((err) => {
        let data={
            "info": "Ha ocurrido un error mientras se actualizaba. Vuelva a intentar.",
            "error": true
        }
       
        return data;
    }); 

    return response;
}


export const sendAprobadorRRHH = async (request) =>{
   
    const headers = {
        'Content-Type': "application/json"
      };
   
    const url = config.credentials.url+'aprueba-rrhh/';

    const response = await axios.post(url,JSON.stringify(request),{headers:headers})
    .then(res => {
        console.log(res);
        let data={
            "info": "Se actualizó correctamente la petición",
            "error": false
        }
        return data;
    })
    .catch((err) => {
        let data={
            "info": "Ha ocurrido un error mientras se actualizaba. Vuelva a intentar.",
            "error": true
        }
       
        return data;
    }); 

    return response;
}

export const sendPlanCarrera = async (request) => {
   
    const url =  config.credentials.url+'completa-carrera/';
    const headers = {
        'Content-Type': "application/json"
      };
    const response = await axios.post(url,JSON.stringify(request),{headers:headers})
   
    .then(res => {
        console.log(res);
        return res.data;
    })
    .catch((err) => {
        //console.log(err);
        return false;
    }); 

    return response;
}


export const sendAprobadorPlanCarrera = async (request) =>{
   
    const headers = {
        'Content-Type': "application/json"
      };
   
    const url = config.credentials.url+'aprueba-carrera/';

    const response = await axios.post(url,JSON.stringify(request),{headers:headers})
    .then(res => {
        console.log(res);
        let data={
            "info": "Se actualizó correctamente la petición",
            "error": false
        }
        return data;
    })
    .catch((err) => {
        let data={
            "info": "Ha ocurrido un error mientras se actualizaba. Vuelva a intentar.",
            "error": true
        }
       
        return data;
    }); 

    return response;
}

export const getDirectores = async () => {
   
    const url = config.credentials.url+'getDirector/';
    const response = await axios.get(url)
    
    .then(res => {
        console.log(res);
        return res.data;
    })
    .catch((err) => {
        //console.log(err);
        return false;
    }); 

    return response;
}

export const sendRRHHPlanCarrera = async (request)=>{
    const headers = {
        'Content-Type': "application/json"
      };
   
    const url = config.credentials.url+'aprueba-carrera-rrhh/';

    const response = await axios.post(url,JSON.stringify(request),{headers:headers})
    .then(res => {
        console.log(res);
        let data={
            "info": "Se actualizó correctamente la petición",
            "error": false
        }
        return data;
    })
    .catch((err) => {
        let data={
            "info": "Ha ocurrido un error mientras se actualizaba. Vuelva a intentar.",
            "error": true
        }
       
        return data;
    }); 

    return response;
}
export const sendDirectorPlanCarrera = async (request)=>{
    const headers = {
        'Content-Type': "application/json"
      };
   
    const url = config.credentials.url+'aprueba-director/';

    const response = await axios.post(url,JSON.stringify(request),{headers:headers})
    .then(res => {
        console.log(res);
        let data={
            "info": "Se actualizó correctamente la petición",
            "error": false
        }
        return data;
    })
    .catch((err) => {
        let data={
            "info": "Ha ocurrido un error mientras se actualizaba. Vuelva a intentar.",
            "error": true
        }
       
        return data;
    }); 

    return response;
}

export const actualizaPlanCarrera = async (request)=>{
    const headers = {
        'Content-Type': "application/json"
      };
   
    const url = config.credentials.url+'actualiza-responsable/';

    const response = await axios.post(url,JSON.stringify(request),{headers:headers})
    .then(res => {
        console.log(res);
        let data={
            "info": "Se actualizó correctamente la petición",
            "error": false
        }
        return data;
    })
    .catch((err) => {
        let data={
            "info": "Ha ocurrido un error mientras se actualizaba. Vuelva a intentar.",
            "error": true
        }
       
        return data;
    }); 

    return response;
}


export const destinatarioPlanCarrera = async (request)=>{
    const headers = {
        'Content-Type': "application/json"
      };
   
    const url = config.credentials.url+'acepta-destinatario/';

    const response = await axios.post(url,JSON.stringify(request),{headers:headers})
    .then(res => {
        console.log(res);
        let data={
            "info": "Se actualizó correctamente la petición",
            "error": false
        }
        return data;
    })
    .catch((err) => {
        let data={
            "info": "Ha ocurrido un error mientras se actualizaba. Vuelva a intentar.",
            "error": true
        }
       
        return data;
    }); 

    return response;
}



