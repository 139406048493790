import React, { Component, Fragment } from "react";
import {MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
// pick a date util library
import enLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';

export default class DatePicker extends Component {
  	constructor(props){
		super(props);
		
		let dateReserva = new Date(this.props.date);
		let today = new Date();
		
		let minYear  = (props.min !== null && props.min[0]) ? dateReserva.getFullYear() - parseInt(props.min[0]) : '';
		let minMonth = (props.min !== null && props.min[1] !== null) ? dateReserva.getMonth() - parseInt(props.min[1]) : '';
		let minDay   = (props.min  !== null && props.min[2] !== null) ? dateReserva.getDate() - parseInt(props.min[2]) : '';
		
		
		let maxYear  = (props.max !== null && props.max[0] !== null ) ? dateReserva.getFullYear() - parseInt(props.max[0]) :  dateReserva.getFullYear();
		let maxMonth = (props.max !== null  && props.max[1] !== null) ?  dateReserva.getMonth() - parseInt(props.max[1]) : '';
		let maxDay   = (props.max !== null  && props.max[2] !== null) ? dateReserva.getDate() - parseInt(props.max[2]) : '';

		let minDate  =new Date(minYear,minMonth,minDay,0,0,0);
	
		let maxDate  = new Date(maxYear,maxMonth,maxDay,0,0,0); 
		
	
		/* if(this.props.ages[0] !== this.props.ages[1]){
			
			let min = dateReserva.getFullYear() - parseInt(this.props.ages[0]);
			let max = dateReserva.getFullYear() - parseInt(this.props.ages[1]);
	
			maxDate  = new Date(min,dateReserva.getMonth(),dateReserva.getDate(),0,0,0);
			minDate  = new Date(max - 1,dateReserva.getMonth(),dateReserva.getDate() + 1,0,0,0);
		}else{
			let min = dateReserva.getFullYear() - parseInt(this.props.ages[0]);
			let max = dateReserva.getFullYear() - parseInt(this.props.ages[0]);
			minDate  = new Date(min-1,dateReserva.getMonth(),dateReserva.getDate(),0,0,0);
			maxDate  = new Date(max,dateReserva.getMonth(),dateReserva.getDate(),0,0,0);
		} */

	
		if(maxDate > today) maxDate = new Date(today.getFullYear(),today.getMonth(),today.getDate()-1);
		this.state={
			locale:"es",
			selectedDate: (props.defaultValue !== null) ? props.defaultValue : null,
			minDate:minDate,
			maxDate:maxDate,
			localeMap:{
				es:enLocale
			},
			errorText:'',
			error:props.error
		}
	  }
	  
	calcularEdad = (date,birthday) =>{
	
		let age_now = date.getFullYear() - birthday.getFullYear();
    
    	let m = date.getMonth() - birthday.getMonth();
	
		if (m < 0 || (m === 0 && date.getDate() < birthday.getDate())) 
		{
			age_now--;
		} 
		//console.log("edad que ingreso ",age_now);
		return age_now;
	}
  	UNSAFE_componentWillReceiveProps(next_props) {
        this.setState({ error: next_props.error });
    }
   	
    formatDate = (date) =>{
		let d = new Date(date);
		let month = '' + (d.getMonth() + 1);
		let  day = '' + d.getDate();
		let  year = d.getFullYear();

		if (month.length < 2) 
			month = '0' + month;
		if (day.length < 2) 
			day = '0' + day;
  
		if([year, month, day].join('/') === 'NaN/NaN/NaN')
			return false;
		else
			return [year, month, day].join('/');
  	}
	
   	handleChange = date => {
		
		let response = this.formatDate(date);
	
		
		if(!response || date === null){
			
			this.setState({/* selectedDate:null, */ errorText: 'Ingrese una fecha con formato DD/MM/AAAA',error:true});	
		}else{
			date = new Date(date.setHours(0,0,0,0));
		
			this.setState({selectedDate:response})
		
			
				
				if(date > this.state.maxDate || date < this.state.minDate )this.setState({error:true, errorText:"Fecha fuera de rango"});
				else
					this.setState({errorText: '',error:false});
			
			
		
		}  
   };
   
  render(){
	
    return (
      <Fragment>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={this.state.localeMap[this.state.locale]}>
  
        <KeyboardDatePicker
			  autoOk
		
          	variant="inline"
          	inputVariant="outlined"
          	error={this.state.error}
          	label={this.props.label}
			  name={this.props.name}
			helperText={this.state.errorText}
          	format="dd/MM/yyyy"
          	invalidDateMessage={this.state.errorText}
          	value={this.state.selectedDate}
			InputAdornmentProps={{ position: "start" }}
			onChange={(date) =>{this.handleChange(date)}}
			maxDateMessage="La fecha no debe ser posterior a la fecha máxima"
			minDateMessage="La fecha no debe ser anterior a la fecha mínima"
			minDate={this.state.minDate}
			maxDate={this.state.maxDate}
		    disabled={this.props.disabled}
		    placeholder={this.props.placeholder}
			id={this.props.id}
			className={`datepicker ${this.state.initial ? 'initialInput' : ''} ${this.props.classNameCustom}`}
			autoComplete="off"
			margin="none"
        />
  
        </MuiPickersUtilsProvider>
      </Fragment>
    );
  }
  
}


