import React, { Component } from 'react';
import { Button, MenuItem, Menu, Link } from '@material-ui/core';

export default class MenuDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null
		}
	}

	handleClick = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		
		this.setState({ anchorEl: null });
	};

	handleLogout = () => {
	
		window.localStorage.clear();
	};

	render() {

		return (
			<div>
				<Button
					startIcon={this.props.buttonStartIcon}
					endIcon={this.props.buttonEndIcon}
					color="primay"
					className="button_lowecase"
					aria-controls="simple-menu"
					aria-haspopup={true}
					onClick={this.handleClick}>
					{this.props.buttonText}
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={this.state.anchorEl}
					keepMounted
					open={Boolean(this.state.anchorEl)}
					onClose={this.handleClose}
					getContentAnchorEl={null}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					transformOrigin={{ vertical: "top", horizontal: "right" }}
				>
					
					<MenuItem onClick={this.handleLogout} component={Link} href={`/dashboard/`} underline='none'>
							Logout
						</MenuItem>
				
				</Menu>
			</div>
		)
	}
}
