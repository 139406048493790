const INITIAL_STATE = {
	listData: {},
	tipoPeticion: [],
	status: [],
	responsePeticion: {},
	responseLicencia: {},
	peticionData: {},
	responseAprobador: {},
	responseAprobadorPlanCarrera: {},
	responseRRHH: {},
	responseUsuarios: {},
	responseAprobadores: {},
	responseAprobadorRRHH: {},
	responsePlanCarrera: {},
	responseAprobadoresByDepartment: {},
	responseDirectores: {},
	responseRRHHPlanCarrera: {},
	responseDirectorPlanCarrera: {},
	responseActualizaPlanCarrera: {},
	responseDestinatarioPlanCarrera: {},
	responseExport: '',
	responseUser: {}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "GET_LIST":

			return { ...state, listData: action.payload };
		case "GET_TIPO_PETICION":

			if (action.payload)
				return { ...state, tipoPeticion: action.payload };
			else
				return { ...state, tipoPeticion: state.tipoPeticion };

		case "SEND_PETICION":
			return { ...state, responsePeticion: action.payload };
		case "GET_PETICION":
			return { ...state, peticionData: action.payload };
		case "SEND_LICENCIA":
			return { ...state, responseLicencia: action.payload };
		case "SEND_APROBADOR":
			console.log(action.payload)
			return { ...state, responseAprobador: action.payload };
		case "GET_RRHH":
			console.log(action.payload)
			return { ...state, responseRRHH: action.payload };
		case "GET_USUARIOS":
			console.log(action.payload)
			return { ...state, responseUsuarios: action.payload };
		case "GET_APROBADORES":
			console.log(action.payload)
			return { ...state, responseAprobadores: action.payload };
		case "SEND_APROBADOR_RRHH":
			console.log(action.payload)
			return { ...state, responseAprobadorRRHH: action.payload };
		case "SEND_PLAN_CARRERA":
			return { ...state, responsePlanCarrera: action.payload };
		case "GET_APROBADORES_DEPARTMENT":
			return { ...state, responseAprobadoresByDepartment: action.payload }
		case "SEND_APROBADOR_PLAN_CARRERA":
			console.log(action.payload)
			return { ...state, responseAprobadorPlanCarrera: action.payload };
		case "GET_DIRECTORES":
			return { ...state, responseDirectores: action.payload }
		case "SEND_RRHH_PLAN_CARRERA":
			console.log(action.payload)
			return { ...state, responseRRHHPlanCarrera: action.payload };
		case "SEND_DIRECTOR_PLAN_CARRERA":
			return { ...state, responseDirectorPlanCarrera: action.payload }
		case "ACTUALIZAR_PLAN_CARRERA":
			return { ...state, responseActualizaPlanCarrera: action.payload }
		case "DESTINATARIO_PLAN_CARRERA":
			return { ...state, responseDestinatarioPlanCarrera: action.payload }
		case "GET_STATUS":

			if (action.payload)
				return { ...state, status: action.payload };
			else
				return { ...state, status: state.status };
		case "GET_EXPORT":
			return { ...state, responseExport: action.payload };
		case "GET_USER":

			return { ...state, responseUser: action.payload };
		default:
			return state;
	}
};
