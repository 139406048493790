import React, { Component, Fragment } from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import {InputLabel,MenuItem,FormControl,Select} from '@material-ui/core';


export default class SimpleSelect extends Component {

	constructor(props) {
		super(props);
		this.state = {
			errorText: '',
			error: props.error,
			defaultValue: this.props.defaultValue,
			focus: false
		}
		this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
	}

	handleChange = event => {
		if(event.target.name === "motivo_licencia"){
			this.props.callback(event.target.value)
		}
		this.setState({ defaultValue: event.target.value });
	};

	onBlur() {setTimeout(() => {if (this.state.focus){this.setState({focus: false})}}, 0)}
    onFocus() {if (!this.state.focus) {this.setState({focus: true})}}

	UNSAFE_componentWillReceiveProps(next_props) {
		this.setState({ error: next_props.error });
	}

	render() {
		const classes = makeStyles(theme => ({
			formControl: {

				"& .MuiPaper-root": {
					maxHeight: '340px',
				}
			}
		}));
		const OnFocusHideFixedElements = withStyles({
			"@global": {
				"body .headroom": { '@media (max-width: 960px)': { transform: "translate3d(0px, -100%, 0px)!important" } }
			}
		})(() => null);

		const { id, labelId, labelText, optionsSelect, defaultValue, name } = this.props;

		return (
			<Fragment>
				{this.state.focus && <OnFocusHideFixedElements />}
				<FormControl variant="outlined" fullWidth className={classes.formControl}>
				<InputLabel shrink htmlFor="age-native-label-placeholder">
				{labelText}
					</InputLabel>
					
					<Select
						labelId={labelId}
						id={id}
						margin="none"
						name={name}
						onChange={this.handleChange}
						label={labelText}
						defaultValue={defaultValue}
						error={this.state.error}
						helperText={this.state.errorText}
						onFocus={this.onFocus}
						onBlur={this.onBlur}
						disabled={this.props.disabled}
					>

						{optionsSelect.map((option, i) => {
							return <MenuItem key={i} value={option}>{option}</MenuItem>
						})}

					</Select>
				</FormControl>
			</Fragment>
		);
	}
}
