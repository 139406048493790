import React, { Component, Fragment } from 'react'
import { sendRRHHPlanCarreraAction, getDirectorAction } from '../actions/FormAction'
import { getPeticionAction } from '../actions/PeticionAction'
import { connect } from "react-redux";
import { TextField, InputAdornment, Icon, Divider, Dialog, DialogContent, Grid, Card, CardHeader, CardContent, Typography, Button } from '@material-ui/core';
import FirmaRRHHForm from './partials/FirmaRRHHForm';
import FirmasAprobadoresForm from './partials/FirmasAprobadoresForm';
import Theme from '../assets/Theme';
import configStyles from '../config/style.json';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Redirect } from "react-router-dom";
class AprobadorRrhhPlanCarrera extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openAlert: true,
            checkbox: false,
            disabled: true,
            otrasCondiciones: null,
            adicional: null,
            categoria: null,
            stepAvanza: null,
            sueldoBasico: null,
            director: null,
            errors: {
                otrasCondiciones: false,
                adicional: false,
                categoria: false,
                stepAvanza: false,
                sueldoBasico: false,
                director: false
            }
        }
    }
    collapseAlert = () => {
        this.setState({ openAlert: false })
    }

    UNSAFE_componentWillMount = () => {
        const id = this.props.match.params.id;
        this.props.dispatchGetPeticionAction(id);
        this.props.dispatchGetDirectorAction()
    }
    getValueDirector = (object, value, reason) => {
        this.setState({ director: value })
    }

    aprueba = () => {
         //No genera cambios
         const hash = this.props.match.params.hash;
         let request = {
             "hash": hash,
             "status": 1
         }
         this.props.dispatchSendRRHHPlanCarreraAction(request);
     }

    desaprueba = () => {
        const hash = this.props.match.params.hash;

        //let step_avanza = new Object();
        let count = 0;
        let countValid = 0;

        let form = {
            "hash": hash,
            "status": 0
        }
        /*  if (this.state.stepAvanza && this.state.stepAvanza !== null) {
             count++;
             if (this.state.stepAvanza.state.defaultValue !== '') {
                 countValid++;
                 step_avanza["nombre"] = this.state.stepAvanza.state.defaultValue;
             }
         }
 
         if (this.state.categoria && this.state.categoria !== null) {
             count++;
             if (this.state.categoria.state.defaultValue !== '') {
                 countValid++;
                 step_avanza["categoria"] = this.state.categoria.state.defaultValue;
             }
         }
 
         if (this.state.sueldoBasico && this.state.sueldoBasico !== null) {
             count++;
             if (this.state.sueldoBasico.state.defaultValue !== '') {
                 countValid++;
                 step_avanza["sueldo_basico"] = this.state.sueldoBasico.state.defaultValue;
             }
         }
 
         if (this.state.adicional && this.state.adicional !== null) {
             count++;
             if (this.state.adicional.state.defaultValue !== '') {
                 countValid++;
                 step_avanza["adicional_vol"] = this.state.adicional.state.defaultValue;
             }
         }
 
         if (this.state.otrasCondiciones && this.state.otrasCondiciones !== null) {
            
             if (this.state.otrasCondiciones.state.defaultValue !== '') {
                
                 step_avanza["otras_condiciones"] = this.state.otrasCondiciones.state.defaultValue;
             }
         } */
        count++;
        if (this.state.director !== null) {

            countValid++;
            let director = this.state.director;
            director["status"] = "0";
            form["director"] = director;
        }
        // form["step_avanza"] = step_avanza;
        let errors = {
            /* stepAvanza: (this.state.stepAvanza && this.state.stepAvanza.state.defaultValue !== '' && this.state.stepAvanza.state.errorText === '') ? false : true,
            categoria: (this.state.categoria && this.state.categoria.state.defaultValue !== '' && this.state.categoria.state.errorText === '') ? false : true,
            sueldoBasico: (this.state.sueldoBasico && this.state.sueldoBasico.state.defaultValue !== '' && this.state.sueldoBasico.state.errorText === '') ? false : true,
            adicional: (this.state.adicional && this.state.adicional.state.defaultValue !== '' && this.state.adicional.state.errorText === '') ? false : true,
            otrasCondiciones: false, */
            director: (this.state.director !== null) ? false : true
        }
        this.setState({
            errors: errors
        })

        if (count === countValid) {
            this.props.dispatchSendRRHHPlanCarreraAction(form);
        }
    }
    loadingScreen = () => {
        return (
            <Dialog open={true} align="center">
                <DialogContent>
                    <div>  </div>
                    <div> <Typography variant="h5">Procesando datos </Typography>  </div>
                </DialogContent>
            </Dialog>
        )
    }
    render() {

        return (

            Object.keys(this.props.responseRRHHPlanCarrera).length > 0 ?
                (<Redirect to={{
                    pathname: `/dashboard/`, state: {
                        responseForm: this.props.responseRRHHPlanCarrera.info,
                        error: this.props.responseRRHHPlanCarrera.error
                    }
                }} />
               )
                :
                (Object.keys(this.props.peticionData).length > 0 ?
                    (<Theme>
                        <div className={`cardView_container`} styles={configStyles}>
                            <Grid container spacing={1}>

                                <Grid item sm={3}></Grid>
                                <Grid item sm={6}>
                                    <Card >
                                        <CardHeader className="Detail_Action_ViewHeader"
                                            title={
                                                <Fragment>
                                                    <Typography component="h4" variant="h4">Solicitud de Plan de carrera del empleado/a:  {this.props.peticionData.destinatario[0].name} {this.props.peticionData.destinatario[0].surname}</Typography>
                                                </Fragment>
                                            } />

                                        <CardContent className="Detail_Action_ViewContainer">

                                            <Grid container spacing={1}>
                                                <Grid item sm={5}>
                                                    <Typography ><strong>Avance en plan de carrera: </strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.avance}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>
                                                </Grid>
                                            </Grid>
                                            <Typography component="h5" variant="h5">Step actual </Typography>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>Puesto:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography> {this.props.peticionData.carrera.step_actual.puesto}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>Ingreso: </strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.step_actual.ingreso}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>Nombre:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.step_actual.nombre}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>

                                            <br />
                                            <Divider style={{ height: 2, backgroundColor: "#a31f35" }} />
                                            <br />
                                            <Typography component="h5" variant="h5">Calificación de cumplimiento con respecto al perfil </Typography>
                                            <Grid container spacing={1}>
                                                <Grid item sm={5}>
                                                    <Typography ><strong>{Object.keys(this.props.peticionData.carrera.cumplimiento)[0]}:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.cumplimiento[Object.keys(this.props.peticionData.carrera.cumplimiento)[0]]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}></Grid>
                                                <Grid item sm={5}>
                                                    <Typography ><strong>{Object.keys(this.props.peticionData.carrera.cumplimiento)[1]}:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.cumplimiento[Object.keys(this.props.peticionData.carrera.cumplimiento)[1]]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}></Grid>
                                                <Grid item sm={5}>
                                                    <Typography ><strong>{Object.keys(this.props.peticionData.carrera.cumplimiento)[2]}:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.cumplimiento[Object.keys(this.props.peticionData.carrera.cumplimiento)[2]]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}></Grid>
                                                <Grid item sm={5}>
                                                    <Typography ><strong>{Object.keys(this.props.peticionData.carrera.cumplimiento)[3]}:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.cumplimiento[Object.keys(this.props.peticionData.carrera.cumplimiento)[3]]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}></Grid>
                                                <Grid item sm={5}>
                                                    <Typography ><strong>{Object.keys(this.props.peticionData.carrera.cumplimiento)[4]}:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.cumplimiento[Object.keys(this.props.peticionData.carrera.cumplimiento)[4]]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}></Grid>
                                                <Grid item sm={5}>
                                                    <Typography ><strong>{Object.keys(this.props.peticionData.carrera.cumplimiento)[5]}:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.cumplimiento[Object.keys(this.props.peticionData.carrera.cumplimiento)[5]]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}></Grid>

                                            </Grid>
                                            <Divider style={{ height: 2, backgroundColor: "#a31f35" }} />
                                            <br />
                                            <Typography component="h5" variant="h5">Resultado de la solicitud </Typography>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>Supera el standard:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.resultado_standard.supera} %</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>Dentro del standard:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.resultado_standard.dentro} %</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>Debajo del standard: </strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.resultado_standard.debajo} %</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <br />
                                            <Divider style={{ height: 2, backgroundColor: "#a31f35" }} />
                                            <br />
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>Fecha de primer permiso ARN: </strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.fecha_primer_permiso === null ? 'No aplica' : this.props.peticionData.carrera.fecha_primer_permiso}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>

                                            <br />
                                            <Divider style={{ height: 2, backgroundColor: "#a31f35" }} />
                                            <br />
                                            <Typography ><strong>DIRECTOR GENERAL para su validación/firma </strong></Typography>
                                            {(Object.keys(this.props.responseDirectores).length > 0) &&

                                                <Grid item xs={6}>
                                                    <Autocomplete
                                                        onChange={this.getValueDirector}
                                                        fullWidth
                                                        freeSolo
                                                        disableClearable
                                                        selectOnFocus
                                                        clearOnBlur
                                                        handleHomeEndKeys
                                                        id="autocomplete"
                                                        options={this.props.responseDirectores}
                                                        getOptionLabel={(option) => { return option.name }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                InputProps={{
                                                                    ...params.InputProps, type: 'search', startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <Icon>search</Icon>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                label={""}
                                                                placeholder={""}
                                                                fullWidth
                                                                error={this.state.errors.director}
                                                            />
                                                        )}
                                                    />

                                                </Grid>}


                                            <Divider style={{ height: 2, backgroundColor: "#a31f35" }} />
                                            <br />

                                            <Typography component="h5" variant="h5">Definición de Competencias para la solicitud </Typography>
                                            <Typography component="h5" variant="h5">.Genéricas </Typography>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>1)</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.competencia.generica[0]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>2)</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.competencia.generica[1]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>

                                            <Typography component="h5" variant="h5">.De nivel </Typography>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>1)</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.competencia.de_nivel[0]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>2)</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.competencia.de_nivel[1]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Typography component="h5" variant="h5">.Funcionales </Typography>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>1)</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.competencia.funcional[0]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>

                                                <Grid item sm={5}>
                                                    <Typography ><strong>2)</strong></Typography>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <Typography>{this.props.peticionData.carrera.competencia.funcional[1]}</Typography>
                                                </Grid>
                                                <Grid item sm={2}>

                                                </Grid>
                                            </Grid>

                                            <br />
                                            <Divider style={{ height: 2, backgroundColor: "#a31f35" }} />
                                            <br />

                                            {(Object.keys(this.props.peticionData).length > 0) &&
                                                <Fragment>

                                                    <FirmasAprobadoresForm peticion={this.props.peticionData} />
                                                    <FirmaRRHHForm peticion={this.props.peticionData} />
                                                </Fragment>
                                            }
<Button onClick={this.aprueba} variant="contained" className="enviar" >
                                                FINALIZAR SOLICITUD
                            </Button>
                                            <Button onClick={this.desaprueba} variant="contained" className="enviar" >
                                                ENVIAR A DIRECCION
                            </Button>

                                        </CardContent>
                                    </Card>

                                </Grid>
                                <Grid item sm={3}></Grid>
                            </Grid>

                        </div>
                    </Theme>)
                    :
                    (this.loadingScreen())
                )
        )
    }
}

const mapStateToProps = reducers => {
    return reducers.listReducer;
};
const mapDispatchToProps = dispatch => {
    return {
        dispatchSendRRHHPlanCarreraAction: (estado, form) =>
            dispatch(
                sendRRHHPlanCarreraAction(estado, form),
            ),
        dispatchGetPeticionAction: (id) =>
            dispatch(
                getPeticionAction(id),
            ),
        dispatchGetDirectorAction: () =>
            dispatch(
                getDirectorAction(),
            ),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AprobadorRrhhPlanCarrera);


