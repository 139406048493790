import { getUser} from "../services/CardsService";

export const getUserAction = async (query) => {

    let response = await getUser(query);
    console.log(response);
    return {
        type: "GET_USER",
        payload: response
    }
}




