
import React, { Fragment } from 'react'
import { Grid, Typography } from '@material-ui/core';


export default function FirmaDestinatarioFormLicencia(props) {

    return (
        <Fragment>
        <Grid container spacing={2}>
            <Grid item xs={6} >
                <Typography style={{ 'fontSize': '12px' }}>FIRMA DEL EMPLEADO:</Typography>
            </Grid>
            <Grid item xs={6} > <Typography style={{ 'fontSize': '12px' }}>ACLARACIÓN:</Typography></Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 2 }}>
            <Grid item xs={6} >
                <Typography style={{ 'fontSize': '12px' }}>{props.destinatario.name}</Typography>
                <Typography>_______________________________</Typography>
            </Grid>
            <Grid item xs={6} >

                <Typography style={{ 'fontSize': '12px' }}>Visto en conformidad {new Date().toLocaleString()}</Typography>
                <Typography>___________________________________</Typography>
            </Grid>
        </Grid>
        </Fragment>
    )
}