import React, { Component, Fragment } from 'react';
import { getPeticionAction } from "../../actions/PeticionAction";
import { connect } from "react-redux";
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import FirmaDestinatarioForm from '../partials/FirmaDestinatarioForm';
import FirmasAprobadoresForm from '../partials/FirmasAprobadoresForm';
import FirmaRRHHForm from '../partials/FirmaRRHHForm';
import logo from '../../assets/atomo.png';
class PdfLicencia extends Component {

    constructor(props) {
        super(props);
        this.state = {
            peticionData: ''
        }
    }
    componentDidMount() {
        const id = this.props.match.params.id;

        this.props.dispatchGetPeticionAction(id);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.peticionData !== this.props.peticionData) {
            this.setState({
                peticionData: this.props.peticionData,
            })
        }
    }
    loadingScreen = () => {
        return (
            <Dialog open={true} align="center">
                <DialogContent>
                    <div>  </div>
                    <div> <Typography variant="h5">Procesando datos </Typography>  </div>
                </DialogContent>
            </Dialog>
        )
    }
    render() {
        return (
            this.state.peticionData === '' ?
                (this.loadingScreen())
                :
                (<div style={{ width: '100%' }}>
                    <div id="all-container" style={{ width: '650px', backgroundColor: '#f1f1f1', padding: '20px', margin: '0 auto', color: '#5f5f5f' }}>
                        <div id="cont-cuerpo-mail" style={{ backgroundColor: '#fff', fontSize: '14px', fontFamily: 'Arimo', width: '620px', padding: '15px', margin: '0 auto' }}>

                            <div>
                                <table width="100%" style={{ fontSize: '16px', border: '1px solid #a31f35' }}>
                                    <tr width="100%">
                                        <td style={{ width: '30%', borderRight: '1px solid #a31f35' }}>
                                              <img src={logo}
                                            alt="centrooncologico" class="img-responsive" style={{margin: '0 auto',float: 'left',height: '110px'}}></img> 
                                          

                                        </td>
                                        <td style={{ width: '70%' }}>
                                            <p style={{ fontWeight: 'bold', color: '#a31f35', textAlign: 'center' }}>Sistema de gestion de calidad</p>
                                            {/* <p style={{borderBottom: '1px solid #a31f35', fontWeight: 'bold',color: '#a31f35', textAlign: 'center'}}></p> */}
                                            <div style={{ borderBottom: '1px solid #a31f35', fontWeight: 'bold', color: '#a31f35', textAlign: 'center' }}>
                                                RECURSOS HUMANOS
                                        </div>
                                            <table width="100%" style={{ fontSize: '16px' }}>
                                                <tr width="100%">
                                                    <td style={{ width: '60%', borderRight: '1px solid #a31f35' }}>
                                                        <div style={{ fontWeight: 'bold', color: '#a31f35', textAlign: 'center' }}>SOLICITUD DE LICENCIA</div>

                                                    </td>
                                                    <td style={{ width: '24%' }}>
                                                        <div style={{ borderBottom: '1px solid #a31f35', textAlign: 'center' }}>Vigencia</div>
                                                        <div style={{ borderBottom: '1px solid #a31f35', textAlign: 'center' }}>Octubre 2016</div>
                                                        <table width="100%" style={{ fontSize: '16px' }}>
                                                            <tr width="100%">
                                                                <td style={{ borderRight: '1px solid #a31f35' }}>
                                                                    <div >Edic. 3</div>
                                                                </td>
                                                                <td >
                                                                    <div>Rev. 0</div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <div style={{ clear: 'both', paddingTop: '15px' }}>

                                    <p style={{ fontSize: '16px', textAlign: 'left', margin: '0px' }}><span style={{ fontWeight: 'bold' }}>Fecha:</span>  {this.state.peticionData.fecha_creacion}</p>

                                </div>
                                <div style={{ fontSize: '16px' }}>
                                    <p style={{ textAlign: 'left', margin: '0px' }}><span style={{ fontWeight: 'bold' }}>Nombre y apellido:</span>  {this.state.peticionData.destinatario[0].name}</p>

                                </div>
                                <br />
                                {this.state.peticionData.licencia.dias_licencia > 0 ?
                                    (<table width="100%" style={{ fontSize: '16px' }}>
                                        <tr>
                                            <td style={{ width: '33%', border: '1px solid #737373', padding: '15px' }}>
                                                <p style={{ fontWeight: 'bold', color: '#a31f35', textAlign: 'center' }}>Licencias por vacaciones</p>

                                                <p style={{ textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>Fecha de inicio:</span> {this.state.peticionData.licencia.fecha_inicio}</p>
                                                <p style={{ textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>Fecha de reincorporación:</span> {this.state.peticionData.licencia.fecha_reincorporacion}</p>
                                                <p style={{ textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>Cantidad de dias a tomar:</span> {this.state.peticionData.licencia.dias_licencia}</p>
                                                <p style={{ textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>Días que quedan pendientes:</span> {this.state.peticionData.licencia.dias_pendientes}</p>
                                                <p style={{ textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>Motivo de rechazo :</span> {(this.state.peticionData.licencia.motivo_rechazo === null) ? 'No aplica' : this.state.peticionData.licencia.motivo_rechazo}</p>

                                            </td>

                                        </tr>
                                    </table>)
                                    :
                                    (<table width="100%" style={{ fontSize: '16px' }}>
                                        <tr>
                                            <td style={{ width: '33%', border: '1px solid #a31f35', padding: '15px' }}>
                                                <p style={{ fontWeight: 'bold', color: '#a31f35', textAlign: 'center' }}>Licencias Especiales</p>
                                                <p style={{ textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>Motivo:</span> {this.state.peticionData.licencia.motivo_licencia}</p>
                                                <p style={{ textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>Descripción:</span> {(this.state.peticionData.licencia.descripcion_lic_especiales === "") ? 'No aplica' : this.state.peticionData.licencia.descripcion_lic_especiales}</p>
                                                <p style={{ textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>Fecha de inicio:</span> {this.state.peticionData.licencia.fecha_inicio}</p>
                                                <p style={{ textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>Fecha de reincorporación:</span> {this.state.peticionData.licencia.fecha_reincorporacion}</p>
                                                <p style={{ textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>Compensación de horas:</span> {(this.state.peticionData.licencia.compensa_horas === false) ? 'No' : 'Si'}</p>
                                                <p style={{ textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>Días y horas de compensación:</span> {(this.state.peticionData.licencia.compensa_horas === false) ? '-' : this.state.peticionData.licencia.compensa_descripcion}</p>
                                                <p style={{ textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>Motivo de rechazo :</span> {(this.state.peticionData.licencia.motivo_rechazo === '') ? 'No aplica' : this.state.peticionData.licencia.motivo_rechazo}</p>

                                            </td>

                                        </tr>
                                    </table>)
                                }
                                <br />
                                {(Object.keys(this.state.peticionData).length > 0) &&
                                    <Fragment>
                                        <FirmaDestinatarioForm peticion={this.state.peticionData} />
                                        <FirmasAprobadoresForm peticion={this.state.peticionData} />
                                        <FirmaRRHHForm peticion={this.state.peticionData} />
                                    </Fragment>
                                }

                                <br />
                                <p>EL presente es un documento para uso exclusivo de <strong>la empresa</strong> y/o distribucion fuera de ella esta prohibida, exepto por expresa autorizacion de la Direccion</p>

                            </div>

                        </div>
                    </div>
                </div>)

        )

    }
}

const mapStateToProps = reducers => {
    return reducers.listReducer;
};
const mapDispatchToProps = dispatch => {
    return {
        dispatchGetPeticionAction: (id) =>
            dispatch(
                getPeticionAction(id),
            ),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PdfLicencia);