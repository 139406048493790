import React, { Component, Fragment } from 'react'
import { AccordionSummary, Accordion, AccordionDetails, Divider, Grid, Typography, Button, Card, CardHeader, CardContent } from '@material-ui/core';
import { connect } from "react-redux";
import '../../assets/Form.css';
import Number from '../inputs/Number'
import SimpleSelect from '../inputs/SimpleSelect'
import CheckboxLicencia from '../inputs/CheckboxLicencia'
import TextArea from '../inputs/TextArea'
import Chips from '../inputs/Chips'
import config from '../../config/config.json';
import DateValid from '../inputs/DateValid'
import RadioButton from '../inputs/RadioButtons'
import FooterForm from '../partials/FooterForm'
import FirmaDestinatarioFormLicencia from '../partials/FirmaDestinatarioFormLicencia'
import { sendLicenciaAction } from '../../actions/FormAction';
import { getUsuariosAction, getAprobadoresAction } from '../../actions/PeticionAction';
import { Alert, AlertTitle } from '@material-ui/lab';
import { getMotivo, getSiNo} from '../../services/InfoFormService'
import { Element, scroller, animateScroll as scroll } from 'react-scroll';
import Theme from '../../assets/Theme';
import configStyles from '../../config/style.json';
import logo from '../../assets/atomo.png';
import Email from '../inputs/Email'
import Login from '../views/login/Login';
import { Redirect } from "react-router-dom";

class Licencia extends Component {
    constructor(props) {
        super(props)
        var date = new Date();
        date.setDate(date.getDate() + config.fecha_expiracion["licencia"]);
        this.state = {
            openAlert: true,
            warning: false,
            email:'',
            vacaciones: {
                input: false,
                checkbox: true
            },
            especial: {
                input: true,
                checkbox: false
            },
            fechaInicioVacaciones: '',
            fechaReincorporacionVacaciones: '',
            fechaInicioEspeciales: '',
            fechaReincorporacionEspeciales: '',
            cantidadDias: '',
            cantidadDiasPendientes: '',
            otroMotivoDescripcion: '',
            compensacionDescripcion: '',
            compensacion: '',
            motivo_licencia: '',
            motivoOtro: false,
            motivoCompensacion: false,
            aprobadores: [],
            destinatario: '',
            aprobadoresChip: [],
            fechaExpiracion: '',
            valueAutocomplete: '',
            initialChip: false,
            date: date,
          
            errors: {
                fechaInicioVacaciones: false,
                fechaReincorporacionVacaciones: false,
                cantidadDias: false,
                cantidadDiasPendientes: false,
                otroMotivoDescripcion: false,
                compensacionDescripcion: false,
                motivo_licencia: false
            }
        }
        this.aprobadoresId = []

    }
    collapseAlert = () => {
        this.setState({ openAlert: false })
    }
    componentDidMount() {
        //this.props.dispatchGetUsuariosAction();
       
        if(window.localStorage.getItem('userData') !== null){
            this.processDataLogin(window.localStorage.getItem('userData'))
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (Object.keys(this.props.responseAprobadores).length > 0 && this.state.aprobadoresChip.length === 0 && !this.state.initialChip) {
            let apro = this.props.responseAprobadores[0];
          
            apro["status"] = "0";

            this.aprobadoresId.push(apro)
            let chip = [];
            chip.push({ 'id': apro.email, "label": apro.name, 'selected': false })

            this.setState({ aprobadoresChip: chip, initialChip: true })
        }
    }

    send = () => {

        let count = 5;
        let countValid = 0;
        let form = new Object();
        let formPeticion = new Object();
        let formLicencia = new Object();
        let dataForm = [];
        let response = true;
        let errors = [];
        console.log(this.state)

        //form["peticion_hash"] = this.props.match.params.hash;

        if (this.state.vacaciones.checkbox) {
            formLicencia["motivo_licencia"] = "";
            formLicencia["descripcion_lic_especiales"] = "";
            formLicencia["compensa_horas"] = "";
            formLicencia["compensa_descripcion"] = "";

            if (this.state.fechaInicioVacaciones && this.state.fechaInicioVacaciones !== null) {

                if (this.state.fechaInicioVacaciones.state.selectedDate !== '' && this.state.fechaInicioVacaciones.state.selectedDate !== null) {
                    countValid++;
                    console.log("1")
                    let date = this.state.fechaInicioVacaciones.state.selectedDate.split("/")
                    formLicencia[this.state.fechaInicioVacaciones.props.name] = date.join("-");
                }
            }

            if (this.state.fechaReincorporacionVacaciones && this.state.fechaReincorporacionVacaciones !== null) {

                if (this.state.fechaReincorporacionVacaciones.state.selectedDate !== '' && this.state.fechaReincorporacionVacaciones.state.selectedDate !== null) {
                    countValid++;
                    console.log("2")

                    let date = this.state.fechaReincorporacionVacaciones.state.selectedDate.split("/")
                    formLicencia[this.state.fechaReincorporacionVacaciones.props.name] = date.join("-");
                }
            }

            if (this.state.cantidadDias && this.state.cantidadDias !== null) {
                countValid++;
                console.log("3")
                formLicencia[this.state.cantidadDias.props.name] = this.state.cantidadDias.state.defaultValue !== '' ? this.state.cantidadDias.state.defaultValue : 0;

            }

            if (this.state.cantidadDiasPendientes && this.state.cantidadDiasPendientes !== null) {
                countValid++;
                console.log("4")
                formLicencia[this.state.cantidadDiasPendientes.props.name] = this.state.cantidadDiasPendientes.state.defaultValue !== '' ? this.state.cantidadDiasPendientes.state.defaultValue : 0;

            }

            //errores
            errors = {
                cantidadDias: false,
                cantidadDiasPendientes: false,
                fechaInicioVacaciones: (this.state.fechaInicioVacaciones && this.state.fechaInicioVacaciones.state.selectedDate !== null && this.state.fechaInicioVacaciones.state.errorText === '') ? false : true,
                fechaReincorporacionVacaciones: (this.state.fechaReincorporacionVacaciones && this.state.fechaReincorporacionVacaciones.state.selectedDate !== null && this.state.fechaReincorporacionVacaciones.state.errorText === '') ? false : true,
                aprobadores: (Object.keys(this.aprobadoresId).length > 0) ? false : true,

            };
        }

        if (this.state.especial.checkbox) {
            formLicencia["dias_licencia"] = 0;
            formLicencia["dias_pendientes"] = 0;

            if (this.state.motivo_licencia && this.state.motivo_licencia !== null) {

                if (this.state.motivo_licencia.state.defaultValue !== '') {
                    countValid++;
                    console.log("11")
                    formLicencia[this.state.motivo_licencia.props.name] = this.state.motivo_licencia.state.defaultValue;

                    formLicencia["descripcion_lic_especiales"] = (this.state.motivo_licencia.state.defaultValue === "OTRO MOTIVO") ? this.state.otroMotivoDescripcion.state.defaultValue : '';

                }
            }

            if (this.state.fechaInicioEspeciales && this.state.fechaInicioEspeciales !== null) {

                if (this.state.fechaInicioEspeciales.state.selectedDate !== '' && this.state.fechaInicioEspeciales.state.selectedDate !== null) {
                    countValid++;
                    console.log("12")

                    let date = this.state.fechaInicioEspeciales.state.selectedDate.split("/")
                    formLicencia[this.state.fechaInicioEspeciales.props.name] = date.join("-");
                }
            }

            if (this.state.fechaReincorporacionEspeciales && this.state.fechaReincorporacionEspeciales !== null) {

                if (this.state.fechaReincorporacionEspeciales.state.selectedDate !== '' && this.state.fechaReincorporacionEspeciales.state.selectedDate !== null) {
                    countValid++;
                    console.log("13")

                    let date = this.state.fechaReincorporacionEspeciales.state.selectedDate.split("/")
                    formLicencia[this.state.fechaReincorporacionEspeciales.props.name] = date.join("-");
                }
            }

            if (this.state.compensacion && this.state.compensacion !== null) {

                if (this.state.compensacion.state.defaultValue !== '') {
                    countValid++;
                    console.log("14")

                    formLicencia[this.state.compensacion.props.name] = (this.state.compensacion.state.defaultValue === "Si") ? true : false;
                    formLicencia["compensa_descripcion"] = (this.state.compensacion.state.defaultValue === "Si") ? this.state.compensacionDescripcion.state.defaultValue : '';

                }
            }

            //errores
            errors = {
                compensacionDescripcion: (this.state.compensacion && this.state.compensacion.state.defaultValue !== '' && this.state.compensacion.state.defaultValue === 'Si') ? false : true,
                motivo_licencia: (this.state.motivo_licencia && this.state.motivo_licencia.state.defaultValue !== '' && this.state.motivo_licencia.state.errorText === '') ? false : true,
                fechaInicioEspeciales: (this.state.fechaInicioEspeciales && this.state.fechaInicioEspeciales.state.selectedDate !== null && this.state.fechaInicioEspeciales.state.errorText === '') ? false : true,
                fechaReincorporacionEspeciales: (this.state.fechaReincorporacionEspeciales && this.state.fechaReincorporacionEspeciales.state.selectedDate !== null && this.state.fechaReincorporacionEspeciales.state.errorText === '') ? false : true,
                aprobadores: (Object.keys(this.aprobadoresId).length > 0) ? false : true,

            };
        }
        formPeticion["mail_notificacion"] = config.mail_notificacion_liciencia;
        if (this.state.destinatario !== '') {

            let destinatario = this.state.destinatario;
            destinatario["status"] = "0";
            formPeticion["destinatario"] = [destinatario];
        }
        formPeticion["tipo_peticion"] = "1";
        console.log("aprobbbbbbbbbbb", this.aprobadoresId)
        if (Object.keys(this.aprobadoresId).length > 0) {
            countValid++;
            console.log("5")

            formPeticion["aprobadores"] = this.aprobadoresId;
        }
        console.log("FECHA DE EXPIRACION", this.state.fechaExpiracion);

        if (this.state.fechaExpiracion && this.state.fechaExpiracion !== null) {

            if (this.state.fechaExpiracion.state.selectedDate !== '' && this.state.fechaExpiracion.state.selectedDate !== null) {

                let date = this.state.fechaExpiracion.state.selectedDate.split("/")
                formPeticion[this.state.fechaExpiracion.props.name] = date.join("-");
            }
        }

        if (!this.state.vacaciones.checkbox && !this.state.especial.checkbox) {
            this.setState({ warning: true })
            scroller.scrollTo('warning')
        }

        this.setState({
            errors: errors
        })
        form["licencia"] = formLicencia;
        form["peticion"] = formPeticion;
        console.log(form)
        console.log("count", count)
        console.log("countValid", countValid)
        if (count === countValid) {
            this.props.dispatchSendLicenciaAction(form);
        }

        if (this.state.vacaciones.checkbox) {

            scroller.scrollTo('vacaciones')
        }
        if (this.state.especial.checkbox) {

            scroller.scrollTo('especial')
        }

    }

    callbackLicencia = (name, checked) => {

        if (name === "vacaciones")
            this.setState({ vacaciones: { input: !checked, checkbox: true }, especial: { input: checked, checkbox: false } })
        else
            this.setState({ vacaciones: { input: checked, checkbox: false }, especial: { input: !checked, checkbox: true } })

    }

    callbackMotivo = (value) => {
        if (value === "OTRO MOTIVO")
            this.setState({ motivoOtro: true })
        else
            this.setState({ motivoOtro: false })

    }

    callbackCompensacion = (value) => {
        if (value === 'Si')
            this.setState({ motivoCompensacion: true })
        else
            this.setState({ motivoCompensacion: false })

    }

    callbackLogin = (status,response) => {
        if(status){
            this.processDataLogin(response)
        }
    }
    processDataLogin = (response)=>{
        let value = JSON.parse(response)
        this.setState({ email:value.email,destinatario: value, aprobadoresChip: [], valueAutocomplete: ''})
        this.aprobadoresId = []
        let username = {
            "userName": value.name
        }
        console.log("estoy en get value destinatario")
        this.props.dispatchGetAprobadoresAction(username);
    }
    callbackChips = (aprobadores) => {
        console.log(aprobadores)
        this.aprobadoresId = []
        let chip = [];
        var date = new Date();
        if (aprobadores.length > 0) {
            aprobadores.map(aprobador => {

                let include = this.props.responseAprobadores.filter(e => e.email === aprobador.id);
                let apro = include[0];
                apro["status"] = "0";

                this.aprobadoresId.push(apro)

                chip.push(aprobador)
            })
            let horas = chip.length * 48;
            let dias = horas / 24;

            date.setDate(date.getDate() + dias);
        } else {
            date.setDate(date.getDate() + config.fecha_expiracion["licencia"]);

        }


        console.log(chip)
        this.setState({ aprobadoresChip: chip, date: date })

    }

  
    render() {
        console.log("LICENCIA")
      

        console.log(this.state.destinatario)
        let today = new Date().toLocaleString();

        today = today.split(",");
        today = today[0].split("/");

        console.log(window.localStorage.getItem('userData')!==null)
        let date = today[2] + '-' + today[0] + '-' + today[1];
        return (
            <Fragment>

                {Object.keys(this.props.responseLicencia).length > 0 ?
                    (<Redirect to={{
                        pathname: `/dashboard/`, state: {
                            responseForm: ' El formulario se envío correctamente',
                            error:false
                        }
                    }} />
                    )
                    :
                    (window.localStorage.getItem('userData')!==null) ?
                        (<Theme>
                            <div className={`cardView_container`} styles={configStyles}>
                                <Grid container >
                                    <Grid item md={3} ></Grid>

                                    <Grid item xs={12} md={6} className="borderBody" >

                                        {/* <HeaderForm rev="Rev. 0" edicion="Edic. 3" fecha="Octubre 2016" type="Solicitud de Licencias" /> */}
                                        <Card>
                                            <CardHeader className="Detail_Action_ViewHeader"
                                                title={<div style={{ 'display': 'inline-block' }}>

                                                    <img src={logo} style={{ margin: '0 auto', float: 'left', 'width': '6%', 'maxWidth': '100%' }}></img>
                                                    <Typography style={{ 'paddingLeft': '8%' }} component="h4" variant="h4"><strong>Sistema de Gestión de la Calidad - Recursos Humanos</strong></Typography>
                                                </div>}
                                                subheader={<Typography style={{ 'paddingLeft': '8%' }} component="h4" variant="h4"><strong>Solicitud de Licencias </strong></Typography>}
                                                action={<Grid cointaner style={{ 'paddingTop': '10px' }} >
                                                    <Grid item xs={12}>
                                                        <Typography component="h6" variant="h6" style={{ 'textAlign': 'center' }} >Vigencia</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>

                                                        <Typography component="h6" variant="h6" style={{ 'textAlign': 'center' }}>Octubre 2016</Typography>
                                                    </Grid>
                                                    <Grid container >
                                                        <Grid item xs={12}>
                                                            <Typography component="h6" variant="h6" >Edic. 3 / Rev. 0</Typography>
                                                        </Grid>

                                                    </Grid></Grid>}
                                            >
                                            </CardHeader>
                                            <CardContent className="Detail_Action_ViewContainer">

                                                <Element name="warning" >
                                                    {this.state.warning &&
                                                        (<Alert severity="error">
                                                            <AlertTitle>Error</AlertTitle>
                                                                Debe completar el formulario antes de enviarse.
                                                        </Alert>)}</Element>
                                                <Grid container spacing={1}>

                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <Typography className="size">Nombre y apellido</Typography>
                                                        {this.state.email !== "" &&
                                                              <Email
                                                              
                                                              label=""
                                                              placeholder={""}
                                                              disabled={true}
                                                              defaultValue={this.state.email}
                                                              
                                                          />  
                                                        }
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={6} >
                                                        <Typography style={{ 'paddingBottom': '4px' }} className="size">Plazo Límite para completar la Solicitud:</Typography>

                                                        <DateValid
                                                            ref={element => { this.state.fechaExpiracion = element }}
                                                            label=""
                                                            name="fecha_expiracion"
                                                            id="fechaExpiracion"
                                                            defaultValue={this.state.date}
                                                            placeholder="__/__/____"
                                                            error={this.state.errors.fechaExpiracion}
                                                            disabled={true}
                                                        />
                                                    </Grid>


                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Typography className="size"> Aprobadores (En el orden que se agregan, es el orden en que se envían los mails)</Typography>


                                                        <Chips
                                                            disabled={Object.keys(this.props.responseAprobadores).length > 0 ? false : true}
                                                            size="small"
                                                            view={'edit'}
                                                            placeholder=""
                                                            label="Aprobadores"
                                                            {...this.props}
                                                            chips={this.state.aprobadoresChip}
                                                            options={this.props.responseAprobadores}
                                                            callbackAdd={this.callbackChips}
                                                            title=""
                                                            optionLabel={"name"}
                                                            valueAutocomplete={this.state.valueAutocomplete}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Divider style={{ height: 2, backgroundColor: "#a51e36" }} />
                                                <Element name="vacaciones" >
                                                    <Accordion className="collapsibleCard" defaultExpanded={true} expanded={this.state.vacaciones.checkbox}>
                                                        <AccordionSummary
                                                            style={{ 'height': '10px' }}
                                                        >
                                                            <CheckboxLicencia
                                                                name="vacaciones"
                                                                label={"Habilitar Licencia por Vacaciones"}
                                                                defaultValue={false}
                                                                defaultChecked={false}
                                                                checked={this.state.vacaciones.checkbox}
                                                                callback={this.callbackLicencia}
                                                            />
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Grid container spacing={2}>

                                                                <Grid item xs={12} sm={6} md={6}>
                                                                    <DateValid
                                                                        ref={element => { this.state.fechaInicioVacaciones = element }}
                                                                        label="Fecha de inicio"
                                                                        name="fecha_inicio"
                                                                        id="fechaInicioVacaciones"
                                                                        defaultValue={null}
                                                                        placeholder="__/__/____"
                                                                        disabled={this.state.vacaciones.input}
                                                                        error={this.state.errors.fechaInicioVacaciones}
                                                                    />
                                                                </Grid>

                                                                <Grid item item xs={12} sm={6} md={6}>
                                                                    <DateValid
                                                                        ref={element => { this.state.fechaReincorporacionVacaciones = element }}
                                                                        label="Fecha de  reincorporación"
                                                                        name="fecha_reincorporacion"
                                                                        id="fechaReincorporacionVacaciones"
                                                                        defaultValue={null}
                                                                        disabled={this.state.vacaciones.input}
                                                                        placeholder="__/__/____"
                                                                        error={this.state.errors.fechaReincorporacionVacaciones}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={6}>
                                                                    <Typography className="size"> Cantidad de días a tomar (Sujeto verificación de Recursos Humanos)</Typography>

                                                                    <Number
                                                                        ref={element => { this.state.cantidadDias = element }}
                                                                        id={`cantidadDias`}
                                                                        label=""
                                                                        name={`dias_licencia`}
                                                                        placeholder=""
                                                                        minLength={1}
                                                                        maxLength={null}
                                                                        defaultValue={''}
                                                                        disabled={this.state.vacaciones.input}
                                                                        error={this.state.errors.cantidadDias}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={6}>
                                                                    <Typography className="size">Días que quedarán pendientes (Sujeto verificación de Recursos Humanos)</Typography>

                                                                    <Number
                                                                        ref={element => { this.state.cantidadDiasPendientes = element }}
                                                                        id={`cantidadDiasPendientes`}
                                                                        label=""
                                                                        name={`dias_pendientes`}
                                                                        placeholder=""
                                                                        minLength={1}
                                                                        maxLength={null}
                                                                        defaultValue={''}
                                                                        disabled={this.state.vacaciones.input}
                                                                        error={this.state.errors.cantidadDiasPendientes}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Element>
                                                <Divider style={{ height: 2, backgroundColor: "#a51e36" }} />
                                                <Element name="especial" >
                                                    <Accordion className="collapsibleCard" expanded={this.state.especial.checkbox}>
                                                        <AccordionSummary
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            style={{ 'height': '10px' }}
                                                        >
                                                            <CheckboxLicencia
                                                                name="especial"
                                                                label={"Habilitar Licencias Especiales"}
                                                                defaultValue={false}
                                                                defaultChecked={false}
                                                                checked={this.state.especial.checkbox}
                                                                callback={this.callbackLicencia}
                                                            />
                                                        </AccordionSummary>
                                                        <AccordionDetails>

                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} sm={6} md={6}>
                                                                    <Typography style={{ 'fontSize': '12px' }}>Motivo</Typography>

                                                                    <SimpleSelect
                                                                        ref={element => { this.state.motivo_licencia = element }}
                                                                        id="motivo"
                                                                        labelId="motivo"

                                                                        name="motivo_licencia"
                                                                        defaultValue=""
                                                                        optionsSelect={getMotivo()}
                                                                        error={this.state.errors.motivo_licencia}
                                                                        disabled={this.state.especial.input}
                                                                        callback={this.callbackMotivo}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={6}>
                                                                    {this.state.motivoOtro &&
                                                                        <Fragment>
                                                                            <Typography style={{ 'fontSize': '12px' }}>Especifique cuál:</Typography>

                                                                            <TextArea
                                                                                ref={element => { this.state.otroMotivoDescripcion = element }}
                                                                                id="otroMotivoDescripcion"
                                                                                name="otroMotivoDescripcion"
                                                                                error={this.state.errors.otroMotivoDescripcion}
                                                                                defaultValue=""
                                                                                disabled={this.state.especial.input}
                                                                            />
                                                                        </Fragment>
                                                                    }
                                                                </Grid>

                                                                <Grid item xs={12} sm={6} md={6}>
                                                                    <DateValid
                                                                        ref={element => { this.state.fechaInicioEspeciales = element }}
                                                                        label="Fecha de inicio"
                                                                        name="fecha_inicio"
                                                                        id="fechaInicioVacaciones"
                                                                        defaultValue={null}
                                                                        placeholder="__/__/____"
                                                                        error={this.state.errors.fechaInicioEspeciales}
                                                                        disabled={this.state.especial.input}

                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={6}>
                                                                    <DateValid
                                                                        ref={element => { this.state.fechaReincorporacionEspeciales = element }}
                                                                        label="Fecha de  reincorporación"
                                                                        name="fecha_reincorporacion"
                                                                        id="fechaReincorporacionVacaciones"
                                                                        defaultValue={null}
                                                                        placeholder="__/__/____"
                                                                        error={this.state.errors.fechaReincorporacionEspeciales}
                                                                        disabled={this.state.especial.input}
                                                                    />
                                                                </Grid>


                                                                <Grid item xs={12} sm={6} md={6}>
                                                                    <Typography style={{ 'fontSize': '12px' }}>Corresponde compensación de horas:</Typography>
                                                                    <RadioButton
                                                                        ref={element => { this.state.compensacion = element }}
                                                                        name="compensa_horas"
                                                                        label={""}
                                                                        defaultValue={"No"}
                                                                        defaultChecked={false}
                                                                        row={true}
                                                                        showLabel={true}
                                                                        disabled={this.state.especial.input}
                                                                        optionsSelect={getSiNo()}
                                                                        callback={this.callbackCompensacion}
                                                                        paddingRight='0%'
                                                                    />

                                                                </Grid>

                                                                <Grid item xs={12} sm={6} md={6}>
                                                                    {this.state.motivoCompensacion &&
                                                                        <Fragment>
                                                                            <Typography style={{ 'fontSize': '12px' }}>Indique días y horarios de compensación</Typography>
                                                                            <TextArea
                                                                                ref={element => { this.state.compensacionDescripcion = element }}
                                                                                id="compensacionDescripcion"
                                                                                name="compensacionDescripcion"
                                                                                error={this.state.errors.compensacionDescripcion}
                                                                                defaultValue=""
                                                                                disabled={this.state.especial.input}
                                                                            />
                                                                        </Fragment>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Element>
                                                <Divider style={{ height: 2, backgroundColor: "#a51e36" }} />

                                                {/*  <WarningForm /> */}

                                                {this.state.destinatario.name !== undefined &&
                                                    <FirmaDestinatarioFormLicencia
                                                        destinatario={this.state.destinatario}
                                                    />}

                                                <Button style={{ color: 'white' }} onClick={() => this.send()} variant="contained" className="enviar" /* disabled={loading} fullWidth  color="secondary" className="buttonSearch" */>
                                                    Enviar
                                                    </Button>
                                                <FooterForm />





                                            </CardContent>
                                        </Card>

                                    </Grid>
                                    <Grid item md={3} ></Grid>

                                </Grid>
                            </div>
                        </Theme>
                        )
                        :
                        (<Login callback={this.callbackLogin}/>)}
            </Fragment>
        )
    }
}
const mapStateToProps = reducers => {
    return reducers.listReducer;
};
const mapDispatchToProps = dispatch => {
    return {
        dispatchSendLicenciaAction: (form) =>
            dispatch(
                sendLicenciaAction(form),
            ),
        dispatchGetUsuariosAction: () =>
            dispatch(
                getUsuariosAction(),
            ),
        dispatchGetAprobadoresAction: (request) =>
            dispatch(
                getAprobadoresAction(request),
            ),

    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Licencia);


