import React, { Component } from 'react';
import Theme from "../../assets/Theme";
import style from "./../../config/style.json";
import config from './../../config/config.json';
import clsx from 'clsx';
import { Toolbar, AppBar, Typography, IconButton, Container, Icon, Avatar } from '@material-ui/core';
import SidebarNavigation from './partials/SidebarNavigation';
import MenuDropdown from '../partials/MenuDropdown';
import LogoBy from '../../assets/images/logowhite.png';
import Login from '../views/login/Login';

class MainAdmin extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: true,
			cards: config.cards,
			openAlert: true,
			showSidebar: true,
			login:false,
			responseForgot: ''
		};
	}
	callbackLogin = (status,response) => {
		
        if(status){
            this.setState({login:true})
        }
    }
	
	callbackCard = (indiceCard) => {
		this.state.cards.map((value, i) => {
			if (i === indiceCard) {
				value.expanded = true;
				value.visibility = true;
			} else {
				value.expanded = false;
				value.visibility = false;
			}
		})

		this.setState({ cards: this.state.cards });
	}

	handleDrawerOpen = () => {
		this.setState({ open: true });
	};
	handleDrawerClose = () => {
		this.setState({ open: false });
	};

	collapseAlert = () => {
		this.setState({ openAlert: false })
	}
	callbackForgot = (res) => {
		console.log("main admin", res)
		this.setState({ responseForgot: res });
	};
	render() {
		console.log(window.localStorage.getItem('userData'))
		return (window.localStorage.getItem('userData')===null && !this.state.login)
		? (<Login callback={this.callbackLogin}/>)
		:(
			<Theme>

				<div className={`wrapper MainLayout_wrapper`} styles={style}>

					{/* START HEADER */}
					<AppBar position="absolute" className={clsx('appBar', this.state.open && 'appBarShift')}>
						<Toolbar className="toolbar_Appbar">
							{/* <div>
								<Typography component="h1" variant="h3" color="inherit" noWrap className="title_Dashboard">VIDT</Typography>
							</div> */}
							<img className={clsx('toolbarLogo', this.state.open && 'toolbarLogoShow')} src={LogoBy} alt='by Vidt' />
							<IconButton edge="start" color="primary" aria-label="open drawer" onClick={this.handleDrawerOpen}
								className={clsx('menuButton', this.state.open && 'menuButtonHidden')}>
								<Icon>menu</Icon>
							</IconButton>
							<MenuDropdown
									user={JSON.parse(window.localStorage.getItem('userData')).email}
									buttonStartIcon={<Avatar className="avatar">
										<Icon>person</Icon>
									</Avatar>}
									buttonEndIcon={<Icon>expand_more</Icon>}
									buttonText={<Typography component="span" color="primary" noWrap >{JSON.parse(window.localStorage.getItem('userData')).email}</Typography>}
									
								/>
							
							
						</Toolbar>
					</AppBar>
					{/* END HEADER */}

					{/* START SIDEBAR */}
					{this.state.showSidebar && <SidebarNavigation />}
					{/* END SIDEBAR */}

					<main className="main_admin">
						<div className="app_bar_spacer" />

						<Container maxWidth={false} className="container mainLayout_container">
							{this.props.children}
						</Container>

					</main>
				</div>
			</Theme>
		)
	}
}

export default MainAdmin