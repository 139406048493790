import React, { Fragment, Component, Suspense } from 'react';
import { Icon, Card, CardHeader, Grid, Avatar,  CardActionArea, Link } from '@material-ui/core';
import ContentLoader from "react-content-loader";

export default class DashboardCard extends Component {

	dashboardCardType = (data) => {
		// console.log(data)
		let Component = React.lazy(() => import(`./sections/DashboardCard${this.props.card.config.type}`));
		return <Suspense fallback={<div className="preload"><div className="responsive"><ContentLoader speed={2} backgroundColor="#e8e8e8" foregroundColor="#f0f0f0" width={385} height={200} viewBox="0 0 385 200"><rect x="0" y="0" rx="10" ry="10" width="385" height="200" /></ContentLoader></div></div>}>
			<Component data={this.props} className={this.props.card.config.type} />
		</Suspense>
	}

	render() {
		const card = this.props.card;
		// console.log('data',card)
		const CardContracted = (props) =>
			(card.visibility &&
			(<Grid item xs={12} md={4} lg={3}>
					<Card className="card_contracted DashboardCard DashboardCardContracted">
						<CardActionArea component={Link} href={`/dashboard/${card.routing}`} underline='none' >
							<CardHeader
								avatar={<Avatar aria-label="recipe" className="avatar"><Icon>{card.icon}</Icon></Avatar>}
								title={<span className="name">{card.name}</span>}
								action={<span className="icon"><Icon>arrow_forward</Icon></span>}
							/>
						</CardActionArea>
					</Card>
				</Grid>
			))



		return (
			<Fragment>
				{card.visibility ? <CardContracted /> : <div></div>}
			</Fragment>
		)
	}
}