import axios from "axios";
import config from "../config/config.json";



const getList = async (endpoint, setCookie) => {
	const headers = {
		'Authorization': `Bearer ${setCookie}`
	};

	const url = config.credentials.url + endpoint;
	const response = await axios.get(url, { headers: headers })
		.then(res => {
			let data = [];
		
			if (res.data["description"]) {
				data["error"] = res.data["description"];
			} else {
				data["activePagination"] = false;

				if (res.data["count"] > 10) {
					//paging is activated
					
				
					data["previuosPage"] = res.data["previous"];
					data["nextPage"] = res.data["next"];
					data["activePagination"] = true;
				}

				data["list"] = res.data["results"];
				data["totalReg"] = res.data["count"];
				
			}

			return data;
		})
		.catch((err) => {
			console.log(err.response)
			return false;
		});
	return response;
}

const getSetCookie = () => {
	return window.localStorage.getItem('headersSetCookie');
}

export const getUser = async (request) => {
	const url = config.credentials.url+'getUsersByMail/';
   
    const headers = {
        'Content-Type': "application/json"
      };
    const response = await axios.post(url,JSON.stringify(request),{headers:headers})
   
    .then(res => {
		console.log(res);
		
        return (res.data.length > 0) ? res.data : false;
    })
    .catch((err) => {
        //console.log(err);
        return false;
    }); 

    return response;
	
}
